import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import useLabelSettings from "../../Pages/LabelSettingsDetail/useLabelSettings";
import HabilitationStatutBadge from "../HabilitationStatutBadge/HabilitationStatutBadge";
import tools from "../../utils/tools";
import LoadLink from "../LoadLink/LoadLink";
import { commonButtonStyles } from "../../styles";
import { toast } from "react-toastify";
import useConstants from "../../hooks/useConstants";
import Pagination from "../Pagination/Pagination";

function HabilitationUtilisateurDetailList({
  habilitationsData = [],
  actions,
  optionsFilters,
  isPaginate = true,
  isUserDetail = false,
  isDashboard = false,
}: {
  habilitationsData: any;
  actions?: any;
  optionsFilters?: any;
  isPaginate?: boolean;
  isUserDetail?: boolean;
  isDashboard?: boolean;
}) {
  const { labels } = useLabelSettings();
  const { constants } = useConstants();

  const columns = [
    {
      key: "1",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <HabilitationStatutBadge statut={item.statut} />,
    },
    {
      key: "2",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
      onColumnClick: () => {
        actions.sortColumn("certification");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters?.sort, "certification"),
    },
    {
      key: "3",
      name: "Nom",
      fieldName: "nom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actions.sortColumn("nom");
      },
      onRender: (item: any) => <Text>{item.utilisateur?.nom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "nom"),
    },
    {
      key: "4",
      name: "Prénom",
      fieldName: "prenom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actions.sortColumn("prenom");
      },
      onRender: (item: any) => <Text>{item.utilisateur?.prenom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "prenom"),
    },
    {
      key: "5",
      name: "Rôle",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        const profilObject: any = tools.findIn(
          constants.MATRICES.PROFILS_UTILISATEURS,
          item.profil,
          "id"
        );

        return <Text>{profilObject?.text}</Text>;
      },
    },
    {
      key: "6",
      name: "Date de début",
      fieldName: "date_debut",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_debut)}</Stack>
      ),
      showSortIconWhenUnsorted: true,
      onColumnClick: () => {
        actions.sortColumn("date_debut");
      },
      ...tools.sorted(optionsFilters?.sort, "date_debut"),
    },
    {
      key: "7",
      name: "Date de fin",
      fieldName: "date_fin",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_fin)}</Stack>
      ),
      showSortIconWhenUnsorted: true,
      onColumnClick: () => {
        actions.sortColumn("date_fin");
      },
      ...tools.sorted(optionsFilters?.sort, "date_fin"),
    },
    {
      key: "8",
      name: "Commentaire",
      fieldName: "commentaire",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.commentaire}</Text>,
    },
    {
      key: "9",
      name: "Actions",
      fieldName: "actions",
      maxWidth: 100,
      minWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        const isHabilitationActived = item.statut == "ACTIVE";
        const isHabilitationHaveDocument = item.documents.length > 0;

        return (
          <Stack horizontal wrap>
            {isHabilitationActived && (
              <LoadLink
                styles={commonButtonStyles.disableButtonLink}
                onClick={() => actions.onRemoveHabilitation([item])}
                isLoading={false}
                text={"Retirer"}
              />
            )}
            {isHabilitationHaveDocument && (
              <LoadLink
                href={item.documents[0].file_url}
                styles={commonButtonStyles.buttonLink}
                text={"Voir document"}
              />
            )}
          </Stack>
        );
      },
    },
  ]
    .filter((col: any) =>
      isUserDetail
        ? col.name != "Nom" && col.name != "Prénom" && col.name != "Rôle"
        : col
    )
    .filter((col: any) =>
      isDashboard ? col.name != "Nom" && col.name != "Prénom" : col
    );

  return (
    <>
      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={habilitationsData?.data ?? []}
        columns={columns}
        selectionMode={SelectionMode.none}
      />

      {isPaginate && (
        <Pagination
          changePage={actions.changePage}
          page={habilitationsData?.meta?.current_page || 1}
          totalPages={Math.ceil(habilitationsData?.meta?.total / 15) || 1}
        />
      )}
    </>
  );
}

export default HabilitationUtilisateurDetailList;
