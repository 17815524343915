import { DateTime } from "luxon";
import { useState } from "react";
import { toast } from "react-toastify";
import useCustomModal from "../Common/Overlays/CustomModal/useCustomModal";
import ExportCaisseDesDepots from "../Pages/SuiviCohortesList/ExportCaisseDesDepots/ExportCaisseDesDepots";
import {
  getExportCaisseDesDepots,
  getInformationLastExportCaisseDesDepots,
} from "../services/exportCaisseDesDepotsServices";
import tools from "../utils/tools";

function useExportCaisseDesDepots() {
  const [dateLastExport, setDateLastExport]: any = useState("");
  const [isLoadingExportCaisseDesDepots, setIsLoadingExportCaisseDesDepots] =
    useState(false);
  const [generationStarted, setGenerationStarted] = useState(false);
  const { actions: modalActions } = useCustomModal();
  const actions = {
    openExportCaisseDesDepot: () => {
      modalActions.openModal({
        header: "Export pour la Caisse des Dépôts",
        body: <ExportCaisseDesDepots />,
      });
    },
    onExportCaisseDesDepot: async (data: any) => {
      setIsLoadingExportCaisseDesDepots(true);
      try {
        const firstResponse: any = await getExportCaisseDesDepots({
          porteur: data.porteur,
          date_debut: DateTime.fromISO(data.date_debut).toFormat("yyyy-MM-dd"),
          date_fin: DateTime.fromISO(data.date_fin).toFormat("yyyy-MM-dd"),
        })
          .then(() => {
            setGenerationStarted(true);
          })
          .catch((err: any) => {
            toast.error(err.response?.data?.message);
          });

        // EXPORT MAINTENANT ASYNC

        // const fileUrl = firstResponse.data?.data?.url;
        // const fileName = firstResponse.data?.data?.nom_fichier;
        // if (process.env.REACT_APP_MODE == "development") {
        //   const downloadLink = document.createElement("a");
        //   downloadLink.href = fileUrl;
        //   downloadLink.download = fileName;
        //   downloadLink.target = "_blank"
        //   downloadLink.click();
        //   downloadLink.remove();
        // } else {
        //   //NOT WORKING IN DEV (CORS ERROR)
        //   axios
        //     .get(fileUrl, {
        //       responseType: "blob",
        //     })
        //     .then((response: any) => {
        //       fileDownload(response.data, fileName);
        //     })
        //     .catch((err: any) => {
        //       toast.error(
        //         "Une erreur est survenue lors du téléchargement, veuillez réessayer."
        //       );
        //     });
        //   //--------------------------------
        // }

        setIsLoadingExportCaisseDesDepots(false);
      } catch (e: any) {
        setIsLoadingExportCaisseDesDepots(false);
      }
    },
    onGetInformationLastUpdate: async () => {
      const response = await getInformationLastExportCaisseDesDepots().catch(
        (e: any) => setDateLastExport(false)
      );
      if (!response.error) {
        setDateLastExport(tools.formatDate(response.created_at));
      }
    },
  };
  return {
    generationStarted,
    dateLastExport,
    isLoadingExportCaisseDesDepots,
    actions,
  };
}

export default useExportCaisseDesDepots;
