import { Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import { commonInputStyles } from "../../../styles/index";
import { CompanyContext } from "../useCompanyDetail";
import SearchExistingStructure from "../../../Common/SearchExistingStructure/SearchExistingStructure";
import BranchesInfo from "../../../Common/BranchesInfo/BranchesInfo";

function GeneralInformationSection() {
  const [isGroupUpdated, setIsGroupUpdated] = useState(false);

  const {
    reactHookProps,
    errors,
    isChecked,
    actions,
    uuid,
    company,
    isSaved,
  }: any = useContext(CompanyContext);
  const { setValue, control, watch } = reactHookProps;

  const fieldsNeedingSavingFirstDisabled = uuid === "creer" && !isSaved;

  useEffect(() => {
    if (isGroupUpdated) {
      setValue("sous_groupe", null);
    }
  }, [reactHookProps.watch("groupe_entreprise")]);

  return (
    <FormSection sectionTitle="Informations principales de l'entreprise">
      {uuid === "creer" && (
        <SearchExistingStructure
          reactHookProps={reactHookProps}
          isChecked={isChecked}
          actions={actions}
          searchExistingUrl={"administration/entreprises/existe"}
          keyToCheck={"siret"}
          acceptedChararacterNumber={14}
        />
      )}

      {(isChecked || uuid !== "creer") && (
        <>
          <Stack.Item>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Saisissez la raison sociale de l'entreprise"
                  type="text"
                  {...field}
                  placeholder="Raison sociale"
                  errorMessage={(errors.nom as any)?.message}
                  required
                />
              )}
              defaultValue=""
              control={control}
              name="nom"
            />
          </Stack.Item>
          {uuid !== "creer" && (
            <>
              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="Saisissez le numéro SIRET de l'entreprise"
                      type="text"
                      placeholder="N° SIRET à 14 chiffres sans espace"
                      errorMessage={(errors.siret as any)?.message}
                      required
                      disabled={true}
                      value={value}
                      onChange={(e: any, newValue: any) => {
                        if (!newValue || newValue.length <= 14) {
                          onChange(e.target.value);
                        }
                      }}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="siret"
                />
              </Stack.Item>
              <BranchesInfo
                data={company}
                branchSentence={"Cette entreprise existe sur les branches :"}
                noBranchSentence={
                  "Cette entreprise est présente uniquement sur cette branche"
                }
              />
            </>
          )}

          <Stack.Item>
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectFieldAsync
                  label="Sélectionnez un IDCC :"
                  placeholder="Recherchez un IDCC en tapant les premières lettres de son nom"
                  resourceURI={`${API_URL}api/references/ref-idcc/chercher-idcc`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?recherche=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) =>
                    item.idcc + " | " + item.titre_convention
                  }
                  renderValue={(item: any) =>
                    item.idcc + " | " + item.titre_convention
                  }
                  dataIndex="id"
                  name={name}
                  value={value}
                  fieldRef={ref}
                  required={true}
                  onChange={(e: any) => {
                    setValue(name, e.target.value, { shouldValidate: true });
                  }}
                  defaultValue={null}
                  errorMessage={(errors.ref_idcc as any)?.message}
                  handleResponse={(response: any) => [...response.data.data]}
                />
              )}
              control={control}
              name="ref_idcc"
            />
          </Stack.Item>

          <FormAdress
            control={control}
            errors={errors}
            varToWatch={watch("code_postal") ?? false}
            required={true}
            onPrefillCityAndRegion={(data: any) => {
              setValue("ville", data.nom_commune);
              setValue("region", data.nom_region);
            }}
          />

          <Stack.Item>
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectFieldAsync
                  label="Groupe d’entreprises rattaché à cette entreprise :"
                  placeholder={
                    uuid === "creer"
                      ? "Veuillez enregistrer cette entreprise avant de pouvoir lui rattacher un groupe d'entreprises"
                      : "Recherchez un groupe d'entreprises en tapant les premières lettres de son nom"
                  }
                  resourceURI={`${API_URL}api/administration/groupe-entreprises/chercher`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?raison_sociale=${terms}&paginate=false`
                  }
                  method="GET"
                  renderItem={(item: any) =>
                    item.raison_sociale + " - " + item.siren
                  }
                  renderValue={(item: any) =>
                    item.raison_sociale + " - " + item.siren
                  }
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    setIsGroupUpdated(true);
                    setValue(name, e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={null}
                  errorMessage={(errors.ref_idccs as any)?.message}
                  handleResponse={(response: any) => [...response.data.data]}
                  disabled={fieldsNeedingSavingFirstDisabled}
                />
              )}
              control={control}
              name="groupe_entreprise"
            />
          </Stack.Item>

          {reactHookProps.watch("groupe_entreprise") && (
            <Stack.Item>
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectFieldAsync
                    label="Sous-groupe d’entreprises rattaché  à cette entreprise :"
                    placeholder="Recherchez un sous-groupe d'entreprises en tapant les premières lettres de son nom"
                    resourceURI={`${API_URL}api/administration/sous-groupes/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?type=SOUS_GROUPE_ENTREPRISE&nom=${terms}&paginate=false&groupe=${
                        reactHookProps.watch("groupe_entreprise").uuid
                      }`
                    }
                    method="GET"
                    renderItem={(item: any) => item.nom}
                    renderValue={(item: any) => item.nom}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    defaultValue={null}
                    errorMessage={(errors.ref_idccs as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                control={control}
                name="sous_groupe"
              />
            </Stack.Item>
          )}
        </>
      )}
    </FormSection>
  );
}

export default GeneralInformationSection;
