import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import TextResult from "../../../Common/TextResult/TextResult";
import { commonButtonStyles, commonInputStyles } from "../../../styles/index";
import useDemandeInscriptionList from "../useDemandeInscriptionList";
import useConstants from "../../../hooks/useConstants";

function DemandeInscriptionListFilters() {
  const { usersProfils, isLoadingUsers, actions, reactHookProps } =
    useDemandeInscriptionList();
  const { constants } = useConstants();

  const roleListSort = [
    { id: undefined, text: "Tous les rôles", key: "all" },
    ...constants.DEMANDE_INSCRIPTION.PROFILS_AUTORISES,
  ];

  const statutListSort = [
    { id: undefined, text: "Tous les statuts", key: "all" },
    ...constants.DEMANDE_INSCRIPTION.STATUTS,
  ];

  const totalUsers = usersProfils?.meta.total || 0;

  const { handleSubmit, control, setValue } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  selectedKey={value ? value.key : undefined}
                  label="Statut :"
                  placeholder="Sélectionnez un statut"
                  options={statutListSort}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={statutListSort[0]}
              control={control}
              name="statut"
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Nom :"
                  type="text"
                  placeholder="Saisissez un nom"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="prenom"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Prénom :"
                  type="text"
                  placeholder="Saisissez un prénom"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="email"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Email :"
                  id="email"
                  type="email"
                  placeholder="Saisissez un email"
                  {...field}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  selectedKey={value ? value.key : undefined}
                  label="Rôle :"
                  placeholder="Sélectionnez un rôle"
                  options={roleListSort}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={roleListSort[0]}
              control={control}
              name="profil"
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom_structure"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Raison sociale :"
                  id="nom_structure"
                  type="text"
                  placeholder="Saisissez une raison sociale"
                  {...field}
                />
              )}
            />
          </Stack.Item>
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingUsers} text="Rechercher" />
            </Stack.Item>

            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text="Réinitialiser ma recherche"
                onClick={onReset}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalUsers} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default DemandeInscriptionListFilters;
