import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import DemandeInscriptionListFilters from "./DemandeInscriptionListFilters/DemandeInscriptionListFilters";
import useDemandeInscriptionList from "./useDemandeInscriptionList";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import tools from "../../utils/tools";
import LoadLink from "../../Common/LoadLink/LoadLink";
import { commonButtonStyles } from "../../styles";
import Loader from "../../Common/Loader/Loader";
import useTheme from "../../Common/useTheme";
import useConstants from "../../hooks/useConstants";
import DemandeInscriptionBadge from "./DemandeInscriptionBadge/DemandeInscriptionBadge";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";
import { Link as RouterLink } from "react-router-dom";
import Can from "../../Common/Can/Can";
import { userAccessEnum } from "../../config/accessEnum";

function DemandeInscriptionList() {
  const {
    userProps,
    usersProfils,
    actions: actionsPage,
    optionsFilters,
  } = useDemandeInscriptionList();
  const { primaryColor } = useTheme();
  const { constants } = useConstants();
  const { isAnyKindOfAdmin } = useInfoProfilUser();

  const items = usersProfils?.data || [];

  const columns = [
    {
      key: "1",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <DemandeInscriptionBadge
          statut={
            (
              tools.findIn(
                constants.DEMANDE_INSCRIPTION.STATUTS,
                item.statut,
                "text"
              ) as any
            )?.id
          }
        />
      ),
    },
    {
      key: "2",
      name: "Nom",
      fieldName: "nom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("nom");
      },
      onRender: (item: any) => <Text>{item.nom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "nom"),
    },
    {
      key: "3",
      name: "Prénom",
      fieldName: "prenom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("prenom");
      },
      onRender: (item: any) => <Text>{item.prenom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "prenom"),
    },
    {
      key: "4",
      name: "Email",
      fieldName: "email",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("email");
      },
      onRender: (item: any) => <Text>{item.email}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "email"),
    },
    {
      key: "5",
      name: "Date de demande d'inscription",
      fieldName: "demande_le",
      minWidth: 120,
      maxWidth: 125,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("demande_le");
      },
      onRender: (item: any) => (
        <Text>{tools.reformateDateFromJsDate(item.demande_le)}</Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "demande_le"),
    },
    {
      key: "6",
      name: "Multibranche",
      fieldName: "multibranche",
      minWidth: 40,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.multibranche} />,
    },
    {
      key: "7",
      name: "Rôle",
      fieldName: "profil",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <Text>{item.profil}</Text>,
    },
    {
      key: "8",
      name: "Raison sociale",
      fieldName: "nom_structure",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("nom_structure");
      },
      onRender: (item: any) => (
        <Text>
          {item?.entite_liee?.raison_sociale ?? item?.entite_liee?.nom}
        </Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "nom_structure"),
    },
    {
      key: "9",
      name: "Responsable",
      fieldName: "responsable",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => (
        <Text>
          {item?.responsable?.nom} {item?.responsable?.prenom}
        </Text>
      ),
    },
    {
      key: "10",
      name: "Commentaire",
      fieldName: "commentaire",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.commentaire}</Text>,
    },
    {
      key: "11",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        switch (item.statut) {
          case "Validée":
            return isAnyKindOfAdmin && item?.utilisateur_lie?.uuid ? (
              <Stack>
                <Link
                  to={`/admin/utilisateurs/${item?.utilisateur_lie?.uuid}`}
                  as={RouterLink}
                  styles={commonButtonStyles.buttonLink}
                >
                  Voir la fiche utilisateur
                </Link>
              </Stack>
            ) : null;
          case "Refusée":
            return null;
          case "En attente":
            return (
              <Stack horizontal wrap>
                {userProps.isToggleInscriptionUuid == item.uuid ? (
                  <Loader size={"medium"} />
                ) : (
                  <Can
                    I={userAccessEnum.VALIDER_DEMANDE_INSCRIPTION_UTILISATEUR}
                  >
                    <>
                      <LoadLink
                        styles={commonButtonStyles.activeButtonLink}
                        onClick={() =>
                          userProps.actions.validerInscription(item)
                        }
                        text={"Valider"}
                      />
                      <LoadLink
                        styles={commonButtonStyles.disableButtonLink}
                        onClick={() => actionsPage.openRefuserInscription(item)}
                        text={"Refuser"}
                      />
                    </>
                  </Can>
                )}
              </Stack>
            );
        }
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Liste des demandes d'inscription"
        explain="Gérer les demandes d'inscription utilisateur"
      >
        <DemandeInscriptionListFilters />

        {[...items].filter((user: any) => user.statut == "En attente").length >
          1 && (
          <Can I={userAccessEnum.VALIDER_DEMANDE_INSCRIPTION_UTILISATEUR}>
            <div style={{ height: 46 }}>
              <DefaultButton
                text="Valider toutes les demandes d'inscription de la page"
                styles={commonButtonStyles.defaultButton}
                style={{ color: primaryColor, borderColor: primaryColor }}
                onClick={() => actionsPage.openValiderInscriptionEnMasse(items)}
              />
            </div>
          </Can>
        )}

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={usersProfils?.meta.current_page}
          totalPages={Math.ceil(usersProfils?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default DemandeInscriptionList;
