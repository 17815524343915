import { atom, useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useCandidature from "../../hooks/candidature/useCandidature";
import { juryInfoAtom } from "../../hooks/jury/useJuryQuery";
import { authAtom } from "../../hooks/useAuth";
import {
  exportCandidaturesJuryRequest,
  getCandidaturesJuryRequest,
} from "../../services/candidatureService";
import tools from "../../utils/tools";
import GiveUpConfirmDialog from "../CandidatureList/GiveUpConfirmDialog/GiveUpConfirmDialog";
import ModificationCandidaturesJuryModal from "../../Common/InscriptionJuryModal/ModificationCandidatureJuryModal";
import DesincriptionConfirmDialog from "../CandidatureList/DesincriptionConfirmDialog/DesincriptionConfirmDialog";

const MAX_BACTH_SIZE = 5;

export const initialFilters: any = {
  nom: "",
  prenom: "",
  resultat: [],
  entreprises: undefined,
  ofs: undefined,
  certifications: undefined,
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  paginate: true,
});

export const isConsultCandidaturesListAtom = atomWithReset(false);
export const candidaturesListAtom = atomWithReset([]);
export const candidaturesTreatedListAtom = atom((get) => {
  const candidaturesList: any = get(candidaturesListAtom);

  return candidaturesList.filter((i: any) => i.isTreated);
});
export const candidaturesNeedActionListAtom = atom((get) => {
  const juryInfo: any = get(juryInfoAtom);
  const userBranchInfo: any = get(userSelectedBranchAtom);
  const userGeneralInfo: any = get(authAtom);
  const allCandidatures: any = get(candidaturesListAtom);
  const sort: any = get(sortAtom);

  const isAdmin =
    userGeneralInfo.user.est_administrateur ||
    userBranchInfo?.profils.includes("ADMINISTRATEUR_BRANCHE") ||
    userBranchInfo?.profils.includes("ADMINISTRATEUR_DOMAINE");

  var candidaturesNeedActionsFromMe: any = [];
  if (isAdmin) {
    candidaturesNeedActionsFromMe = allCandidatures.filter(
      (cand: any) => cand.statut != "resultats_jury"
    );
  } else {
    if (
      juryInfo.president_jury_uuid == userGeneralInfo.user.uuid &&
      tools.isThisStartOfDateBeforeOrEqualStartOfToday(
        juryInfo?.date_acces_membres_jury
      ) &&
      tools.isThisEndOfDateAfterStartOfToday(juryInfo?.date_jury)
    ) {
      candidaturesNeedActionsFromMe = allCandidatures.filter(
        (cand: any) => cand.statut == "decision_jury"
      );
    }
    if (
      juryInfo.membres?.find((m: any) => m.uuid == userGeneralInfo.user.uuid) &&
      tools.isThisStartOfDateBeforeOrEqualStartOfToday(
        juryInfo?.date_acces_membres_jury
      ) &&
      tools.isThisEndOfDateAfterStartOfToday(juryInfo?.date_jury)
    ) {
      candidaturesNeedActionsFromMe = allCandidatures
        .filter((cand: any) => cand.statut != "resultats_jury")
        .filter(
          (cand: any) =>
            !cand.avis_jury?.find(
              (a: any) => a.membre_jury.uuid == userGeneralInfo.user.uuid
            )
        );
    }
  }

  return candidaturesNeedActionsFromMe
    .filter((cand: any) => !cand.isTreated)
    .sort(tools.fieldSorterBo(sort.split(",")));
});

const isLoadingCandidaturesAtom = atom(false);
const isLoadingFirstCandidaturesAtom = atom(false);

const candidaturesTotalAtom: any = atom(0);
const nbCandidatureLoadedAtom: any = atom(0);
const isInitialFirstRenderAtom: any = atom(true);
const isInitialRenderAtom: any = atomWithReset(true);
const previousJuryCallUuidAtom: any = atom(null);

const sortAtom = atom("");

function useCandidatureJuryList() {
  const candidatureProps = useCandidature();
  const { actions: modalActions } = useCustomModal();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [sort, setSort] = useAtom(sortAtom);
  const [optionsFilters] = useAtom(optionsFiltersAtom);
  const [candidaturesNeedActionList] = useAtom(candidaturesNeedActionListAtom);
  const [candidaturesTreatedList] = useAtom(candidaturesTreatedListAtom);
  const [, setIsConsultCandidaturesList] = useAtom(
    isConsultCandidaturesListAtom
  );
  const [candidaturesList, setCandidaturesList]: any =
    useAtom(candidaturesListAtom);
  const [nbCandidatureLoaded, setNbCandidatureLoaded]: any = useAtom(
    nbCandidatureLoadedAtom
  );
  const navigate = useNavigate();
  const [isLoadingCandidatures, setIsLoadingCandidatures] = useAtom(
    isLoadingCandidaturesAtom
  );
  const [isLoadingFirstCandidatures, setIsLoadingFirstCandidatures] = useAtom(
    isLoadingFirstCandidaturesAtom
  );
  const [isExportingCandidatures, setIsExportingCandidatures] = useState(false);

  const { uuid_jury } = useParams();
  const [previousJuryCallUuid, setPreviousJuryCallUuid] = useAtom(
    previousJuryCallUuidAtom
  );
  const [candidaturesTotal, setCandidaturesTotal]: any = useAtom(
    candidaturesTotalAtom
  );
  const [isInitialFirstRender, setIsInitialFirstRender]: any = useAtom(
    isInitialFirstRenderAtom
  );
  const [isInitialRender, setIsInitialRender] = useAtom(isInitialRenderAtom);
  const resetIsInitialRender = useResetAtom(isInitialRenderAtom);

  const actions = {
    openGiveUpCandidatureModal: (candidature: any) => {
      modalActions.openModal({
        body: (
          <GiveUpConfirmDialog
            candidat={`${candidature?.candidat?.nom} ${candidature?.candidat?.prenom}`}
            giveUpFunction={() =>
              candidatureProps.actions.patchGiveUpCandidature(candidature.uuid)
            }
          />
        ),
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      actions.getAllCandidatures(uuid_jury, newFilters);
    },
    onConsultCandidatures: () => {
      if (candidaturesNeedActionList) {
        setIsConsultCandidaturesList(true);
        navigate(
          `/admin/candidature/${candidaturesNeedActionList[0]["uuid"]}/${candidaturesNeedActionList[0]["statut"]}/true`
        );
      }
    },
    getAllCandidatures: async (juryUuid: any, overrideFilters: any) => {
      if (isInitialFirstRender) {
        setIsInitialFirstRender(false);
      }
      setIsInitialRender(false);
      setPreviousJuryCallUuid(juryUuid);
      setIsLoadingCandidatures(true);
      setIsLoadingFirstCandidatures(true);
      const response = await getCandidaturesJuryRequest(
        { ...overrideFilters, jury_associe: juryUuid },
        optionsFilters
      );
      if (!response.error) {
        setCandidaturesList((u: any) => []);
        setCandidaturesTotal(response?.meta?.total);
        setIsLoadingFirstCandidatures(false);
        const nbPages = Math.ceil(response.meta.total / response.meta.per_page);
        const batchSize = nbPages < MAX_BACTH_SIZE ? nbPages : MAX_BACTH_SIZE;
        const howManyBatches = Math.ceil(nbPages / batchSize);

        var allResults: any = [];
        for (let j = 1; j <= howManyBatches; j++) {
          const batch = await actions.getBatch(batchSize, j, {
            ...overrideFilters,
            jury_associe: juryUuid,
          });
          allResults = [...allResults, ...batch];
        }
        setCandidaturesList((prev: any) => [...prev, ...allResults]);
      } else {
        toast.error(response.error?.message);
      }
      setIsLoadingCandidatures(false);
      setNbCandidatureLoaded(0);
      setCandidaturesTotal(0);
    },
    exportCandidatures: async () => {
      setIsExportingCandidatures(true);
      try {
        const response = await exportCandidaturesJuryRequest({
          ...filters,
          jury_associe: uuid_jury,
        });
        toast.success(
          "La génération de votre export est en cours, un lien de téléchargement vous sera envoyé par email dans quelques instants."
        );
      } catch (err: any) {
        toast.error(
          err?.response?.data?.message ||
            "Une erreur est survenue lors de l'export"
        );
      }
      setIsExportingCandidatures(false);
    },
    getBatch: async (
      nbPagesToFetch: any,
      batchNumber: any,
      searchParams: any
    ) => {
      var min = (batchNumber - 1) * nbPagesToFetch + 1; // starting page of batch
      var max = batchNumber * nbPagesToFetch; // ending page of batch
      const allPromises = [];
      for (var i = min; i <= max; i++) {
        allPromises.push(
          getCandidaturesJuryRequest(
            { ...searchParams },
            { ...optionsFilters, page: i }
          ).then((v: any) => {
            //SI utiliser a + endroits pour different cas deplace le set dans la fonction approprié (incrementation tous les nb data * nb page fetch)
            setNbCandidatureLoaded((prev: any) => prev + v.data.length);
            return v;
          })
        );
      }
      var allResults: any = [];
      return Promise.all(allPromises).then((values: any) => {
        values.map((v: any) => {
          allResults = [...allResults, ...v.data];
        });
        return allResults;
      });
    },
    sortColumnBo: (name: string) => {
      const sortArray = sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);
      setSort(sortJoin);
      setCandidaturesList(
        candidaturesList.sort(tools.fieldSorterBo(sortJoin.split(",")))
      );
    },
    changeJuryCandidatures: () => {
      console.log("candidaturesList", candidaturesList);
      const candidatureSansDecisionPresident = candidaturesList.filter(
        (c: any) => c.statut !== "resultats_jury"
      );
      console.log(
        "candidatureSansDecisionPresident",
        candidatureSansDecisionPresident
      );
      if (candidatureSansDecisionPresident?.length == 0) {
        alert(
          "Aucune candidature pour cette recherche ne possède un statut permettant encore de changer de jury."
        );
        return;
      }
      modalActions.openModal({
        header: "Modification du jury associé à la candidature",
        body: (
          <ModificationCandidaturesJuryModal
            candidatures={candidatureSansDecisionPresident}
            onSaved={(jury: any) => {
              console.log("transfered to ", jury);
              actions.getAllCandidatures(uuid_jury, filters);
            }}
          />
        ),
        fullWidth: true,
      });
    },
    openDesincriptionConfirmModal: (candidature: any) => {
      modalActions.openModal({
        body: (
          <DesincriptionConfirmDialog
            candidat={`${candidature?.candidat?.nom} ${candidature?.candidat?.prenom}`}
            desinscriptionAction={() => {
              candidatureProps.actions.patchDesinscireCandidature(
                candidature.uuid,
                {
                  onSuccess: () => {
                    setCandidaturesList(
                      candidaturesList.filter(
                        (cand: any) => cand.uuid != candidature.uuid
                      )
                    );
                  },
                }
              );
            }}
          />
        ),
      });
    },
  };

  return {
    candidaturesList,
    candidaturesTotal,
    isLoadingCandidatures,
    isLoadingFirstCandidatures,
    filters,
    candidaturesNeedActionList,
    candidaturesTreatedList,
    candidatureProps,
    modalActions,
    actions,

    previousJuryCallUuid,
    isInitialFirstRender,
    isInitialRender,
    resetIsInitialRender,

    nbCandidatureLoaded,
    isExportingCandidatures,
    sort,
  };
}

export default useCandidatureJuryList;
