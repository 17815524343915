import { DatePicker, Dropdown, Label, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import BackLink from "../../Common/BackLink/BackLink";
import Page from "../../Common/Page/Page";
import useCertificationList from "../../hooks/certifications/useCertificationList";
import useHabilitationUtilisateurDetail from "./useHabilitationUtilisateurDetail";
import Loader from "../../Common/Loader/Loader";
import FormSection from "../../Common/FormSection/FormSection";
import CancelButton from "../../Common/CancelButton/CancelButton";
import LoadButton from "../../Common/LoadButton/LoadButton";
import { RiAddFill } from "react-icons/ri";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../styles";
import { strings } from "../../config/datePickerConfig";
import tools from "../../utils/tools";
import ErrorMessage from "../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import FileInput from "../../Common/FileInput/FileInput";
import { branchAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import SelectFieldAsync from "../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../config/config";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const ROLES_WITH_HABILITATION = [
  { id: "EVALUATEUR", text: "Evaluateur", key: "EVALUATEUR" },
  { id: "POSITIONNEUR", text: "Positionneur", key: "POSITIONNEUR" },
];

enum ROLE_HABILITATION_PARAMETRE {
  "EVALUATEUR" = branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
  "POSITIONNEUR" = branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
}

function HabilitationUtilisateurDetail() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [isPrefillInput, setIsPrefillInput] = useState(false);
  const { reactHookProps, actions, habilitationProps } =
    useHabilitationUtilisateurDetail();
  const {
    control,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setError,
  } = reactHookProps;
  const { errorPostHabilitationUtilisateur } = habilitationProps;
  const { actions: accessActions } = useAccessSettings();
  const {
    certificationsFromApi,
    isLoadingCertifications,
    isRefetchingCertifications,
  } = useCertificationList();

  useEffect(() => {
    var apiErrors = (errorPostHabilitationUtilisateur as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostHabilitationUtilisateur]);

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actions.onSubmit)(e);
  };

  const roleCanHaveHabilitation = [...ROLES_WITH_HABILITATION].filter(
    (role) =>
      accessActions.canI({
        action: ROLE_HABILITATION_PARAMETRE[role.id as any],
      }).granted
  );

  const isOnlyOneRole = roleCanHaveHabilitation.length == 1;

  useEffect(() => {
    if (!parsed?.userUuid) {
      setValue("utilisateur", null);
      if (
        isOnlyOneRole &&
        watch("profil")?.id != roleCanHaveHabilitation[0]?.id
      ) {
        setValue("profil", roleCanHaveHabilitation[0]);
      }
    } else {
      setIsPrefillInput(true);
      setValue("utilisateur", {
        uuid: parsed.userUuid,
        nom: parsed.nom,
        prenom: parsed.prenom,
      });
      setValue(
        "profil",
        tools.findIn(roleCanHaveHabilitation, parsed.userRole, "id")
      );
    }
  }, [watch("profil")]);

  return (
    <Stack>
      <BackLink />
      <Page
        title={"Veuillez créer une habilitation"}
        explain={
          "Veuillez créer une nouvelle habilitation rattachée à un utilisateur"
        }
      >
        {isLoadingCertifications || isRefetchingCertifications ? (
          <Loader />
        ) : (
          <form onSubmit={onSubmit}>
            <FormSection sectionTitle="Informations de l'habilitation">
              <Stack.Item>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      selectedKey={value ? value.key : undefined}
                      label="Rôle :"
                      required
                      placeholder="Sélectionnez un rôle"
                      options={ROLES_WITH_HABILITATION}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                      disabled={isOnlyOneRole || isPrefillInput}
                    />
                  )}
                  control={control}
                  name="profil"
                />
              </Stack.Item>
              {watch("profil") && (
                <Stack.Item>
                  <Controller
                    render={({ field: { name, value, onBlur, ref } }) => (
                      <SelectFieldAsync
                        disabled={isPrefillInput}
                        label="Utilisateur :"
                        placeholder="Recherchez un utilisateur par son nom, prénom ou email"
                        resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
                        addQueryURL={(resourceURI: any, terms: any) =>
                          `${resourceURI}?paginate=0&roles[]=${
                            watch("profil").id
                          }&recherche=${terms}`
                        }
                        method="GET"
                        renderItem={(item: any) => item.nom + " " + item.prenom}
                        renderValue={(item: any) =>
                          item.nom + " " + item.prenom
                        }
                        dataIndex="uuid"
                        name={name}
                        value={value}
                        fieldRef={ref}
                        onChange={(e: any) => {
                          setValue(name, e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        handleResponse={(response: any) => [
                          ...response.data.data,
                        ]}
                        required
                      />
                    )}
                    control={control}
                    name="utilisateur"
                  />
                </Stack.Item>
              )}
              <Stack.Item>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Dropdown
                        required
                        selectedKey={value ? value.key : undefined}
                        label="Certification :"
                        placeholder="Sélectionnez une certification"
                        options={certificationsFromApi}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    );
                  }}
                  defaultValue={null}
                  control={control}
                  name="certification"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { value, name } }) => (
                    <DatePicker
                      placeholder="dd/mm/yyyy"
                      ariaLabel="dd/mm/yyyy"
                      value={value}
                      onSelectDate={(date: any) =>
                        date ? setValue(name, date) : null
                      }
                      styles={commonInputStyles.textField}
                      label="Date de début"
                      isRequired
                      strings={strings}
                      formatDate={(date: any): any =>
                        date ? `${tools.reformateDateFromJsDate(date)}` : null
                      }
                      allowTextInput
                      parseDateFromString={(dateStr: any) =>
                        tools.parseDateFromStringDatePicker(dateStr)
                      }
                    />
                  )}
                  control={control}
                  name="date_debut"
                />
                <ErrorMessage
                  errorMessage={(errors.date_debut as any)?.message}
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { value, name } }) => (
                    <DatePicker
                      placeholder="dd/mm/yyyy"
                      ariaLabel="dd/mm/yyyy"
                      value={value}
                      onSelectDate={(date: any) =>
                        date ? setValue(name, date) : null
                      }
                      styles={commonInputStyles.textField}
                      label="Date de fin"
                      isRequired
                      strings={strings}
                      formatDate={(date: any): any =>
                        date ? `${tools.reformateDateFromJsDate(date)}` : null
                      }
                      allowTextInput
                      parseDateFromString={(dateStr: any) =>
                        tools.parseDateFromStringDatePicker(dateStr)
                      }
                    />
                  )}
                  control={control}
                  name="date_fin"
                />
                <ErrorMessage
                  errorMessage={(errors.date_fin as any)?.message}
                />
              </Stack.Item>

              <Stack.Item>
                <Label styles={commonInputStyles.label}>
                  Convention d'habilitation :
                </Label>
                <FileInput
                  name="documents"
                  nameDisplay="convention_habilitation.pdf"
                  file={watch("documents") ? watch("documents")[0]?.file : []}
                  onUpdateFile={(newFile: any) => {
                    setValue("documents", [
                      { file: newFile.target.value, type: "convention" },
                    ]);
                  }}
                  onDeleteFile={() => setValue("documents", undefined)}
                  existingFileURL={
                    watch("documents") ? watch("documents")[0]?.file_url : null
                  }
                  acceptedFileTypes={["PDF"]}
                />
              </Stack.Item>
            </FormSection>
            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ marginTop: 20 }}
            >
              <CancelButton />

              <LoadButton
                text={"Valider"}
                isLoading={false}
                icon={<RiAddFill size={38} />}
                style={{ fontWeight: "bold" }}
              />
            </Stack>
          </form>
        )}
      </Page>
    </Stack>
  );
}

export default HabilitationUtilisateurDetail;
