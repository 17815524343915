import { Stack } from "@fluentui/react";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import CompetenceBloc from "../../../Common/CompetenceBloc/CompetenceBloc";
import SectionTitle from "../../../Common/SectionTitle/SectionTitle";
import tools from "../../../utils/tools";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { StatutCandidature } from "../../../hooks/candidature/useCandidatureWorkflow";
import useUrlParams from "../useUrlParams";
import Accordion from "../../../Common/Accordion/Accordion";
import { useState } from "react";

const blocLabelsByStatus: any = {
  default: {
    OPTION_BLOCS: "OPTION & BLOCS DE COMPÉTENCES",
    OPTION_BLOCSIMPOSE: "Blocs imposés",
    OPTION_BLOCSAUCHOIX: "Blocs au choix",
    OPTION_BLOCSOPTIONNEL: "Blocs optionnels",
    POSITIONNEMENT_BLOC: "Positionnement du bloc de compétences",
    EVALUATION_BLOC: "Évaluation du bloc de compétences",
    COMPETENCES_ASSOCIEES: "COMPETENCES ASSOCIÉES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT: "Positionnement de la compétence",
    COMPETENCES_ASSOCIEES_EVALUATION: "Évaluation de la compétence",
  },
  positionnement: {
    OPTION_BLOCS: "POSITIONNEMENT_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "POSITIONNEMENT_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "POSITIONNEMENT_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES:
      "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
  },
  validation_positionnement: {
    OPTION_BLOCS: "POSITIONNEMENT_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "POSITIONNEMENT_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "POSITIONNEMENT_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES:
      "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "POSITIONNEMENT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
  },
  evaluation: {
    OPTION_BLOCS: "EVALUATION_CANDIDAT_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    EVALUATION_BLOC:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_EVALUATION",
    COMPETENCES_ASSOCIEES:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES_EVALUATION:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_EVALUATION",
  },
  validation_evaluation: {
    OPTION_BLOCS: "EVALUATION_CANDIDAT_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    EVALUATION_BLOC:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_EVALUATION",
    COMPETENCES_ASSOCIEES:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES_EVALUATION:
      "EVALUATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_EVALUATION",
  },
  avis_second_evaluateur: {
    OPTION_BLOCS: "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    EVALUATION_BLOC:
      "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_EVALUATION",
    COMPETENCES_ASSOCIEES:
      "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES_EVALUATION:
      "AVIS_SECOND_EVALUATEUR_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_EVALUATION",
  },
  decision_jury: {
    OPTION_BLOCS: "DEROULEMENT_JURY_JURY_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    EVALUATION_BLOC:
      "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_EVALUATION",
    COMPETENCES_ASSOCIEES:
      "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES_EVALUATION:
      "DEROULEMENT_JURY_JURY_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_EVALUATION",
  },
  resultats_jury: {
    OPTION_BLOCS: "CERTIFICATION_CANDIDAT_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    EVALUATION_BLOC:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_EVALUATION",
    COMPETENCES_ASSOCIEES:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES_EVALUATION:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_EVALUATION",
  },
  transmission_dossier: {
    OPTION_BLOCS: "CERTIFICATION_CANDIDAT_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    EVALUATION_BLOC:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_EVALUATION",
    COMPETENCES_ASSOCIEES:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES_EVALUATION:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_EVALUATION",
  },
  avis_formateur: {
    OPTION_BLOCS: "CERTIFICATION_CANDIDAT_OPTION_BLOCS",
    OPTION_BLOCSIMPOSE: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE",
    OPTION_BLOCSAUCHOIX: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCAUCHOIX",
    OPTION_BLOCSOPTIONNEL: "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCOPTIONNEL",
    POSITIONNEMENT_BLOC:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_POSITIONNEMENT",
    EVALUATION_BLOC:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_EVALUATION",
    COMPETENCES_ASSOCIEES:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES",
    COMPETENCES_ASSOCIEES_POSITIONNEMENT:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_POSITIONNEMENT",
    COMPETENCES_ASSOCIEES_EVALUATION:
      "CERTIFICATION_CANDIDAT_OPTION_BLOCS_BLOCIMPOSE_AUCHOIX_OPTIONNEL_COMPETENCESASSOCIEES_EVALUATION",
  },
};

function OptionsBlocsSection({
  candidature,
  statutCandidature,
  showDecisions = true,
  reactHookProps = {},
  disabled,
  isPresident = false,
  onInvalidate = () => {},
  onValidate = () => {},
}: any) {
  const [isUpdateAll, setIsUpdateAll] = useState(false);
  const { statut_candidature } = useUrlParams();
  const { labels } = useLabelSettings();
  const blocs_competences_passes = reactHookProps.watch(
    "blocs_competences_passes"
  );

  const onChangeBloc = (newBloc: any) => {
    if (reactHookProps.setValue) {
      reactHookProps.setValue(
        "blocs_competences_passes",
        reactHookProps.watch("blocs_competences_passes").map((bloc: any) => {
          if (bloc.uuid === newBloc.uuid) {
            return newBloc;
          }
          return bloc;
        })
      );
    }
  };
  let labelsInContext: any = {};

  if (statut_candidature && blocLabelsByStatus[statut_candidature]) {
    Object.keys(blocLabelsByStatus[statut_candidature]).forEach((key: any) => {
      labelsInContext[key] =
        labels[blocLabelsByStatus[statut_candidature][key]];
    });
  } else {
    labelsInContext = blocLabelsByStatus.default;
  }

  const blocsImposes = blocs_competences_passes?.filter(
    (bloc: any) => bloc.statut == 1
  );
  const blocsAuChoix = blocs_competences_passes?.filter(
    (bloc: any) => bloc.statut == 2
  );
  const blocsOptionnels = blocs_competences_passes?.filter(
    (bloc: any) => bloc.statut == 3
  );

  const allBlocs = [
    {
      key: "OPTION_BLOCSIMPOSE",
      blocs: blocsImposes,
    },
    {
      key: "OPTION_BLOCSAUCHOIX",
      blocs: blocsAuChoix,
    },
    {
      key: "OPTION_BLOCSOPTIONNEL",
      blocs: blocsOptionnels,
    },
  ];

  return (
    <Stack>
      <SectionTitle title={labelsInContext.OPTION_BLOCS} />

      {/* 
       Afficher les boutons SI: 
          - Les inputs ne sont pas disabled ET
          - On ne se trouve pas sur une etape de la creation de la candidature ET
          - On n'est pas ( sur la page decision du jury en tant que utilisateur autre que president ou admin ) 
          - On ne se trouve pas sur l'etape avis_formation
      */}
      {!disabled &&
        statutCandidature != StatutCandidature.CREATION_CANDIDATURE &&
        !(
          statutCandidature == StatutCandidature.DECISION_JURY && !isPresident
        ) &&
        !(statutCandidature == StatutCandidature.AVIS_FORMATEUR) && (
          <Stack
            horizontal
            horizontalAlign="end"
            style={{ marginTop: 20 }}
            wrap
          >
            <LoadButton
              type="button"
              onClick={() => {
                onValidate();
                setIsUpdateAll(true);
              }}
              text={"Tout valider"}
              style={{ marginRight: 20, marginBottom: 10 }}
            />
            <CancelButton
              onClick={() => {
                onInvalidate();
                setIsUpdateAll(true);
              }}
              text={"Tout invalider"}
              style={{ marginBottom: 10 }}
            />
          </Stack>
        )}

      {/* ADD SECTION BLOC */}
      {candidature.option_selectionnee && (
        <div style={{ marginBottom: 20 }}>
          Option sélectionnée :{" "}
          <strong>
            {
              (
                tools.findIn(
                  candidature.certification.options,
                  candidature.option_selectionnee,
                  "uuid"
                ) as any
              ).option
            }
          </strong>
        </div>
      )}
      {allBlocs.map((blocopt: any) => {
        if (blocopt?.blocs?.length == 0) {
          return null;
        }
        return (
          <Accordion
            key={labelsInContext[blocopt.key]}
            title={`${labelsInContext[blocopt.key]} (${blocopt.blocs?.length})`}
            noImg
            defaultIsOpen={true}
            content={blocopt.blocs?.map((bl: any, index: number) => {
              return (
                <CompetenceBloc
                  index={index + 1}
                  candidature={candidature}
                  viewDecision={showDecisions}
                  bloc={bl}
                  status={statutCandidature}
                  reactHookProps={reactHookProps}
                  onChangeBloc={onChangeBloc}
                  key={`comblock${bl.uuid}`}
                  disabled={disabled}
                  labels={labelsInContext}
                  isPresident={isPresident}
                  isUpdateAll={isUpdateAll}
                />
              );
            })}
            styles={{
              content: {
                root: {},
              },
            }}
          />
        );
      })}
    </Stack>
  );
}

const BlocGroupTitle = ({ title }: any) => {
  return (
    <div
      style={{
        marginBottom: 10,
        marginTop: 20,
        fontSize: 20,
        color: "black",
        display: "flex",
        alignItems: "center",
      }}
    >
      <HiOutlineChevronDoubleDown />
      <strong>{title}</strong>
    </div>
  );
};

export default OptionsBlocsSection;
