import { useForm } from "react-hook-form";
import useCandidature from "../../../hooks/candidature/useCandidature";
import { useState } from "react";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";

export const useAvisFormateur = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccessEnregistrementAvis, setIsSuccessEnregistrementAvis] =
    useState(false);
  const { candidature, actions: candidatureActions } = useCandidature();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const actions = {
    onSave: async (data: any, estEnregistre: any) => {
      setIsSaving(true);
      setIsSuccessEnregistrementAvis(false);
      const avisFormateurObject = {
        ...data,
        avis_formateur: {
          id: data.avis_formateur_id ?? null,
          nom: data.nom_formateur,
          prenom: data.prenom_formateur,
          email: data.email_formateur,
          date_debut_formation: data.date_debut_formation,
          date_fin_formation: data.date_fin_formation,
          avis: data.avis_formateur,
        },
        isEnregistrerAvisFormateur: estEnregistre,
      };

      await candidatureActions.putCandidatureFormData(
        avisFormateurObject,
        STATUT_PAGE,
        reactHookProps
      );

      if (estEnregistre) {
        setIsSuccessEnregistrementAvis(true);
      }

      setIsSaving(false);
    },
  };

  return {
    isSaving,
    isSuccessEnregistrementAvis,
    candidatureActions,
    candidature,
    reactHookProps,
    actions,
  };
};
