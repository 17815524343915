import { Label, Stack, Text } from "@fluentui/react";
import Badge from "../../../Common/Badge/Badge";
import useTheme from "../../../Common/useTheme";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import tools from "../../../utils/tools";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";

enum StatutCandidatureLibelleId {
  "creation_candidature" = "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_CREATIONCANDIDATURE_DESCRIPTION",
  "saisie_informations" = "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_SAISIEINFORMATIONS_DESCRIPTION",
  "choix_blocs" = "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_CHOIXDESBLOCS_DESCRIPTION",
  "saisie_parcours" = "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_SAISIEPARCOURS_DESCRIPTION",
  "identification_parties_prenantes" = "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_IDENTIFICATIONPARTIEPRENANTES_DESCRIPTION",
}

const CandidatureStatus = () => {
  const { STATUTS_CANDIDATURE } = useCandidatureWorkflow();
  const { candidature } = useCandidature();
  const { primaryColor } = useTheme();
  const { statut_candidature } = useParams();
  const { labels } = useLabelSettings();

  var descriptionCandidatureStatut = "";
  if (
    Object.keys(StatutCandidatureLibelleId).includes(statut_candidature ?? "")
  ) {
    descriptionCandidatureStatut =
      // @ts-ignore
      labels[StatutCandidatureLibelleId[statut_candidature]] ?? "";
  }

  const notificationInvitationSecondEvaluateur = candidature.second_evaluateur
    ? candidature.second_evaluateur.notifications
        .filter((n: any) => n.data.candidature == candidature.uuid)
        .find(
          (n: any) =>
            n.type == "InviterSecondEvaluateur" ||
            n.type == "CreationInvitationSecondEvaluateur"
        )
    : null;
  const dateMaxToGiveInvitationSecondEvaluateur =
    notificationInvitationSecondEvaluateur
      ? DateTime.fromISO(notificationInvitationSecondEvaluateur.created_at)
          .startOf("day")
          .plus({ day: 30 })
      : null;
  const howManyDaysToGiveAvis = dateMaxToGiveInvitationSecondEvaluateur
    ? dateMaxToGiveInvitationSecondEvaluateur.diff(
        DateTime.now().startOf("day"),
        ["days"]
      )
    : null;

  const isEvaluationAlreadyDone = candidature.blocs_competences_passes
    ? candidature.blocs_competences_passes[0]
      ? candidature.blocs_competences_passes[0]["decision_evaluation"]
      : null
    : null;

  return (
    <Stack
      style={{ marginTop: 10, marginBottom: 10 }}
      horizontal={descriptionCandidatureStatut ? true : false}
      horizontalAlign={descriptionCandidatureStatut ? "start" : "end"}
      wrap
    >
      {descriptionCandidatureStatut && (
        <Stack className="ms-Grid-col ms-sm12 ms-lg7">
          <div
            dangerouslySetInnerHTML={{ __html: descriptionCandidatureStatut }}
          />
        </Stack>
      )}
      <Stack className="ms-Grid-col ms-sm12 ms-lg5">
        <Stack horizontal verticalAlign="center" wrap horizontalAlign="end">
          <Label style={{ textAlign: "end" }}>STATUT DE LA CANDIDATURE :</Label>
          <Badge
            style={{ marginLeft: 10 }}
            text={
              (
                tools.findIn(
                  STATUTS_CANDIDATURE,
                  candidature.statut,
                  "id"
                ) as any
              ).text
            }
            color="#f0f0f0"
            textStyle={{ color: primaryColor }}
          />
        </Stack>
        <Stack horizontal verticalAlign="center" wrap horizontalAlign="end">
          {candidature.statut != "creation_candidature" ? (
            <>
              <Label style={{ marginRight: 10 }}>CRÉÉE PAR :</Label>
              <Stack>
                {candidature.cree_par ? (
                  <Text>
                    {candidature.cree_par.nom
                      ? candidature.cree_par.nom
                      : candidature.cree_par.raison_sociale}
                  </Text>
                ) : (
                  <Text>Administrateur</Text>
                )}
                {candidature.createur?.email && (
                  <>
                    <Text>{tools.getFullname(candidature.createur)}</Text>
                    <Text>{candidature.createur.email}</Text>
                  </>
                )}
              </Stack>
            </>
          ) : null}
        </Stack>
        {candidature.statut != "valider_avis_formateur" &&
          statut_candidature == "valider_avis_formateur" && (
            <>
              <Stack
                horizontal
                verticalAlign="center"
                wrap
                horizontalAlign="end"
              >
                <Label style={{ textAlign: "end", marginRight: 10 }}>
                  VALIDÉ PAR :
                </Label>
                <Stack>
                  <Stack.Item>
                    {candidature?.avis_formateur?.valide_par?.nom}{" "}
                    {candidature?.avis_formateur?.valide_par?.prenom}
                  </Stack.Item>
                  <Stack.Item>
                    {candidature?.avis_formateur?.valide_par?.email}
                  </Stack.Item>
                </Stack>
              </Stack>
            </>
          )}
        {candidature.statut == "evaluation" &&
          candidature.second_evaluateur && (
            <>
              <Stack
                horizontal
                verticalAlign="center"
                wrap
                horizontalAlign="end"
              >
                <Label style={{ textAlign: "end" }}>SECOND ÉVALUATEUR :</Label>
                {candidature.second_evaluateur.nom}{" "}
                {candidature.second_evaluateur.prenom}{" "}
                {candidature.second_evaluateur.email}
                <Badge
                  style={{ marginLeft: 10 }}
                  text={
                    candidature.second_evaluateur.is_verified_account
                      ? "Compte vérifié"
                      : "Compte non vérifié"
                  }
                  color={
                    candidature.second_evaluateur.is_verified_account
                      ? primaryColor
                      : "red"
                  }
                />
              </Stack>
              {notificationInvitationSecondEvaluateur && (
                <Stack
                  horizontal
                  verticalAlign="center"
                  wrap
                  horizontalAlign="end"
                >
                  <Label style={{ textAlign: "end" }}>
                    JOURS RESTANTS POUR RENSEIGNER LA SECONDE ÉVALUATION :{" "}
                  </Label>
                  <Badge
                    style={{ marginLeft: 10 }}
                    text={howManyDaysToGiveAvis?.toObject().days}
                    color={
                      (howManyDaysToGiveAvis as any)?.toObject().days < 0
                        ? "red"
                        : primaryColor
                    }
                  />
                </Stack>
              )}
              {isEvaluationAlreadyDone &&
                notificationInvitationSecondEvaluateur && (
                  <Stack
                    horizontal
                    verticalAlign="center"
                    wrap
                    horizontalAlign="end"
                  >
                    <Badge
                      style={{ marginLeft: 10 }}
                      text={"Évaluation transmise"}
                      color={"green"}
                    />
                  </Stack>
                )}
            </>
          )}
        {candidature.statut != "valider_evaluation" &&
          statut_candidature == "valider_evaluation" && (
            <>
              <Stack
                horizontal
                verticalAlign="center"
                wrap
                horizontalAlign="end"
              >
                <Label style={{ textAlign: "end", marginRight: 10 }}>
                  VALIDÉ PAR :
                </Label>
                <Stack>
                  <Stack.Item>
                    {candidature?.valider_evaluation?.valide_par?.nom}{" "}
                    {candidature?.valider_evaluation?.valide_par?.prenom}
                  </Stack.Item>
                  <Stack.Item>
                    {candidature?.valider_evaluation?.valide_par?.email}
                  </Stack.Item>
                </Stack>
              </Stack>
            </>
          )}
      </Stack>
    </Stack>
  );
};

export default CandidatureStatus;
