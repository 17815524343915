import useCandidature from "../../../hooks/candidature/useCandidature";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";

function useValidationAvisFormateur() {
  const { auth } = useAuth();
  const { candidature, actions: candidatureActions } = useCandidature();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const reactHookProps = useForm({
    mode: "onBlur",
  });
  const [isSaving, setIsSaving] = useState(false);

  const isCandidat = candidature.candidat.utilisateur_uuid === auth.user.uuid;

  const actions = {
    onSubmit: async (data: any) => {
      setIsSaving(true);

      await candidatureActions.putCandidature(
        {
          ...data,
          _method: "PUT",
          statut_a_modifier: "valider_avis_formateur",
        },
        STATUT_PAGE,
        reactHookProps
      );

      setIsSaving(false);
    },
  };

  return {
    candidature,
    isCandidat,
    isSaving,
    actions,
    reactHookProps,
  };
}

export default useValidationAvisFormateur;
