import { Label, Stack } from "@fluentui/react";
import useValidationAvisFormateur from "./useValidationAvisFormateur";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import InfoMessage from "../../../Common/SelectField/common/InfoMessage/InfoMessage";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CandidatSection from "../Common/CandidatSection";
import CertificationSection from "../Common/CertificationSection";
import useFetchCandidature from "../useFetchCandidature";
import tools from "../../../utils/tools";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import { userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";

function ValidationAvisFormateur() {
  const { isThisPageStatusBeforeCandidatureStatus } = useCandidatureWorkflow();
  const { candidature, isCandidat, isSaving, actions, reactHookProps } =
    useValidationAvisFormateur();
  const { isAdmin } = useInfoProfilUser();
  const { actions: accessActions } = useAccessSettings();

  const fetchCandidature = useFetchCandidature(reactHookProps);

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? userAccessEnum.NO_ACCESS_EVEN_ADMIN
    : userAccessEnum.VALIDER_AVIS_FORMATEUR;

  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  const onSubmit = (e: any) => {
    reactHookProps.clearErrors();
    reactHookProps.handleSubmit((data: any) => actions.onSubmit(data))(e);
  };

  return (
    <Stack>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>
      <Stack>
        <Stack.Item style={{ display: "flex", alignItems: "center" }}>
          <Label style={{ marginRight: 5 }}>
            Date de création de la candidature :
          </Label>
          <p>{tools.formatDate(candidature?.cree_le)}</p>
        </Stack.Item>
        <Stack.Item style={{ display: "flex", alignItems: "center" }}>
          <Label style={{ marginRight: 5 }}>Date de l'avis formateur :</Label>
          <p>{tools.formatDate(candidature?.avis_formateur?.cree_le)}</p>
        </Stack.Item>
        <Stack.Item style={{ display: "flex", alignItems: "center" }}>
          <Label style={{ marginRight: 5 }}> Nom du formateur :</Label>
          <p>{candidature?.avis_formateur?.nom}</p>
        </Stack.Item>
        <Stack.Item style={{ display: "flex", alignItems: "center" }}>
          <Label style={{ marginRight: 5 }}>Prénom du formateur :</Label>
          <p>{candidature?.avis_formateur?.prenom}</p>
        </Stack.Item>
        {candidature?.avis_formateur?.date_debut_formation && (
          <Stack.Item style={{ display: "flex", alignItems: "center" }}>
            <Label style={{ marginRight: 5 }}>
              Date de début de formation du candidat :
            </Label>
            <p>
              {tools.formatDate(
                candidature?.avis_formateur?.date_debut_formation
              )}
            </p>
          </Stack.Item>
        )}
        {candidature?.avis_formateur?.date_fin_formation && (
          <Stack.Item style={{ display: "flex", alignItems: "center" }}>
            <Label style={{ marginRight: 5 }}>
              Date de fin de formation du candidat :
            </Label>
            <p>
              {tools.formatDate(
                candidature?.avis_formateur?.date_fin_formation
              )}
            </p>
          </Stack.Item>
        )}
        <Stack.Item>
          <Label style={{ marginRight: 5 }}>Avis formateur :</Label>
          <p>{candidature?.avis_formateur?.avis}</p>
        </Stack.Item>
      </Stack>
      {!inputsDisabled && (
        <>
          <Stack horizontal horizontalAlign="end" style={{ marginTop: 20 }}>
            {isCandidat && (
              <LoadButton
                text={"J’ai pris connaissance de mon avis formateur"}
                isLoading={isSaving}
                type="button"
                onClick={onSubmit}
              />
            )}
            {isAdmin && (
              <LoadButton
                text={"Passer cette étape"}
                isLoading={isSaving}
                type="button"
                onClick={onSubmit}
              />
            )}
          </Stack>
          {isCandidat && (
            <Stack>
              <InfoMessage
                infoMessage={
                  "ATTENTION : Sans cette action de votre part, votre candidature ne pourra pas être évaluée. Pour rappel, seul le jury décide ou non de l’obtention de la certification."
                }
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}

export default ValidationAvisFormateur;
