import { atom, useAtom } from "jotai";
import candidatureAdapters from "../../../../adapters/candidatureAdapters";
import useDynamicFields from "../../../../hooks/dynamicFields/useDynamicFields";
import useCandidature from "../../../../hooks/candidature/useCandidature";
import {
  cancelAskMECRequest,
  postAskMECRequest,
  postMECRequest,
} from "../../../../services/candidatureService";
import tools from "../../../../utils/tools";

import useUrlParams from "../../useUrlParams";

import { toast } from "react-toastify";
import useCustomPanel from "../../../../Common/Overlays/CustomPanel/useCustomPanel";

export const isEditModeAtom = atom("");
const isConfirmDialogOpenAtom = atom(false);

const editableInputNames: any = {
  creation_candidature: [
    "candidat.nom",
    "candidat.nom_usage",
    "candidat.prenom",
    "candidat.date_de_naissance",
    "candidat.civilite",
    "candidat.telephone_portable",
    "candidat.telephone_professionnel",

    "candidat.ne_etranger",
    "candidat.pays_naissance",
    "candidat.code_postal_naissance",
    "candidat.region_naissance",
    "candidat.ville_naissance",

    "adresse",

    "dispositif_financement",
    "dispositif_financement_libelle",
    "type_contrat",
    "type_contrat_libelle",
  ],
  saisie_informations: [
    "dispositif_financement",
    "dispositif_financement_libelle",
    "type_contrat",
    "type_contrat_libelle",
    "situation_avant_cursus",
    "dernier_metier",
    "annees_experience",
    "niveau_dernier_diplome",
    "intitule_dernier_diplome",
    "attestation_dernier_diplome",
  ],
  saisie_parcours: [],
  identification_parties_prenantes: [],
  transmission_dossier: [],
  positionnement: [],
  evaluation: [],
  avis_formateur: [
    "nom_formateur",
    "prenom_formateur",
    "email_formateur",
    "date_debut_formation",
    "date_fin_formation",
    "avis_formateur",
  ],
};

const mecDetailAtom = atom({
  data: null,
  comment: "",
});
const mecRecipientsAtom = atom([]);
const isMECSavingAtom = atom(false);
const isLoadingMECRecipientsAtom = atom(false);
const isMECAskingAtom = atom(false);
const deletedAskedMECAtom = atom([]);

const useMiseEnConfirmite = () => {
  const { statut_candidature }: any = useUrlParams();
  const { candidature, actions } = useCandidature();
  const { actions: panelActions } = useCustomPanel();
  const [isMECSaving, setIsMECSaving] = useAtom(isMECSavingAtom);
  const [isMECAsking, setIsMecAsking] = useAtom(isMECAskingAtom);
  const [isLoadingMECRecipients, setIsLoadingMECRecipients] = useAtom(
    isLoadingMECRecipientsAtom
  );
  const [deletedAskedMEC, setDeletedAskedMEC]: any =
    useAtom(deletedAskedMECAtom);

  const { dynamicFields } = useDynamicFields();
  const [isEditMode, setIsEditMode] = useAtom(isEditModeAtom);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useAtom(
    isConfirmDialogOpenAtom
  );
  const [mecDetail, setMecDetail]: any = useAtom(mecDetailAtom);
  const [mecRecipients, setMecRecipients] = useAtom(mecRecipientsAtom);

  const mecActions = {
    onActivateMEC: (statut: any) => {
      setIsEditMode(statut);
    },
    onDeactivateMEC: (onAfterValidate = () => {}) => {
      setIsEditMode("");
      setMecDetail({
        data: null,
        comment: "",
      });
      onAfterValidate();
    },
    onGetEditableInputNames: () => {
      return isEditMode ? editableInputNames[statut_candidature] : [];
    },
    onSaveMEC: (data: any) => {
      setMecDetail((d: any) => ({ ...d, data }));
      mecActions.onOpenConfirmDialog();
    },
    onOpenConfirmDialog: () => {
      setIsConfirmDialogOpen(true);
    },
    onCloseConfirmDialog: () => {
      setIsConfirmDialogOpen(false);
    },
    onValidateDialog: async (
      reactHookProps: any,
      onAfterValidate = () => {}
    ) => {
      //CALL API ROUTE TO SAVE MEC
      //dont forget to transformForApi mecDetail.data before !
      setIsMECSaving(true);
      try {
        const mer = {
          uuid: null,
          candidature_uuid: mecDetail.data.uuid,
          candidature_statut: isEditMode,
          commentaire_correcteur: mecDetail.comment,
          candidatureData: {
            ...candidatureAdapters.transformForAPI(
              {
                ...mecDetail.data,

                statut_a_modifier: isEditMode,
              },
              dynamicFields
            ),
          },
        };
        const formData: any = new FormData();
        tools.buildFormData(formData, mer, null);

        const newMER = await postMECRequest(formData);

        setMecDetail({
          data: null,
          comment: "",
        });
        setIsEditMode("");
        onAfterValidate();
        mecActions.onCloseConfirmDialog();
        toast.success("Mise en conformité enregistrée avec succès.");
      } catch (err: any) {
        console.log("MEC ERR", err);
        toast.error(
          "Erreur lors de l'enregistrement de la mise en conformité."
        );
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
      setIsMECSaving(false);
    },
    onUpdateMECDetail: (e: any) => {
      setMecDetail((d: any) => ({
        ...d,
        [e.target.name]: e.target.value,
      }));
    },
    onGetMECRecipients: () => {
      setMecRecipients((m) => []);
      setIsLoadingMECRecipients(true);
      actions
        .getMECRecipients({
          candidature_uuid: candidature.uuid,
          candidature_statut: statut_candidature,
        })
        .then((response: any) => {
          setMecRecipients((m) => response);
        })
        .finally(() => {
          setIsLoadingMECRecipients(false);
        });
    },
    onAskMEC: async (data: any, onAfterValidate = () => {}) => {
      setIsMecAsking(true);
      try {
        console.log("data", data);
        await postAskMECRequest({
          candidature_uuid: candidature.uuid,
          candidature_statut: statut_candidature,
          emails: data.selectedRecipients.map((r: any) => r.email),
          commentaire_demandeur: data.comment,
        });
        panelActions.closeModal();
        onAfterValidate();
        toast.success("Demande de mise en conformité enregistrée.");
      } catch (err: any) {
        console.log("MEC ERR", err);
        toast.error(
          "Erreur lors de l'enregistrement de la mise en conformité."
        );
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }

      setIsMecAsking(false);
    },
    onCancelAskMEC: async (uuid: any, onAfterValidate = () => {}) => {
      if (
        !window.confirm(
          "Êtes-vous sûr de vouloir annuler cette demande de mise en conformité ?"
        )
      )
        return;
      setIsMecAsking(true);
      try {
        // await postAskMECRequest({

        // });
        await cancelAskMECRequest(uuid);
        setDeletedAskedMEC((d: any) => [...d, uuid]);
        onAfterValidate();
        toast.success("Demande de mise en conformité annulée.");
      } catch (err: any) {
        console.log("MEC ERR", err);
        toast.error("Erreur lors de l'annulation de la mise en conformité.");
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }

      setIsMecAsking(false);
    },
  };

  const mecAsked =
    candidature?.mise_en_conformites?.filter(
      (m: any) => m.statut === "EN_ATTENTE_DE_MODIFICATION"
    ) || [];

  const activeMecsAsked =
    mecAsked.filter((f: any) => !deletedAskedMEC.includes(f.uuid)) || [];
  const activeMecs =
    candidature?.mise_en_conformites?.filter(
      (f: any) => !deletedAskedMEC.includes(f.uuid)
    ) || [];

  return {
    mecActions,
    isEditMode,
    isConfirmDialogOpen,
    mecDetail,
    isMECSaving,
    isMECAsking,
    mecRecipients,
    isLoadingMECRecipients,
    mecAsked,
    deletedAskedMEC,
    activeMecsAsked,
    activeMecs,
  };
};

export default useMiseEnConfirmite;
