import { atom, useAtom } from "jotai";
import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  loginRequest,
  logoutRequest,
  checkUserConnectedRequest,
  forgetPasswordRequest,
  resetPasswordRequest,
  checkTokenRequest,
} from "../services/authServices";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCustomModal from "../Common/Overlays/CustomModal/useCustomModal";
import ExpiredCookieConfirmDialog from "../Pages/Login/ExpiredCookieConfirmDialog/ExpiredCookieConfirmDialog";
import { userSelectedBranchAtom } from "../atoms/branchAtom";
import { selectedBranchIdAtom } from "./useBranchEntities";

export const ROLES = {
  ADMIN: "ADMIN",
  USER: "USER",
  CANDIDAT: "CANDIDAT",
};

export const authAtom = atom({
  isAuthenticated: false,
  isTokenResetAuthenticated: false,
  isReferent: false,
  user: {
    cgu_acceptees: [],
    profils_sur_branche: [],
    email: "",
    prenom: "",
    nom: "",
    uuid: "",
    telephone_portable: "",
    est_administrateur: false,
    mes_branches: [],
  },
});

export const disconnectRedirectUrlAtom = atom("/");

function useAuth() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location: any = useLocation();
  const { actions: modalActions } = useCustomModal();
  const [, setUserSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [, setSelectedBranchId] = useAtom(selectedBranchIdAtom);
  const [disconnectRedirectUrl, setDisconnectRedirectUrl] = useAtom(
    disconnectRedirectUrlAtom
  );
  const from = location.state?.from?.pathname || "/admin/accueil";

  const [auth, setAuth] = useAtom(authAtom);

  const getUser = useQuery("user", checkUserConnectedRequest, {
    enabled: false,
    retry: 0,
    onSuccess: (data) => {
      const datas = { ...auth, isAuthenticated: true, user: data };
      setAuth({ ...auth, isAuthenticated: true, user: data });
      //navigate(from, { replace: true });
    },
    onError: () => {
      setAuth({ ...auth, isAuthenticated: false });
    },
  });

  const mutations = {
    Login: useMutation(loginRequest, {
      onSuccess: () => {
        setAuth({
          isAuthenticated: false,
          isTokenResetAuthenticated: false,
          isReferent: false,
          user: {
            profils_sur_branche: [],
            cgu_acceptees: [],
            email: "",
            prenom: "",
            nom: "",
            uuid: "",
            telephone_portable: "",
            est_administrateur: false,
            mes_branches: [],
          },
        });
        getUserQuery();
        localStorage.setItem(
          "current_user",
          JSON.stringify({ isAuthenticated: "true" })
        );
        queryClient.invalidateQueries("user");
        //navigate(from, { replace: true });
      },
      onError: (error: any) => {
        if (!error.response.status || error.response.status >= 500) {
          navigate(`/erreur`);
        } else {
          console.log(error.message);
        }
      },
    }),
    Logout: useMutation(logoutRequest, {
      onSuccess: () => {
        setAuth({
          ...auth,
          isAuthenticated: false,
          isReferent: false,
        });
        localStorage.removeItem("current_user");
        setUserSelectedBranch(null);
        setSelectedBranchId(null);
        queryClient.invalidateQueries("user");
        navigate(disconnectRedirectUrl);
        setDisconnectRedirectUrl("/");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : console.log(error.message);
      },
    }),
    ForgotPassword: useMutation(forgetPasswordRequest, {
      onSuccess: () => {
        toast.success(
          "Un lien de réinitialisation du mot de passe a été envoyé sur votre adresse email"
        );
        queryClient.invalidateQueries("user");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'envoie du lien de réinitialisation : ${error?.message}`
            );
      },
    }),
    CheckToken: useMutation(checkTokenRequest, {
      onSuccess: () => {
        setAuth({ ...auth, isTokenResetAuthenticated: true });
      },
      onError: (error: any) => {
        setAuth({ ...auth, isTokenResetAuthenticated: false });
        toast.error(`Vous n'avez pas accès à cette page : ${error?.message}`);
        navigate(`/`);
      },
    }),
    ResetPassword: useMutation(resetPasswordRequest, {
      onSuccess: () => {
        toast.success(
          "Votre mot de passe a bien été réinitialisé, vous pouvez dès à présent vous connecter"
        );
        navigate(`/`);
        queryClient.invalidateQueries("user");
      },
      onError: (error: any) => {
        if (!error.response.status || error.response.status >= 500) {
          navigate(`/erreur`);
        }
      },
    }),
  };

  const actions = {
    login: (data: any) => {
      mutations.Login.mutate(data);
    },
    logout: () => {
      mutations.Logout.mutate();
    },
    forgetPassword: (data: any) => {
      mutations.ForgotPassword.mutate(data);
    },
    checkToken: (data: any) => {
      mutations.CheckToken.mutate(data);
    },
    resetPassword: (data: any) => {
      mutations.ResetPassword.mutate(data);
    },
    openCookieExpiredModal: () => {
      modalActions.openModal({
        header: "Votre cookie a expiré.",
        body: <ExpiredCookieConfirmDialog />,
        isDissmiss: false,
      });
    },
  };

  const {
    isLoading: isLoadingLogin,
    isError: isErrorLogin,
    error: errorLogin,
  } = mutations.Login;

  const { isLoading: isLoadingGetUser, refetch: getUserQuery } = getUser;

  const {
    isLoading: isLoadingForgetPassword,
    isSuccess: isSuccessForgetPassword,
    isError: isErrorForgetPassword,
    error: errorForgetPassword,
  } = mutations.ForgotPassword;

  const {
    isLoading: isLoadingResetPassword,
    isError: isErrorResetPassword,
    error: errorResetPassword,
  } = mutations.ResetPassword;

  return {
    auth,

    isAuth: auth.isAuthenticated,
    isToken: auth.isTokenResetAuthenticated,

    isLoadingLogin,
    isErrorLogin,
    errorLogin,

    isLoadingGetUser,

    isLoadingForgetPassword,
    isSuccessForgetPassword,
    isErrorForgetPassword,
    errorForgetPassword,

    isLoadingResetPassword,
    isErrorResetPassword,
    errorResetPassword,

    modalActions,
    actions,
    getUserQuery,
  };
}

export default useAuth;
