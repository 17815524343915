const accordionStyles = {
  wrapper: {
    position: "relative",
    zIndex: "2",
    borderBottom: "1px solid #4a60f4",
  },
  container: {
    root: {
      width: "100%",
      marginBottom: 1,
      borderBottom: "1px solid #4a60f4",
    },
  },
  header: {
    root: {
      color: "#fff",

      padding: 5,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgb(52, 72, 200)",
      },
    },
  },
  content: {
    root: {
      color: "#fff",
      padding: 10,
      paddingLeft: 54,
    },
  },
  iconButton: {
    root: {
      color: "white",
      selectors: {
        "&:active": {
          color: "white",
        },
      },
    },
    rootHovered: {
      color: "white",
      backgroundColor: "rgb(16, 110, 190)",
    },
  },
  text: {
    color: "white",
  },
};
export const accordionMenuStyles = (primaryColor: any) => {
  return {
    wrapper: {},
    container: {
      root: {
        width: "100%",
        marginBottom: 1,
      },
    },
    header: {
      root: {
        cursor: "pointer",
        // borderBottom: "1px solid rgb(229,229,229)",
        "&:hover": {
          backgroundColor: "#f6f6f6",
        },
      },
    },
    content: {
      root: {},
    },
    iconButton: {
      root: {
        color: "#666666",
        selectors: {
          "&:active": {
            color: "black",
          },
        },
      },
    },
    text: {
      color: primaryColor,
      fontSize: 13,
      textTransform: "uppercase",
      fontWeight: "400",
    },
  };
};

export default accordionStyles;
