import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { branchAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import useOfflineCandidaturePile from "../../../hooks/offlineCandidature/useOfflineCandidaturePile";
import useAuth from "../../../hooks/useAuth";
import {
  deleteInvitationSecondEvaluateur,
  postInviteSecondEvaluateur,
} from "../../../services/candidatureService";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import AddSecondEvaluateurModal from "./AddSecondEvaluateurModal/AddSecondEvaluateurModal";

export const useEvaluation = () => {
  const [
    isSuccessToInviteSecondEvaluateur,
    setIsSuccessToInviteSecondEvaluateur,
  ] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingSecondeEvaluation, setIsSavingSecondeEvaluation] =
    useState(false);
  const [isSavingSecondEvaluateur, setIsSavingSecondEvaluateur] =
    useState(false);

  const [isRemovedSecondEvaluateur, setIsRemovedSecondEvaluateur] =
    useState(false);
  const [isLoadingRemoveSecondEvaluateur, setIsLoadingRemoveSecondEvaluateur] =
    useState(false);

  const { actions: accessActions } = useAccessSettings();
  const { candidature, actions: candidatureActions } = useCandidature();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const { auth }: any = useAuth();
  const { actions: modalActions } = useCustomModal();
  const { labels } = useLabelSettings();
  const { actions: actionsPile, isOffline } = useOfflineCandidaturePile();

  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const conditionnalDisplayVariable: any = {
    isEvaluateurThisCandidature: candidature.evaluateur?.uuid == auth.user.uuid,
    isSecondEvaluateurThisCandidature:
      candidature.second_evaluateur?.uuid == auth.user.uuid,
  };

  const actions = {
    onSaveEvaluation: async (data: any, isEnregistrerEvaluation: boolean) => {
      setIsSaving(true);
      //Si on modifie evaluationObject, il faut aussi le modifier dans useEvaluationObject.ts
      const evaluationObject = {
        ...data,
        isEnregistrerEvaluation,
        evaluation: data.blocs_competences_passes.map((b: any) => ({
          uuid: b.candidature_bloc_competence_uuid,
          decision: b.decision_evaluation,
          commentaire_evaluation: b.commentaire_evaluation,
          competences_associees: b.competences.map((c: any) => ({
            uuid: c.uuid,
            decision: c.decision_evaluation,
            criteres_evaluation: accessActions.canI({
              action: branchAccessEnum.EVALUATION_PAR_CRITERE,
            }).granted
              ? c.criteres
                ? c.criteres.map((l: any) => ({
                    libelle: l.libelle,
                    decision: l.decision_evaluation?.toString(),
                  }))
                : null //waiting api
              : null,
          })),
        })),
      };

      if (isOffline) {
        actionsPile.onSaveEvaluation(data);
        toast.info(
          "Vos informations on été temporairement sauvegardées et sont en attente de synchronisation"
        );
      } else {
        await candidatureActions.putCandidatureFormData(
          evaluationObject,
          STATUT_PAGE,
          reactHookProps
        );
        actionsPile.onRemoveCandidatureFromPile(data.uuid, false);
      }
      setIsSaving(false);
    },
    onSaveSecondEvaluation: async (data: any) => {
      setIsSavingSecondeEvaluation(true);
      const secondEvaluationObject = {
        ...data,
        statut_a_modifier: "avis_second_evaluateur",
      };
      if (isOffline) {
        actionsPile.onSaveEvaluation(secondEvaluationObject);
        toast.info(
          "Vos informations on été temporairement sauvegardées et sont en attente de synchronisation"
        );
      } else {
        await candidatureActions.putCandidatureFormData(
          secondEvaluationObject,
          STATUT_PAGE,
          reactHookProps
        );
      }
      setIsSavingSecondeEvaluation(false);
    },
    onValidate: () => {
      const validateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_evaluation: "VALIDE",
          competences: b.competences?.map((c: any) => ({
            ...c,
            decision_evaluation: "VALIDE",
            criteres:
              accessActions.canI({
                action: branchAccessEnum.EVALUATION_PAR_CRITERE,
              }).granted &&
              candidature.certification.modalites_evaluation.type == 1
                ? c.criteres
                  ? c.criteres.map((l: any) => ({
                      ...l,
                      decision_evaluation: "VALIDE",
                    }))
                  : null
                : c.criteres,
          })),
        }));
      reactHookProps.setValue("blocs_competences_passes", validateAll);
    },
    onInvalidate: () => {
      const invalidateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_evaluation: "NON_VALIDE",
          competences: b.competences?.map((c: any) => ({
            ...c,
            decision_evaluation: "NON_VALIDE",
            criteres:
              accessActions.canI({
                action: branchAccessEnum.EVALUATION_PAR_CRITERE,
              }).granted &&
              candidature.certification.modalites_evaluation.type == 1
                ? c.criteres
                  ? c.criteres.map((l: any) => ({
                      ...l,
                      decision_evaluation: "NON_VALIDE",
                    }))
                  : null
                : c.criteres,
          })),
        }));
      reactHookProps.setValue("blocs_competences_passes", invalidateAll);
    },
    openAddSecondEvaluateurModal: () => {
      modalActions.openModal({
        header: labels.INVITE_EVALUATEUR_TITRE,
        body: (
          <AddSecondEvaluateurModal
            onSave={actions.onInviteSecondEvaluateur}
            isSaving={isSavingSecondEvaluateur}
          />
        ),
      });
    },
    onInviteSecondEvaluateur: async (secondEvaluateur: any) => {
      setIsSavingSecondEvaluateur(true);
      const response = await postInviteSecondEvaluateur(
        candidature.uuid,
        secondEvaluateur
      );
      if (!response.error) {
        toast.success("Second évaluateur invité avec succès.");
        setIsSuccessToInviteSecondEvaluateur(true);
      } else {
        toast.error(response.error);
      }
      setIsSuccessToInviteSecondEvaluateur(false);
      setIsSavingSecondEvaluateur(false);
    },
    onCancelInvitationSecondEvaluateur: async () => {
      setIsLoadingRemoveSecondEvaluateur(true);
      setIsRemovedSecondEvaluateur(false);
      const response = await deleteInvitationSecondEvaluateur(candidature.uuid);
      if (!response.error) {
        toast.success("Second évaluateur supprimé avec succès.");
      } else {
        toast.error(response.error);
      }
      setIsRemovedSecondEvaluateur(true);
      setIsLoadingRemoveSecondEvaluateur(false);
    },
  };

  // useEffect(() => {
  //   reactHookProps.setValue(
  //     "blocs_competences_passes",
  //     candidature.certification.blocs_competences.filter(
  //       (bloc: any) =>
  //         bloc.option == (reactHookProps.watch("option_selectionnee")?.key || null) &&
  //         bloc.statut == 1
  //     )
  //   );
  // }, [reactHookProps.watch("option_selectionnee")]);

  return {
    candidature,
    candidatureActions,
    reactHookProps,
    actions,
    conditionnalDisplayVariable,
    isSaving,
    isSavingSecondeEvaluation,
    isRemovedSecondEvaluateur,
    isLoadingRemoveSecondEvaluateur,
    isSuccessToInviteSecondEvaluateur,
    auth,
  };
};
