import {
  DefaultButton,
  DefaultSpacing,
  DetailsList,
  DetailsListLayoutMode,
  IStackTokens,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ButtonNumberInfo from "../../Common/ButtonNumberInfo/ButtonNumberInfo";
import Can from "../../Common/Can/Can";
import CancelButton from "../../Common/CancelButton/CancelButton";
import FormSection from "../../Common/FormSection/FormSection";
import JuryStatusBadge from "../../Common/JuryStatusBadge/JuryStatusBadge";
import Loader from "../../Common/Loader/Loader";
import SliderCustom from "../../Common/SliderCustom/SliderCustom";
import useTheme from "../../Common/useTheme";
import {
  branchAccessEnum,
  permissionsEditCandidatureStatus,
  userAccessEnum,
} from "../../config/accessEnum";
import useCandidatureWorkflow from "../../hooks/candidature/useCandidatureWorkflow";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import { IStyles, getStyles } from "./index.styles";
import { useDashboard } from "./useDashboard";
import HabilitationOfDetailList from "../../Common/HabilitationOfDetailList/HabilitationOfDetailList";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useAuth from "../../hooks/useAuth";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useAtom } from "jotai";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";

const sectionStackTokens: IStackTokens = { childrenGap: 10 };

function Dashboard() {
  const { auth }: any = useAuth();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { labels } = useLabelSettings();
  const [styles, setStyles] = useState<IStyles | undefined>();
  const { primaryColor, dangerColor } = useTheme();
  const {
    STATUTS_CANDIDATURE,
    ETAPES,
    STATUT_PAGE,
    isFirstStatutBeforeOrEqualSecondStatus,
  } = useCandidatureWorkflow();
  const navigate = useNavigate();
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const { actions: accessActions } = useAccessSettings();
  const {
    habilitationsOF,
    habilitationsEvaluateur,
    habilitationsPositionneur,
    habilitationOptionsFilters,
    candidatureCreationItems,
    candidaturePositionnementItems,
    candidatureEvaluationItems,
    inscriptionsJuryItems,
    decisionJuryItems,
    branchInformations,
    isLoadingCandidatures,
    juryList,
    actions: dashBoardActions,
  } = useDashboard();

  useEffect(() => {
    setStyles(getStyles(primaryColor));
  }, []);

  const columns = [
    {
      key: "1",
      name: "Candidat",
      fieldName: "candidat",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => (
        <Text>
          {item.candidat.nom} {item.candidat.prenom}
        </Text>
      ),
    },
    {
      key: "2",
      name: "Certification",
      fieldName: "certification",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => (
        <Text>
          {item.certification.libelle} - {item.certification.type_libelle}
        </Text>
      ),
    },
    {
      key: "3",
      name: "Gérer",
      fieldName: "gerer",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => {
        const myActions = STATUTS_CANDIDATURE.map(
          (statut: any, index: number) => {
            if (
              index > 0 &&
              isFirstStatutBeforeOrEqualSecondStatus(statut.id, item.statut)
            ) {
              return (
                <Can I={permissionsEditCandidatureStatus[statut.id]}>
                  <Link
                    // a modifie -> changer la route
                    to={`/admin/candidature/${item.uuid}/${statut.id}`}
                    as={RouterLink}
                    styles={commonButtonStyles.buttonLink}
                  >
                    {statut.actionName}
                  </Link>
                </Can>
              );
            }

            return null;
          }
        ).filter((t: any) => t);
        return (
          <Stack>
            <Link
              to={`/admin/candidature/${item.uuid}/${item.statut}`}
              as={RouterLink}
              styles={commonButtonStyles.buttonLink}
            >
              {labels.CANDIDATURES_BTN_VISUALISER}
            </Link>
            {myActions.length > 0 && myActions[myActions.length - 1]}
            {item.statut == "inscription_jury" && item.jury && (
              <small
                style={{
                  color: dangerColor,
                }}
              >
                Inscription demandée en attente de validation
              </small>
            )}
          </Stack>
        );
      },
    },
  ];
  const prochainJuryColumns = [
    {
      key: "1",
      name: "Date",
      fieldName: "date",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{tools.formatDate(item.date_jury)}</Text>,
    },
    {
      key: "2",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <JuryStatusBadge statut={item.statut} />,
    },
    {
      key: "3",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.libelle}</Text>,
    },
    {
      key: "4",
      name: "Date limite d'inscription",
      fieldName: "date_limite_inscription",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => (
        <Text>{tools.formatDate(item.date_limite_inscription_candidat)}</Text>
      ),
    },
    {
      key: "5",
      name: "Places restantes",
      fieldName: "nombre_de_places_restantes",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.nombre_de_places_restantes}</Text>,
    },
  ];

  const isHabilitationPositionneurAndEvaluateur =
    accessActions.canI({
      action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
    }).granted &&
    userSelectedBranch.profils.includes("EVALUATEUR") &&
    accessActions.canI({
      action: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
    }).granted &&
    userSelectedBranch.profils.includes("POSITIONNEUR");

  return (
    <Stack style={{ background: "#fcfcfc" }}>
      <Stack
        styles={styles?.hero}
        style={
          branchInformations.headband
            ? {
                background: `url(${branchInformations.headband}) repeat-x`,
                backgroundSize: "auto 100%",
              }
            : { padding: "20px 0px" }
        }
        horizontalAlign="center"
        verticalAlign="center"
        horizontal
      >
        <DefaultButton
          onClick={() => navigate("/admin/demande_aide")}
          styles={commonButtonStyles.defaultButton}
          style={{
            color: primaryColor,
            borderColor: primaryColor,
            position: "absolute",
            right: "2%",
            top: "7%",
          }}
        >
          Besoin d'aide ?
        </DefaultButton>
        <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
          style={{
            backgroundImage:
              "linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 35%,rgba(255,255,255,0.8) 65% ,rgba(255,255,255,0) 100%)",
            backgroundColor: "transparent",
            width: "100%",
            padding: "10px 0px",
          }}
        >
          <Stack styles={{ root: { marginRight: DefaultSpacing.m } }}>
            {branchInformations.logo && (
              <img src={branchInformations.logo} width={60} />
            )}
          </Stack>
          <Text variant="xLargePlus" style={{ color: "black" }}>
            {branchInformations.nom}
          </Text>
        </Stack>
      </Stack>

      <Stack styles={styles?.container}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <div
            dangerouslySetInnerHTML={{ __html: branchInformations.description }}
            style={{ width: "100%" }}
          />
        </Stack>
        <Stack style={{ margin: "20px 0px" }} className="contentNotShowSm">
          <SliderCustom
            steps={ETAPES}
            subSteps={STATUTS_CANDIDATURE}
            currentSubStep={STATUT_PAGE}
            activeSubStep={"resultats_jury"}
            disabled
          />
        </Stack>
        {isLoadingCandidatures ? (
          <Loader />
        ) : (
          <>
            {!isAnyKindOfAdmin && (
              <Can
                I={[
                  branchAccessEnum.HABILITATION_SIMPLE_OF,
                  branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
                  branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
                ]}
              >
                <Stack wrap>
                  <FormSection sectionTitle="Mes habilitations">
                    {accessActions.canI({
                      action: branchAccessEnum.HABILITATION_SIMPLE_OF,
                    }).granted &&
                      userSelectedBranch.profils.includes("OF") && (
                        <>
                          <strong>OF</strong>
                          <Stack horizontal>
                            <CancelButton
                              text="Demander une habilitation"
                              onClick={() => {
                                dashBoardActions.onOpenDemandeHabilitationModal(
                                  "OF"
                                );
                              }}
                            />
                          </Stack>
                          {habilitationsOF?.data?.length > 0 && (
                            <HabilitationOfDetailList
                              habilitationsData={habilitationsOF ?? []}
                              optionsFilters={habilitationOptionsFilters}
                              isDashboard={true}
                              isPaginate={true}
                              actions={dashBoardActions}
                            />
                          )}
                        </>
                      )}
                    {accessActions.canI({
                      action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
                    }).granted &&
                      userSelectedBranch.profils.includes("EVALUATEUR") && (
                        <>
                          <strong>Évaluateur</strong>
                          <Stack horizontal>
                            <CancelButton
                              text={
                                isHabilitationPositionneurAndEvaluateur
                                  ? "Demander une habilitation en tant qu'évaluateur"
                                  : "Demander une habilitation"
                              }
                              onClick={() => {
                                dashBoardActions.onOpenDemandeHabilitationModal(
                                  "EVALUATEUR"
                                );
                              }}
                            />
                          </Stack>
                          {habilitationsEvaluateur?.data?.length > 0 && (
                            <HabilitationOfDetailList
                              habilitationsData={habilitationsEvaluateur ?? []}
                              optionsFilters={habilitationOptionsFilters}
                              isDashboard={true}
                              isPaginate={true}
                              actions={dashBoardActions}
                            />
                          )}
                        </>
                      )}
                    {accessActions.canI({
                      action:
                        branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
                    }).granted &&
                      userSelectedBranch.profils.includes("POSITIONNEUR") && (
                        <>
                          <strong>Positionneur</strong>
                          <Stack horizontal>
                            <CancelButton
                              text={
                                isHabilitationPositionneurAndEvaluateur
                                  ? "Demander une habilitation en tant que positionneur"
                                  : "Demander une habilitation"
                              }
                              onClick={() => {
                                dashBoardActions.onOpenDemandeHabilitationModal(
                                  "POSITIONNEUR"
                                );
                              }}
                            />
                          </Stack>
                          {habilitationsPositionneur?.length > 0 && (
                            <HabilitationOfDetailList
                              habilitationsData={
                                habilitationsPositionneur ?? []
                              }
                              optionsFilters={habilitationOptionsFilters}
                              isDashboard={true}
                              isPaginate={true}
                              actions={dashBoardActions}
                            />
                          )}
                        </>
                      )}
                  </FormSection>
                </Stack>
              </Can>
            )}

            <Stack tokens={sectionStackTokens} horizontal wrap>
              <Stack.Item grow={3}>
                <FormSection sectionTitle="Candidatures en cours de création">
                  {candidatureCreationItems?.length > 0 && (
                    <Stack>
                      <ButtonNumberInfo
                        text="Gérer les candidatures en cours de création"
                        count={candidatureCreationItems.length}
                        onClick={() =>
                          navigate(
                            `/admin/candidature?statuts[]=creation_candidature_etape`
                          )
                        }
                      />
                    </Stack>
                  )}
                  <DetailsList
                    className="contentUseAllWidthMdSm"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    items={candidatureCreationItems || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                </FormSection>
              </Stack.Item>
              <Stack.Item grow={3}>
                <FormSection sectionTitle="Candidatures en attente de positionnement">
                  {candidaturePositionnementItems?.length > 0 && (
                    <Stack>
                      <ButtonNumberInfo
                        text="Gérer les candidatures à positionner"
                        count={candidaturePositionnementItems.length}
                        onClick={() =>
                          navigate(
                            `/admin/candidature?statuts[]=positionnement_etape`
                          )
                        }
                      />
                    </Stack>
                  )}
                  <DetailsList
                    className="contentUseAllWidthMdSm"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    items={candidaturePositionnementItems || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                </FormSection>
              </Stack.Item>
              <Stack.Item grow={3}>
                <FormSection sectionTitle="Candidatures en attente d'évaluation">
                  {candidatureEvaluationItems?.length > 0 && (
                    <Stack>
                      <ButtonNumberInfo
                        text="Gérer les candidatures à évaluer"
                        count={candidatureEvaluationItems.length}
                        onClick={() =>
                          navigate(
                            `/admin/candidature?statuts[]=evaluation_etape`
                          )
                        }
                      />
                    </Stack>
                  )}
                  <DetailsList
                    className="contentUseAllWidthMdSm"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    items={candidatureEvaluationItems || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                </FormSection>
              </Stack.Item>

              <Stack.Item grow={3}>
                <FormSection sectionTitle="Candidatures en attente d'inscription au jury">
                  {inscriptionsJuryItems?.length > 0 && (
                    <Stack>
                      <ButtonNumberInfo
                        text="Gérer les candidatures à inscrire au jury"
                        count={inscriptionsJuryItems.length}
                        onClick={() =>
                          navigate(
                            `/admin/candidature?statuts[]=inscription_jury_etape`
                          )
                        }
                      />
                    </Stack>
                  )}
                  <DetailsList
                    className="contentUseAllWidthMdSm"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    items={inscriptionsJuryItems || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                </FormSection>
              </Stack.Item>
              <Stack.Item grow={3}>
                <FormSection
                  sectionTitle="Candidatures en attente de décision du jury"
                  noBorder
                >
                  {decisionJuryItems?.length > 0 && (
                    <Stack>
                      <ButtonNumberInfo
                        text="Gérer les candidatures en attente de décision du jury"
                        count={decisionJuryItems.length}
                        onClick={() =>
                          navigate(
                            `/admin/candidature?statuts[]=decision_jury_etape`
                          )
                        }
                      />
                    </Stack>
                  )}
                  <DetailsList
                    className="contentUseAllWidthMdSm"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    items={decisionJuryItems || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                </FormSection>
              </Stack.Item>
            </Stack>
            <Stack>
              <FormSection sectionTitle="Prochains jurys">
                <Can I={userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION}>
                  <Stack horizontal>
                    <CancelButton
                      text="Inscrire mes candidatures aux jurys"
                      onClick={dashBoardActions.inscriptionCandidaturesJury}
                    />
                  </Stack>
                </Can>
                <DetailsList
                  className="contentUseAllWidthMdSm"
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  items={juryList || []}
                  columns={prochainJuryColumns}
                  selectionMode={SelectionMode.none}
                />
              </FormSection>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default Dashboard;
