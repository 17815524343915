import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { useAtom } from "jotai";
import tools from "../../utils/tools";
import { getJurysRequest } from "../../services/juryServices";
import useJury from "../../hooks/jury/useJury";
import { useQuery } from "react-query";
import InscriptionCandidaturesJuryModal from "../../Common/InscriptionJuryModal/InscriptionCandidaturesJuryModal";
import { useForm } from "react-hook-form";
import { IJuryFilters } from "../../interfaces/juryInterface";
import { atomWithReset } from "jotai/utils";

export const initialFilters = {
  libelle: "",
  statut: { id: undefined, text: "Tous", key: "all" },
  date_debut: null,
  date_fin: null,
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useJuryListPage() {
  const juryProps = useJury();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);

  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm<IJuryFilters>({
    mode: "onBlur",
    defaultValues:filters,
  });

  const {
    data: jurys,
    isLoading: isLoadingJurys,
    refetch,
  } = useQuery(
    ["jurys", optionsFilters, juryProps.userSelectedBranch],
    () => getJurysRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      }); 
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    openInscrireCandidaturesModal: (jury: any) => {
      modalActions.openModal({
        header: "Inscription de candidatures au jury de certification",
        body: (
          <InscriptionCandidaturesJuryModal
            jury={jury}
            onSaved={(jur: any) => {
              refetch();
            }}
          />
        ),
        fullWidth: true,
      });
    },
  };

  return {
    jurys,
    optionsFilters,
    isLoadingJurys,
    juryProps,
    modalActions,
    actions,
    reactHookProps
  };
}

export default useJuryListPage;
