import { Stack } from "@fluentui/react";
import { RiAddFill } from "react-icons/ri";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import SectionTitle from "../../../Common/SectionTitle/SectionTitle";
import { userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import BlocSelectionRecap from "../ChoixBlocs/BlocSelectionRecap/BlocSelectionRecap";
import CandidatSection from "../Common/CandidatSection";
import CertificationSection from "../Common/CertificationSection";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import useFetchCandidature from "../useFetchCandidature";
import DocumentsSection from "./Sections/DocumentsSection";
import PartiesPrenantesSection from "./Sections/PartiesPrenantesSection";
import useTransmissionDossier, {
  TransmissionDossierContext,
} from "./useTransmissionDossier";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import InfoMessage from "../../../Common/SelectField/common/InfoMessage/InfoMessage";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import { useAtom } from "jotai";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";

function TransmissionDossier() {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);

  const {
    isReferentielStillEditable,
    isThisPageStatusBeforeCandidatureStatus,
  } = useCandidatureWorkflow();
  const { actions: accessActions } = useAccessSettings();
  const {
    candidature,
    candidatureSelectableBlocs,
    reactHookProps,
    actions,
    isSaving,
  } = useTransmissionDossier();

  const fetchCandidature = useFetchCandidature(reactHookProps, (cand: any) => {
    //TODO : modifier pour rattacher les documents uploadés
    reactHookProps.setValue(
      "documents_certification",
      cand.certification?.documents?.filter(
        (doc: any) => doc.option_uuid === cand.option_selectionnee
      ) || []
    );
  });
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  const onSubmit = (e: any) => {
    reactHookProps.clearErrors();
    if (isEditMode) {
      reactHookProps.handleSubmit(mecActions.onSaveMEC)(e);
    } else {
      reactHookProps.handleSubmit(actions.onSave)(e);
    }
  };

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? userAccessEnum.NO_ACCESS_EVEN_ADMIN //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE;

  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  const documents = reactHookProps
    .watch("documents_certification")
    .filter(
      (doc: any) =>
        doc.option_uuid === candidature.option_selectionnee &&
        doc.types_candidature_autorises_en_ajout.find(
          (type: any) =>
            type.value && type.key == candidature.modalite_certification
        )
    )
    .map((doc: any) => {
      return { ...doc, collection: doc.uuid };
    });

  return !candidature?.certification ? null : (
    <TransmissionDossierContext.Provider
      value={{ candidature, reactHookProps, actions }}
    >
      <Stack style={{ marginTop: 0 }}>
        <CandidatureInfoHeaderWrapper>
          <CandidatSection candidat={candidature.candidat} />
          <CertificationSection certification={candidature.certification} />
        </CandidatureInfoHeaderWrapper>
        {inputsDisabled && (
          <Can I={userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE}>
            {documents.length > 0 && (
              <BoutonDemanderMEC
                statut="transmission_dossier"
                statut_candidature={candidature.statut}
                onAfterValidate={fetchCandidature.refrechCandidature}
                infoMessage={`Seuls les documents peuvent être corrigés à cette étape. ${
                  documents.length == 0
                    ? "Aucun document n'est à envoyer pour cette certification."
                    : ""
                }`}
              />
            )}
          </Can>
        )}
        <OptionsBlocsSection
          candidature={candidature}
          statutCandidature={StatutCandidature.CREATION_CANDIDATURE}
          showDecisions={false}
          reactHookProps={reactHookProps}
        />
        <SectionTitle title="Résumé de la sélection"></SectionTitle>
        <BlocSelectionRecap
          blocs_competences_passes={candidature.blocs_competences_passes}
          blocs_competences={candidatureSelectableBlocs}
          nb_blocs_au_choix_a_valider={
            candidature.certification.nb_blocs_au_choix_a_valider
          }
        />
        <Can
          I={
            userAccessEnum.VISUALISER_IDENTIFICATION_PARTIES_PRENANTES_PARCOURS
          }
        >
          <PartiesPrenantesSection />
        </Can>

        <DocumentsSection documents={documents} />

        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          <Stack.Item>
            {!isOpenedInModal && <CancelButton text={"Retour"} />}
          </Stack.Item>
          {!inputsDisabled && (
            <Can I={userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE}>
              <LoadButton
                text="Transmettre la candidature"
                isLoading={isSaving}
                icon={<RiAddFill size={38} />}
                style={{ fontWeight: "bold" }}
                onClick={onSubmit}
              />
            </Can>
          )}
          <BoutonEnregistrerMEC
            onClick={onSubmit}
            reactHookProps={reactHookProps}
            onAfterValidate={fetchCandidature.refrechCandidature}
          />
        </Stack>
      </Stack>
    </TransmissionDossierContext.Provider>
  );
}

export default TransmissionDossier;
