import { useForm } from "react-hook-form";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";

function useValidationEvaluation() {
  const { candidature, actions: candidatureActions } = useCandidature();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const [isSaving, setIsSaving] = useState(false);

  const { auth } = useAuth();
  const reactHookProps = useForm({
    mode: "onBlur",
  });

  const isCandidat = candidature.candidat.utilisateur_uuid === auth.user.uuid;

  const actions = {
    onSubmit: async (data: any) => {
      setIsSaving(true);
      await candidatureActions.putCandidature(
        data,
        STATUT_PAGE,
        reactHookProps
      );
      setIsSaving(false);
    },
  };
  return { isCandidat, isSaving, candidature, reactHookProps, actions };
}

export default useValidationEvaluation;
