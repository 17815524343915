import {
  Stack,
  IContextualMenuProps,
  IconButton,
  DefaultButton,
} from "@fluentui/react";
import useAuth from "../../hooks/useAuth";
import headerStyles from "./Header.styles";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useNav from "../../hooks/useNav";
import { FaUser } from "react-icons/fa";
import useTheme from "../useTheme";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BsChevronDown } from "react-icons/bs";

function HeaderPrivate() {
  const { actions, auth } = useAuth();
  const {
    userSelectedBranch,
    isNavOpen,
    actions: actionsNav,
    isOffline,
  } = useNav();
  const { primaryColor } = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    //navigate("")

    document
      .querySelector(".contentContainer")
      ?.classList.add("contentContainerConnected");

    return () => {
      document
        .querySelector(".contentContainer")
        ?.classList.remove("contentContainerConnected");
    };
  }, []);

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "mon_compte",
        text: "Mon compte",
        iconProps: { iconName: "AccountBrowser" },
        onClick: () => navigate("/admin/mon_profile"),
      },
      // {
      //   key: "ma_structure",
      //   text: "Ma structure",
      //   iconProps: { iconName: "AccountBrowser" },
      //   onClick: () => navigate("/admin/ma_structure"),
      // },
      // {
      //   key: "parameter",
      //   text: "Paramètres",
      //   iconProps: { iconName: "Settings" },
      // },
      {
        key: "logout",
        text: "Déconnexion",
        iconProps: { iconName: "Cancel" },
        onClick: () => {
          actions.logout();
        },
      },
    ],
    directionalHintFixed: true,
  };

  return (
    <Stack
      styles={headerStyles.outerStyle}
      style={{
        background: `rgb(248 248 248 / 51%)`,
      }}
      horizontal
      className="headerHeight"
      verticalAlign="center"
      horizontalAlign="space-between"
      wrap
    >
      <Stack.Item align="center" grow>
        <Stack horizontal grow>
          <Stack.Item>
            <IconButton
              className="ms-hiddenXlUp"
              style={{ padding: "0.5rem 1rem" }}
              iconProps={{ iconName: "GlobalNavButton" }}
              title="nav"
              ariaLabel="nav"
              onClick={actionsNav.toggleNav}
            />
          </Stack.Item>
          <Stack.Item grow>
            <Breadcrumb />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal wrap verticalAlign="center" horizontalAlign="center">
          {/* <Label
            className="text-info contentNotShowSm"
            style={{
              marginRight: 10,
              fontWeight: "400",
              lineHeight: "25px",
            }}
          >
            Branche :
          </Label> */}
          <DefaultButton
            style={{
              color: primaryColor,
              border: 0,
              background: "rgb(248 248 248 / 51%)",
            }}
            onClick={isOffline ? undefined : actionsNav.emptySelectedBranch}
          >
            {userSelectedBranch.nom}
            {isOffline ? null : (
              <BsChevronDown size={15} style={{ marginLeft: 5 }} />
            )}
          </DefaultButton>
          <DefaultButton
            className="UserButton"
            menuProps={isOffline ? undefined : menuProps}
            style={{ color: primaryColor, border: 0, marginLeft: 0 }}
            styles={headerStyles.iconButtonStyles}
          >
            <FaUser size={12} style={{ marginRight: 10 }} />
            {auth.user.prenom} {auth.user.nom}
          </DefaultButton>
          {/* <Dropdown
            selectedKey={userSelectedBranch ? userSelectedBranch.id : undefined}
            placeholder="Sélectionnez une branche"
            options={branches}
            calloutProps={{ doNotLayer: true }}
            onChange={(e: any, item: any) => actionsNav.changeBranch(item)}
            onRenderOption={(option: any) => option.nom}
            onRenderTitle={(option: any) => option[0].nom}
            styles={headerStyles.dropdownNavHeader}
            style={{ color: primaryColor }}
            className="brancheDropdown"
          /> */}

          {/* <IconButton
            className="UserButton"
            menuProps={menuProps}
            style={{ color: primaryColor }}
            onRenderIcon={() => <FaUser size={20} />}
            ariaLabel="Profile"
            styles={headerStyles.iconButtonStyles}
          /> */}
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export default HeaderPrivate;
