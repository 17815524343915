import { atom, useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import useSuiviCohortes from "../../hooks/suiviCohortes/useSuiviCohortes";
import { useQuery } from "react-query";
import { getSuiviCohortesRequest } from "../../services/suiviCohortesServices";
import { useForm } from "react-hook-form";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import ExportSuiviCohortes from "./ExportSuiviCohortes/ExportSuiviCohortes";
import FormulaireSuiviCohortes from "../SuiviCohortes/FormulaireSuiviCohortes/FormulaireSuiviCohortes";
import suiviCohortesAdapters from "../../adapters/suiviCohortesAdapters";
import useConstants from "../../hooks/useConstants";
import tools from "../../utils/tools";

export const initialFilters = {
  nom_candidat: "",
  prenom_candidat: "",
  email_candidat: "",
  certification: { id: undefined, text: "Toutes", key: "all" },
  jury: "",
  annee_jury: "",
  statut_6_mois: {
    key: "all",
    text: "Tous",
    id: undefined,
  },
  statut_24_mois: {
    key: "all",
    text: "Tous",
    id: undefined,
  },
};

export const filtersAtom = atomWithReset<any>(initialFilters);

const optionsFiltersAtom = atom({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useSuiviCohortesList() {
  const suiviCohortesProps = useSuiviCohortes();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();
  const { constants } = useConstants();

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: suiviCohortes, isLoading: isLoadingSuiviCohortes } = useQuery(
    ["suiviCohortes", optionsFilters, suiviCohortesProps.userSelectedBranch],
    () => {
      return getSuiviCohortesRequest(filters, optionsFilters);
    },
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    openSuiviCohorteModal: (suivi: any) => {
      modalActions.openModal({
        header: "Formulaire du candidat",
        body: (
          <FormulaireSuiviCohortes
            initialFormState={suiviCohortesAdapters.transformForBO(
              suivi,
              constants
            )}
            onSave={(data: any) => {
              suiviCohortesProps.actions.onUpdateSuiviCohorte(data);
            }}
            style={{ minWidth: 400 }}
            constants={constants}
          />
        ),
      });
    },
    openExportCohorteModal: (candidat: any) => {
      modalActions.openModal({
        header: "Export du suivi des cohortes",
        body: <ExportSuiviCohortes />,
      });
    },
  };

  return {
    suiviCohortes,
    suiviCohortesProps,
    isLoadingSuiviCohortes,
    optionsFilters,
    actions,
    reactHookProps,
  };
}

export default useSuiviCohortesList;
