import { atom, useAtom } from "jotai";
import { branchesAtom, constantsAtom } from "../atoms/constantsAtom";
import { authAtom } from "./useAuth";
import {
  getBranchesRequest,
  getConstantRequest,
} from "../services/publicServices";
import tools from "../utils/tools";
import { isOfflineAtom } from "./useNav";

const isInitializeAtom = atom(false);

function useInitialize() {
  const [auth] = useAtom(authAtom);
  const [, setBranches] = useAtom(branchesAtom);
  const [, setConstants] = useAtom(constantsAtom);
  const [isOffline] = useAtom(isOfflineAtom);

  const isLetter = [2, 3, 4];
  const isScore = [5];

  const [isInitialize, setIsInitialize] = useAtom(isInitializeAtom);

  const initializeBranches = async () => {
    if (isOffline) return;
    const response = await getBranchesRequest();
    var branches = response.data.sort((a: any, b: any) =>
      a.nom.localeCompare(b.nom)
    );
    branches = tools.addKeyToObjectInArray(branches, "id");
    setBranches(branches);
  };

  const initializeConstant = async () => {
    let data = null;

    const response = await getConstantRequest();

    const colors = tools.createColorPickerTable(20, 92, 20);
    //const colors = tools.createColorPickerTable(20, 72, 55);

    data = {
      ...response,
      CERTIFICATIONS: {
        TYPES: tools.addKeyToObjectInArray(
          response["CERTIFICATIONS"]["TYPES"],
          "id"
        ),
        BLOC_COMPETENCE_OPTION_STATUTS: tools.addKeyToObjectInArray(
          response["CERTIFICATIONS"]["BLOC_COMPETENCE_OPTION_STATUTS"],
          "id"
        ),
        ENREGISTREMENT: tools.addKeyToObjectInArray(
          response["CERTIFICATIONS"]["ENREGISTREMENT"],
          "id"
        ),
        MODALITE_EVALUATION_TYPES: tools.addKeyToObjectInArray(
          response["CERTIFICATIONS"]["MODALITE_EVALUATION_TYPES"].map(
            (item: any) => {
              if (isLetter.includes(item.id) && !isScore.includes(item.id)) {
                item.isLetter = true;
                item.isScore = false;
                item.nbLetters = item.id + 1; //to update
              } else if (
                !isLetter.includes(item.id) &&
                isScore.includes(item.id)
              ) {
                item.isLetter = false;
                item.isScore = true;
              } else {
                item.isLetter = false;
                item.isScore = false;
              }
              return item;
            }
          ),
          "id"
        ),
        CANDIDATURE_TYPES: tools.addKeyToObjectInArray(
          response["CERTIFICATIONS"]["CANDIDATURE_TYPES"],
          "id"
        ),
        PROFIL_TYPES: tools.addKeyToObjectInArray(
          response["CERTIFICATIONS"]["PROFIL_TYPES"],
          "id"
        ),
      },
      CANDIDATURES: {
        GROUPAGE_TELECHARGEMENT_DOCUMENTS: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["GROUPAGE_TELECHARGEMENT_DOCUMENTS"],
          "id"
        ),
        STATUTS: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["STATUTS"],
          "id"
        ),
        RESULTATS_STATUTS: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["RESULTATS_STATUTS"],
          "id"
        ),
        MODALITES_ACCES_CERTIFICATION: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["MODALITES_ACCES_CERTIFICATION"],
          "id"
        ),
        SITUATIONS_AVANT_CURSUS: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["SITUATIONS_AVANT_CURSUS"],
          "id"
        ),
        NIVEAUX_DIPLOME: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["NIVEAUX_DIPLOME"],
          "id"
        ),

        TYPES_CONTRAT: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["TYPES_CONTRAT"],
          "id"
        ),
        DISPOSITIFS_FINANCEMENT: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["DISPOSITIFS_FINANCEMENT"],
          "id"
        ),
        DECISIONS_POSITIONNEMENT: tools
          .addKeyToObjectInArray(
            response["CANDIDATURES"]["DECISIONS_POSITIONNEMENT"],
            "id"
          )
          .map((d: any) => {
            return {
              ...d,
              styles: { root: { marginRight: 10, fontWeight: "bold" } },
            };
          }),
        DECISIONS_EVALUATION: tools
          .addKeyToObjectInArray(
            response["CANDIDATURES"]["DECISIONS_EVALUATION"],
            "id"
          )
          .map((d: any) => {
            return {
              ...d,
              styles: { root: { marginRight: 10, fontWeight: "bold" } },
            };
          }),
        AVIS_JURY_TYPES: tools
          .addKeyToObjectInArray(
            response["CANDIDATURES"]["AVIS_JURY_TYPES"],
            "id"
          )
          .map((d: any) => {
            return {
              ...d,
              styles: { root: { marginRight: 10, fontWeight: "bold" } },
            };
          }),
        DECISIONS_JURY: tools
          .addKeyToObjectInArray(
            response["CANDIDATURES"]["DECISIONS_JURY"],
            "id"
          )
          .map((d: any) => {
            return {
              ...d,
              styles: { root: { marginRight: 10, fontWeight: "bold" } },
            };
          }),
        DOCUMENTS_TYPES: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["DOCUMENTS_TYPES"],
          "id"
        ),
        SITUATION_APRES_CERTIFICATION: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["SITUATION_APRES_CERTIFICATION"],
          "id"
        ),
        STATUT_SUIVI_COHORTE: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["STATUT_SUIVI_COHORTE"],
          "id"
        ),
        VOIE_ACCES_VAE: tools.addKeyToObjectInArray(
          response["CANDIDATURES"]["VOIE_ACCES_VAE"],
          "id"
        ),
      },
      JURY: {
        DOCUMENTS_APRES_CLOTURE: tools.addKeyToObjectInArray(
          response["JURY"]["DOCUMENTS_APRES_CLOTURE"],
          "id"
        ),
        STATUTS: tools.addKeyToObjectInArray(response["JURY"]["STATUTS"], "id"),
      },
      CIVILITE: tools.addKeyToObjectInArray(response["CIVILITE"], "id"),
      DEMANDE_INSCRIPTION: {
        PROFILS_AUTORISES: tools.addKeyToObjectInArray(
          response["DEMANDE_INSCRIPTION"]["PROFILS_AUTORISES"],
          "id"
        ),
        STATUTS: tools.addKeyToObjectInArray(
          response["DEMANDE_INSCRIPTION"]["STATUTS"],
          "id"
        ),
      },
      DEMANDE_HABILITATION: {
        STATUTS: tools.addKeyToObjectInArray(
          response["DEMANDE_HABILITATION"]["STATUTS"],
          "id"
        ),
      },
      COLORS: colors,
      MATRICES: {
        PROFILS_UTILISATEURS: tools.addKeyToObjectInArray(
          response["MATRICES"]["PROFILS_UTILISATEURS"],
          "id"
        ),
      },
    };

    setConstants(data);
  };

  const initializeApp = async () => {
    await initializeBranches();
    await initializeConstant();
    setIsInitialize(true);
  };

  return {
    auth,
    initializeApp,
    initializeBranches,
    isInitialize,
  };
}

export default useInitialize;
