import tools from "../utils/tools";
import { v4 as uuidv4 } from "uuid";
import { IS_OFFLINE } from "../config/offlineConfig";

const candidatureAdapters = {
  transformForAPI: (
    data: any,
    dynamicFields?: any,
    isCandidatPartiePrenante?: boolean,
    isEnregistrer: boolean = false
  ) => {
    //todo: check if profil candidat (valider_avis_formateur) need to skip all
    let transformed: any = {};
    const allData = {
      ...data,
      candidat: {
        ...data.candidat,
        civilite: data.candidat.civilite?.id || 3,
        date_de_naissance: tools.formatDate(data.candidat.date_de_naissance),
        telephone_portable: data.candidat.telephone_portable?.replaceAll(
          " ",
          ""
        ),
        telephone_professionnel:
          data.candidat.telephone_professionnel?.replaceAll(" ", ""),
      },
      certification: data.certification?.uuid,
      modalite_certification: data.modalite_certification?.id,
      voie_acces_vae: data.voie_acces_vae?.id,
      dispositif_financement: data.dispositif_financement?.id,
      type_contrat: data.type_contrat?.id,
      situation_avant_cursus: data.situation_avant_cursus?.id,
      niveau_dernier_diplome: data.niveau_dernier_diplome?.id,
      // entreprise_liee: data.entreprise_liee?.uuid,
      //of_lie: data.of_lie?.uuid,
      positionnement_par_candidat: data.positionnement_par_candidat
        ? true
        : false,
      blocs_competences_passes: data.blocs_competences_passes?.map(
        (b: any) => b.id
      ),
      formations_initiale: data.formations_initiale?.map((item: any) => ({
        ...item,
        niveau_formation: item.niveau_formation.id,
      })),
      formations_continue: data.formations_continue?.map((item: any) => ({
        ...item,
        niveau_formation: item.niveau_formation.id,
      })),
      evaluateur: {
        ...data?.evaluateur,
        profils: [],
      },
    };

    if (data.domaine) {
      allData.domaine = data.domaine.id;
    }

    if (data.champs_complementaires) {
      allData.champs_complementaires = data.champs_complementaires.map(
        (c: any) => {
          const formateDynamicField: any = {
            champ_complementaire_uuid: c.uuid,
          };
          const dynamicField = dynamicFields.find((d: any) => d.uuid == c.uuid);
          if (
            dynamicField.type == "TEXT" ||
            (dynamicField.type == "SELECT" && !dynamicField.multiple)
          ) {
            formateDynamicField.value = [c.value];
          } else if (dynamicField.type == "SELECT" && dynamicField.multiple) {
            formateDynamicField.value = [...c.value];
          } else if (dynamicField.type == "FILE") {
            formateDynamicField.file_deleted = true;
            if (c.value) {
              formateDynamicField.value = null;
              formateDynamicField.file = c.value;
            }
            if (c.file_url) {
              formateDynamicField.file_url = c.file_url;
              formateDynamicField.file_deleted = false;
            }
          }
          return formateDynamicField;
        }
      );
    }

    if (data.positionneur) {
      allData.positionneur = {
        ...data.positionneur,
        profils: [],
      };
    }

    if (data.adresse) {
      allData.candidat.adresse = {
        adresse: data.adresse,
        complement_adresse: data.complement_adresse,
        code_postal: data.code_postal,
        region: data.region,
        ville: data.ville,
      };
    }

    if (data.entreprise_liee && !data.entreprise_liee?.uuid) {
      allData.entreprise_liee = {
        nom: data.entreprise_liee.nom,
        siret: data.entreprise_liee.siret,
        ref_idcc: data.entreprise_liee.ref_idcc.id,
        adresse: {
          adresse: data.entreprise_liee.adresse,
          complement_adresse: data.entreprise_liee.complement_adresse,
          code_postal: data.entreprise_liee.code_postal,
          ville: data.entreprise_liee.ville,
          region: data.entreprise_liee.region,
        },
      };
    } else if (data.entreprise_liee?.uuid) {
      allData.entreprise_liee = { uuid: data.entreprise_liee.uuid };
    }

    if (data.of_lie && !data.of_lie?.uuid) {
      allData.of_lie = {
        raison_sociale: data.of_lie.raison_sociale,
        siret: data.of_lie.siret,
        adresse: {
          adresse: data.of_lie.adresse,
          complement_adresse: data.of_lie.complement_adresse,
          code_postal: data.of_lie.code_postal,
          ville: data.of_lie.ville,
          region: data.of_lie.region,
        },
      };
    } else if (data.of_lie?.uuid) {
      allData.of_lie = { uuid: data.of_lie.uuid };
    }

    //optimiser payload en retirant le maximum de données non exploitées
    const dataNeeded: any = {};
    const keysNeeded = getPayloadKeysToStatut(data.statut_a_modifier);
    keysNeeded.forEach((key: any) => {
      if (allData.hasOwnProperty(key)) {
        dataNeeded[key] = allData[key];
      }
    });

    if (isEnregistrer) {
      transformed = {
        _method: data._method,
        ...dataNeeded,
      };
    } else if (
      data.statut_a_modifier &&
      data.statut_a_modifier != "transmission_dossier"
    ) {
      transformed = {
        statut_a_modifier: data.statut_a_modifier,
        _method: data._method,
        data: { ...dataNeeded },
      };
    } else if (data.statut_a_modifier == "transmission_dossier") {
      transformed = {
        statut_a_modifier: data.statut_a_modifier,
        _method: data._method,
        data: {
          documents: data.certification_documents.map((d: any) => ({
            ...d,
            certification_document_uuid: d.collection,
          })),
        },
      };
    } else {
      //INFO: candidature statut -> creation
      transformed = { ...dataNeeded };
      if (isCandidatPartiePrenante) {
        transformed.candidat = {
          nom: dataNeeded.candidat.nom,
          prenom: dataNeeded.candidat.prenom,
          email: dataNeeded.candidat.email,
          uuid: dataNeeded.candidat.uuid,
          utilisateur_uuid: dataNeeded.candidat.utilisateur_uuid,
          civilite: dataNeeded.candidat.civilite,
          date_de_naissance: dataNeeded.candidat.date_de_naissance,
        };
      }
    }

    return transformed;
  },
  transformForBO: (
    data: any,
    constants: any,
    isPreviousPartiePrenante: any,
    dynamicFields?: any
  ) => {
    if (IS_OFFLINE) return data;
    let transformed: any = {
      ...data,
      avis: "",
      certification: { ...data.certification, key: data.certification.uuid },
      candidat: {
        ...data.candidat,
        civilite: tools.findIn(
          constants["CIVILITE"],
          data.candidat.civilite,
          "id"
        ),
        telephone_professionnel: data.candidat.telephone_professionnel || "",
        telephone_portable: data.candidat.telephone_portable || "",
        date_de_naissance: data.candidat.date_de_naissance || "",
      },
      adresse: data.candidat.adresse?.adresse,
      complement_adresse: data.candidat.adresse?.complement_adresse,
      code_postal: data.candidat.adresse?.code_postal,
      region: data.candidat.adresse?.region,
      ville: data.candidat.adresse?.ville,
      modalite_certification: tools.findIn(
        constants["CANDIDATURES"]["MODALITES_ACCES_CERTIFICATION"],
        data.modalite_certification,
        "id"
      ),
      voie_acces_vae: tools.findIn(
        constants["CANDIDATURES"]["VOIE_ACCES_VAE"],
        data.voie_acces_vae,
        "id"
      ),
      dispositif_financement: tools.findIn(
        constants["CANDIDATURES"]["DISPOSITIFS_FINANCEMENT"],
        data.dispositif_financement,
        "id"
      ),
      type_contrat: tools.findIn(
        constants["CANDIDATURES"]["TYPES_CONTRAT"],
        data.type_contrat,
        "id"
      ),
      situation_avant_cursus: tools.findIn(
        constants["CANDIDATURES"]["SITUATIONS_AVANT_CURSUS"],
        data.situation_avant_cursus,
        "id"
      ),
      niveau_dernier_diplome: tools.findIn(
        constants["CANDIDATURES"]["NIVEAUX_DIPLOME"],
        data.niveau_dernier_diplome,
        "id"
      ),
      rattache_of: isPreviousPartiePrenante ? true : data.of_lie ? true : false,
      attestation_dernier_diplome_url:
        data.candidat_documents.length > 0
          ? data.candidat_documents.find(
              (doc: any) => doc.collection == "ATTESTATION_DERNIER_DIPLOME"
            )?.file_url
          : "",
    };

    if (data.formations_initiales) {
      transformed.formations_initiales = data.formations_initiales.map(
        (item: any) => ({
          ...item,
          niveau_formation: tools.findIn(
            constants["CANDIDATURES"]["NIVEAUX_DIPLOME"],
            item.niveau_formation,
            "id"
          ),
        })
      );
    }

    if (data.formations_continues) {
      transformed.formations_continues = data.formations_continues.map(
        (item: any) => ({
          ...item,
          niveau_formation: tools.findIn(
            constants["CANDIDATURES"]["NIVEAUX_DIPLOME"],
            item.niveau_formation,
            "id"
          ),
          boUuid: uuidv4(),
        })
      );
    }

    if (data.blocs_competences_passes) {
      transformed.blocs_competences_passes =
        data?.blocs_competences_passes?.map((bloc: any) => ({
          ...bloc,
          competences: bloc.competences?.map((comp: any, i: any) => {
            const competence_positionnement: any =
              bloc?.competences_associees_positionnement?.find(
                (c: any) => comp.uuid == c.uuid
              );
            const competence_evaluation: any =
              bloc?.competences_associees_evaluation?.find(
                (c: any) => comp.uuid == c.uuid
              );
            const competence_decision_jury: any =
              bloc?.competences_associees_decision_jury?.find(
                (c: any) => comp.uuid == c.uuid
              );
            return {
              ...comp,
              decision_positionnement: competence_positionnement?.decision,
              decision_evaluation: competence_evaluation?.decision,
              decision_president: competence_decision_jury?.decision,
              criteres: comp.criteres?.map((critere: any) => {
                const critereEval =
                  competence_evaluation?.criteres_evaluation?.find(
                    (crit: any) => crit.libelle.trim() == critere.libelle.trim()
                  );
                return {
                  libelle: critere.libelle,
                  decision_evaluation: critereEval
                    ? critereEval.decision
                    : null,
                };
              }),
            };
          }),
        }));
    }

    if (data.champs_complementaires) {
      transformed.champs_complementaires = data.champs_complementaires.map(
        (f: any) => {
          let formateDynamicField: any = {
            uuid: f.champ_complementaire_uuid,
          };
          const dynamicField = dynamicFields.find(
            (d: any) => d.uuid == f.champ_complementaire_uuid
          );
          if (dynamicField) {
            if (
              dynamicField.type == "TEXT" ||
              (dynamicField.type == "SELECT" && !dynamicField.multiple)
            ) {
              formateDynamicField.value = f.value[0];
            } else if (dynamicField.type == "SELECT" && dynamicField.multiple) {
              formateDynamicField.value = f.value;
            } else {
              formateDynamicField.file_url = f.file;
            }
          }
          return formateDynamicField;
        }
      );
    }

    if (data.domaine) {
      transformed.domaine = {
        ...data.domaine,
        id: data.domaine.uuid,
        key: data.domaine.uuid,
      };
    }

    if (data.avis_second_evaluateur) {
      transformed.avis = data.avis_second_evaluateur.avis;
    }

    if (data.avis_formateur) {
      transformed.avis_formateur_id = data.avis_formateur.id;
      transformed.nom_formateur = data.avis_formateur.nom;
      transformed.prenom_formateur = data.avis_formateur.prenom;
      transformed.email_formateur = data.avis_formateur.email;
      transformed.avis_formateur = data.avis_formateur.avis;
      transformed.date_debut_formation =
        data.avis_formateur.date_debut_formation ?? null;
      transformed.date_fin_formation =
        data.avis_formateur.date_fin_formation ?? null;
    }
    return transformed;
  },
};

const getPayloadKeysToStatut = (statutAModifie: any) => {
  let payloadKeys: any = [];
  switch (statutAModifie) {
    case "saisie_informations":
      payloadKeys = [
        "certification",
        "modalite_certification",
        "dispositif_financement",
        "dispositif_financement_libelle",
        "type_contrat",
        "type_contrat_libelle",
        "situation_avant_cursus",
        "dernier_metier",
        "annees_experience",
        "niveau_dernier_diplome",
        "intitule_dernier_diplome",
        "attestation_dernier_diplome",
        "attestation_dernier_diplome_deleted",
        "positionnement_par_candidat",
        "champs_complementaires",
        "voie_acces_vae",
      ];
      break;
    case "choix_blocs":
      payloadKeys = ["option_selectionnee", "blocs_competences_passes"];
      break;
    case "identification_parties_prenantes":
      payloadKeys = [
        "evaluateur",
        "entreprise_liee",
        "rattache_of",
        "of_lie",
        "positionneur",
      ];
      break;
    case "transmission_dossier":
      payloadKeys = ["documents"];
      break;
    case "positionnement":
      payloadKeys = ["positionnement", "champs_complementaires"];
      break;
    case "avis_formateur":
      payloadKeys = ["avis_formateur", "champs_complementaires"];
      break;
    case "valider_avis_formateur":
      payloadKeys = [];
      break;
    case "evaluation":
      payloadKeys = ["evaluation", "champs_complementaires"];
      break;
    case "avis_second_evaluateur":
      payloadKeys = ["avis", "champs_complementaires"];
      break;
    case "decision_jury":
      payloadKeys = [
        "decision_jury",
        "champs_complementaires",
        "commentaire_jury",
      ];
      break;
    default:
      return (payloadKeys = [
        "candidat",
        "certification",
        "modalite_certification",
        "dispositif_financement",
        "dispositif_financement_libelle",
        "type_contrat",
        "type_contrat_libelle",
        "entreprise_liee",
        "of_lie",
        "voie_acces_vae",
        "option_selectionnee",
        "domaine",
      ]);
  }

  return [...payloadKeys, "uuid", "statut"];
};

export default candidatureAdapters;
