import FormSection from "../../Common/FormSection/FormSection";
import Page from "../../Common/Page/Page";
import { Controller, useForm } from "react-hook-form";
import { IStructure } from "../../interfaces/IStructure";
import { commonButtonStyles, commonInputStyles } from "../../styles";

import {
  DetailsList,
  IColumn,
  Stack,
  Text,
  TextField,
  Link,
  DetailsListLayoutMode,
  SelectionMode,
  DefaultButton,
} from "@fluentui/react";
import FormAdress from "../../Common/FormAdress/FormAdress";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import CancelButton from "../../Common/CancelButton/CancelButton";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import useStructureDetails from "./useStructureDetails";
import useTheme from "../../Common/useTheme";

function StructureDetails() {
  const { actions: modalActions } = useCustomModal();
  const { structure, actions } = useStructureDetails();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm<IStructure>({
    mode: "onBlur",
  });
  const { primaryColor } = useTheme();
  const columns: IColumn[] = [
    {
      key: "1",
      name: "Nom",
      fieldName: "last_name",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {},
      onRender: (item: any) => <Text>{item.last_name}</Text>,
      showSortIconWhenUnsorted: true,
    },
    {
      key: "2",
      name: "Prénom",
      fieldName: "first_name",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {},
      onRender: (item: any) => <Text>{item.first_name}</Text>,
      showSortIconWhenUnsorted: true,
    },
    {
      key: "3",
      name: "Email",
      fieldName: "email",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {},
      onRender: (item: any) => <Text>{item.email}</Text>,
      showSortIconWhenUnsorted: true,
    },
    {
      key: "4",
      name: "Référant",
      fieldName: "is_active",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <BooleanValue value={item["referant"]} />,
      onColumnClick: () => {},
      showSortIconWhenUnsorted: true,
    },
    {
      key: "5",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Link
              //to={`/admin/utilisateurs/${item.uuid}`}
              //as={RouterLink}
              onClick={() => setToReferant(item)}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Désigner comme compte référent
            </Link>
          </Stack>
        );
      },
    },
  ];

  const items: any = [
    {
      last_name: "Ouerfelli",
      first_name: "Raed",
      email: "ouerfellir5@gmail.com",
      referant: true,
    },
  ];

  const onSubmit = (data: any) => {
    modalActions.closeModal();
    modalActions.openModal({
      body: (
        <Stack horizontalAlign="center">
          <Text>
            Text from backend to verify SIRET ... Action taken by button "OUI"
            depends on the state of the form
          </Text>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <DefaultButton
              onClick={() => modalActions.closeModal()}
              styles={commonButtonStyles.defaultButton}
              style={{ color: primaryColor, borderColor: primaryColor }}
            >
              Non
            </DefaultButton>
            <LoadButton text={"Oui"} />
          </Stack>
        </Stack>
      ),
    });
  };

  const setToReferant = (item: any) => {
    modalActions.closeModal();
    modalActions.openModal({
      body: (
        <Stack horizontalAlign="center">
          <Text>
            Le statut de référant permet à l'utilisateur de valider ou invalider
            les demandes d'inscription sur votre Structure.
            <br />
            Etes-vous sur de vouloir désigner{" "}
            {`${item.last_name} ${item.first_name}`} comme référant au sein de
            votre structure ?
          </Text>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <DefaultButton
              onClick={() => modalActions.closeModal()}
              styles={commonButtonStyles.defaultButton}
              style={{ color: primaryColor, borderColor: primaryColor }}
            >
              Non
            </DefaultButton>
            <LoadButton
              text={"Oui"}
              onClick={(ev: any) => actions.setReferant(ev, item)}
            />
          </Stack>
        </Stack>
      ),
    });
  };

  return (
    <Page
      title="Informations de ma structure"
      explain={"Vous pouvez mettre à jour les informations de votre structure"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection sectionTitle="Informations générales">
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                required
                label="Nom:"
                type="text"
                {...field}
                placeholder="Ex: Branche 1"
                errorMessage={(errors.name as any)?.message}
              />
            )}
            control={control}
            name="name"
            defaultValue={structure.name}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <TextField
                styles={commonInputStyles.textField}
                required
                label="SIREN:"
                type="text"
                errorMessage={(errors.siren as any)?.message}
                disabled
                value={value}
                onChange={(e: any, newValue: any) => {
                  if (!newValue || newValue.length <= 9) {
                    onChange(e.target.value);
                  }
                }}
              />
            )}
            control={control}
            name="siren"
            defaultValue={structure.siren}
          />
          <Text variant="small">
            Afin de modifier votre numéro de SIREN, veuillez effectuer une{" "}
            <Link to={""}>demande d'aide</Link> à votre administrateur de
            branche
          </Text>

          <FormAdress
            control={control}
            errors={errors}
            varToWatch={watch("code_postal")}
            onPrefillCityAndRegion={(data: any) => {
              setValue("ville", data.nom_commune);
              setValue("region", data.nom_region);
            }}
          />
        </FormSection>
        <FormSection sectionTitle="Comptes associés à ma structure">
          <DetailsList
            layoutMode={DetailsListLayoutMode.justified}
            items={items}
            columns={columns}
            selectionMode={SelectionMode.none}
          />
        </FormSection>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          <CancelButton />

          <LoadButton text={"Valider les modifications"} isLoading={false} />
        </Stack>
      </form>
    </Page>
  );
}

export default StructureDetails;
