export const stateList: any = [
  {
    key: "all",
    text: "Tous",
    value: undefined,
  },
  {
    key: "yes",
    text: "Oui",
    value: true,
  },
  {
    key: "no",
    text: "Non",
    value: false,
  },
];
export const userStateList: any = [
  {
    key: "all",
    text: "Tous",
    value: undefined,
  },
  {
    key: "yes",
    text: "Oui",
    value: 1,
  },
  {
    key: "no",
    text: "Non",
    value: 0,
  },
];
export const juryStateList:any =[
  {
    key: "all",
    text: "Tous",
    value: undefined,
  },
  {
    key: "ouverts",
    text: "Ouverts",
    value: 1,
  },
  {
    key: "fermes",
    text: "Fermés",
    value: 0,
  },
]
