import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { toast } from "react-toastify";
import useLabelSettings from "../../Pages/LabelSettingsDetail/useLabelSettings";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import HabilitationStatutBadge from "../HabilitationStatutBadge/HabilitationStatutBadge";
import LoadLink from "../LoadLink/LoadLink";
import Pagination from "../Pagination/Pagination";

function HabilitationOfDetailList({
  habilitationsData = [],
  actions,
  optionsFilters,
  isPaginate = true,
  isOfDetail = false,
  isDashboard = false,
}: {
  habilitationsData: any;
  actions?: any;
  optionsFilters?: any;
  isOfDetail?: boolean;
  isDashboard?: boolean;
  isPaginate?: boolean;
}) {
  const { labels } = useLabelSettings();

  const columns = [
    {
      key: "1",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <HabilitationStatutBadge statut={item.statut} />,
    },
    {
      key: "2",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,

      onColumnClick: () => {
        actions.sortColumn("certification");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters?.sort, "certification"),
    },
    {
      key: "3",
      name: "OF",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.of?.raison_sociale}</Text>,
      onColumnClick: () => {
        if (!isDashboard) {
          actions.sortColumn("of");
        }
      },
      showSortIconWhenUnsorted: !isDashboard,
      ...tools.sorted(optionsFilters?.sort, "of"),
    },
    {
      key: "4",
      name: "Date de début",
      fieldName: "date_debut",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_debut)}</Stack>
      ),
      showSortIconWhenUnsorted: true,
      onColumnClick: () => {
        actions.sortColumn("date_debut");
      },
      ...tools.sorted(optionsFilters?.sort, "date_debut"),
    },
    {
      key: "5",
      name: "Date de fin",
      fieldName: "date_fin",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_fin)}</Stack>
      ),
      showSortIconWhenUnsorted: true,
      onColumnClick: () => {
        actions.sortColumn("date_fin");
      },
      ...tools.sorted(optionsFilters?.sort, "date_fin"),
    },
    {
      key: "6",
      name: "Commentaire",
      fieldName: "commentaire",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.commentaire}</Text>,
    },
    {
      key: "7",
      name: "Actions",
      fieldName: "actions",
      maxWidth: 100,
      minWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        const isHabilitationActived = item.statut == "ACTIVE";
        const isHabilitationHaveDocument = item.documents.length > 0;

        return (
          <Stack horizontal wrap>
            {isHabilitationActived && (
              <LoadLink
                styles={commonButtonStyles.disableButtonLink}
                onClick={() => actions.onRemoveHabilitation([item])}
                isLoading={false}
                text={"Retirer"}
              />
            )}
            {isHabilitationHaveDocument && (
              <LoadLink
                href={item.documents[0].file_url}
                styles={commonButtonStyles.buttonLink}
                text={"Voir document"}
              />
            )}
          </Stack>
        );
      },
    },
  ]
    .filter((col: any) => (isOfDetail || isDashboard ? col.name != "OF" : col))
    .filter((col: any) => (isDashboard ? col.name != "Actions" : col));

  return (
    <>
      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={habilitationsData?.data ?? []}
        columns={columns}
        selectionMode={SelectionMode.none}
      />

      {isPaginate && (
        <Pagination
          changePage={actions.changePage}
          page={habilitationsData?.meta?.current_page || 1}
          totalPages={Math.ceil(habilitationsData?.meta?.total / 15) || 1}
        />
      )}
    </>
  );
}

export default HabilitationOfDetailList;
