import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import TextResult from "../../../Common/TextResult/TextResult";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import useConstants from "../../../hooks/useConstants";
import { getCertificationsRequest } from "../../../services/certificationServices";
import { getJurysRequest } from "../../../services/juryServices";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useSuiviCohortesList from "../useSuiviCohortesList";

function SuiviCohortesListFilters() {
  const {
    suiviCohortes,
    suiviCohortesProps,
    isLoadingSuiviCohortes,
    actions,
    reactHookProps,
  } = useSuiviCohortesList();
  const { userSelectedBranch } = suiviCohortesProps;
  const { handleSubmit, control, setValue } = reactHookProps;
  const [showFilters, setShowFilters] = useState(false);
  const { constants } = useConstants();

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    isRefetching: isRefetchingCertifications,
  } = useQuery(
    ["certifications_export", userSelectedBranch],
    () =>
      getCertificationsRequest(
        {},
        { sort: "", page: 1, paginate: false, searchAt: "" },
        userSelectedBranch.id
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const certificationsFromApi =
    certifications?.data.map((certification: any) => ({
      id: certification.uuid,
      key: certification.uuid,
      text: `${certification.libelle} - ${certification.type_libelle}`,
    })) || [];
  const certificationList = [
    { id: undefined, text: "Toutes", key: "all" },
    ...certificationsFromApi,
  ];

  const {
    data: jurys,
    isLoading: isLoadingJurys,
    isRefetching: isRefetchingJurys,
  } = useQuery(
    ["jurys", userSelectedBranch],
    () =>
      getJurysRequest({}, { sort: "", page: 1, paginate: false, searchAt: "" }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const jurysFromApi =
    jurys?.data.map((jury: any) => ({
      id: jury.uuid,
      key: jury.uuid,
      text: `${jury.libelle}`,
    })) || [];
  const jurysList = [
    { id: undefined, text: "Tous", key: "all" },
    ...jurysFromApi,
  ];

  const toggleFilters = () => {
    setShowFilters((s) => !s);
  };

  const totalSuiviCohortes = suiviCohortes?.meta.total || 0;

  const cohorteStateList = [
    {
      key: "all",
      text: "Tous",
      id: undefined,
    },
    ...constants.CANDIDATURES.STATUT_SUIVI_COHORTE,
  ];

  const onWrapDefaultLabelRenderer = (
    props: any,
    defaultRender: any
  ): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <span>{defaultRender(props)}</span>
        <Tooltip
          id={"nom-tooltip"}
          content={"Vous pouvez rechercher par nom d'usage ou nom de naissance"}
        />
      </Stack>
    );
  };

  return isLoadingJurys ||
    isRefetchingJurys ||
    isLoadingCertifications ||
    isRefetchingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom_candidat"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Nom :"
                  styles={commonInputStyles.textField}
                  type="text"
                  onRenderLabel={onWrapDefaultLabelRenderer}
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="prenom_candidat"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Prénom du candidat :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="email_candidat"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Email du candidat :"
                  styles={commonInputStyles.textField}
                  type="email"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="annee_jury"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Année du jury :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          {showFilters && (
            <>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Dropdown
                        selectedKey={value ? value.key : undefined}
                        label="Statut à 6 mois :"
                        placeholder="Sélectionnez un statut"
                        options={cohorteStateList}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    );
                  }}
                  defaultValue={certificationList[0]}
                  control={control}
                  name="statut_6_mois"
                />
              </Stack.Item>

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Dropdown
                        selectedKey={value ? value.key : undefined}
                        label="Statut à 24 mois :"
                        placeholder="Sélectionnez un statut"
                        options={cohorteStateList}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    );
                  }}
                  defaultValue={certificationList[0]}
                  control={control}
                  name="statut_24_mois"
                />
              </Stack.Item>

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Dropdown
                        selectedKey={value ? value.key : undefined}
                        label="Certification :"
                        placeholder="Sélectionnez une certification"
                        options={certificationList}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    );
                  }}
                  defaultValue={certificationList[0]}
                  control={control}
                  name="certification"
                />
              </Stack.Item>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Controller
                        name="jury"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            label="Libellé du jury :"
                            styles={commonInputStyles.textField}
                            type="text"
                            {...field}
                          />
                        )}
                      />
                    );
                  }}
                  defaultValue={jurysList[0]}
                  control={control}
                  name="jury"
                />
              </Stack.Item>
            </>
          )}
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton
                isLoading={isLoadingSuiviCohortes}
                text="Rechercher"
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalSuiviCohortes} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default SuiviCohortesListFilters;
