import { DefaultButton, Link, MessageBar, Stack } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import Can from "../../../../Common/Can/Can";
import FormSection from "../../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import useTheme from "../../../../Common/useTheme";
import {
  branchAccessEnum,
  branchAccessMessageEnum,
  userAccessEnum,
} from "../../../../config/accessEnum";
import { API_URL } from "../../../../config/config";
import { commonButtonStyles, MessageBarStyles } from "../../../../styles";
import useLabelSettings from "../../../LabelSettingsDetail/useLabelSettings";
import { PartiesPrenantesContext } from "../usePartiesPrenantes";
import formSectionStyle from "./FormSection.styles";

function SelectEvaluateurFormSection({ disabled }: any) {
  const { primaryColor } = useTheme();
  const { labels } = useLabelSettings();
  const { conditionnalDisplayVariables, actions, reactHookProps, candidature, userSelectedBranch }: any =
    useContext(PartiesPrenantesContext);
  const {
    control,
    setValue,
    formState: { errors },
  } = reactHookProps;
  const { showEvaluateur } = conditionnalDisplayVariables;

  return (
    <FormSection sectionTitle={labels.PARIE_PRENANTE_EVALUATEURDUCANDIDAT}>
      {showEvaluateur ? (
        <>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label={`${labels.PARTIE_PRENANTE_EVALUATEURRATTACHE} :`}
                placeholder="Recherchez un évaluateur par son nom, prénom ou email"
                resourceURI={`${API_URL}api/candidature/candidatures/${candidature.uuid}/chercher-evaluateur`}
                addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?recherche=${terms}`}
                method="GET"
                renderItem={(item: any) => item.nom + " " + item.prenom}
                renderValue={(item: any) => item.nom + " " + item.prenom}
                dataIndex="uuid"
                name={name}
                value={value}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, { shouldValidate: true });
                }}
                defaultValue={null}
                //errorMessage={}
                handleResponse={(response: any) => [...response.data.data]}
                disabled={disabled || userSelectedBranch.gestion_par_domaine}
                required={true}
              />
            )}
            control={control}
            name="evaluateur"
          />

          {(!disabled && !userSelectedBranch.gestion_par_domaine) && (
            <Can I={userAccessEnum.SAISIR_EVALUATION}>
              <Stack horizontal>
                <Link
                  styles={formSectionStyle.buttonLink}
                  style={{ color: primaryColor }}
                  onClick={() => actions.addMeAs("evaluateur")}
                >
                  Sélectionner mon propre compte
                </Link>
              </Stack>
            </Can>
          )}

          {(!disabled && !userSelectedBranch.gestion_par_domaine) && (
            <Can I={branchAccessEnum.AUTORISER_CREATEUR_CANDIDATURE_INVITER_EVALUATEUR}>
              <Stack horizontal>
                <DefaultButton
                  styles={commonButtonStyles.defaultButton}
                  style={{ color: primaryColor, borderColor: primaryColor }}
                  text="Inviter un nouvel évaluateur"
                  onClick={() => actions.openUserFormModal("evaluateur")}
                />
              </Stack>
            </Can>
          )}
        </>
      ) : (
        <div style={{ margin: "15px 0" }}>
          <MessageBar className="bg-info" styles={MessageBarStyles}>
            {branchAccessMessageEnum.CREATEUR_CANDIDATURE_EFFECTUE_TOUJOURS_EVALUATION}
          </MessageBar>
        </div>
      )}
    </FormSection>
  );
}

export default SelectEvaluateurFormSection;
