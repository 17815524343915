import { DateTime } from "luxon";
import { branchAccessEnum } from "../config/accessEnum";
import { certificationTypeCantBeContratApprentissageValues } from "../Pages/CandidatureWorkflow/CreationCandidature/FormSections/CertificationSection";

const queryString = require("query-string");

const tools = {
  findIn: (inTheArray: any, theValue: any, theField: any) => {
    var returnValue = {};
    if (inTheArray?.length && theField) {
      returnValue =
        inTheArray.find((en: any) => en[theField] == theValue) || {};
    }
    return returnValue;
  },
  buildFormData: (formData: any, data: any, parentKey: any) => {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        tools.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  },
  sortColumnRefactoring: (theArray: string[], theColumnName: string) => {
    theArray = theArray.filter((e: any) => e);
    if (
      !theArray.includes(theColumnName) &&
      !theArray.includes(`-${theColumnName}`)
    ) {
      theArray.unshift(theColumnName);
    } else {
      if (theArray.includes(`-${theColumnName}`)) {
        theArray = theArray.filter((e: any) => e != `-${theColumnName}`);
      } else {
        theArray = theArray.filter((e: any) => e != `${theColumnName}`);
        theArray.unshift(`-${theColumnName}`);
      }
    }
    return theArray.join(",");
  },
  sorted: (theSort: string, theColumnName: string) => {
    if (!theSort) {
      return;
    }
    const newArray = theSort.split(",");
    const response = {
      isSorted: false,
      isSortedDescending: false,
    };
    if (
      !newArray.includes(theColumnName) &&
      !newArray.includes(`-${theColumnName}`)
    ) {
      response.isSorted = false;
    } else {
      response.isSorted = true;
      if (newArray.includes(theColumnName)) {
        response.isSortedDescending = false;
      } else {
        response.isSortedDescending = true;
      }
    }
    return response;
  },
  addKeyToObjectInArray: (theArray: any, theKeyToCopy?: any) => {
    theKeyToCopy
      ? theArray.forEach((item: any) => (item.key = item[theKeyToCopy]))
      : theArray.forEach((item: any, index: any) => (item.key = index));

    return theArray;
  },
  getModaliteNotationObject: (
    modalitesEvaluationUUID: any,
    notationValues: any,
    modaliteEvaluationConstant: any
  ) => {
    if (!modalitesEvaluationUUID) return {};
    const selectedNotation = tools.findIn(
      modaliteEvaluationConstant,
      modalitesEvaluationUUID,
      "id"
    ) as any;
    const arrayOfLetters = ["A", "B", "C", "D", "E"];
    const initialState = {} as any;
    for (var nb = 0; nb < selectedNotation.nbLetters; nb++) {
      var letter = arrayOfLetters[nb];
      var val =
        notationValues && notationValues[letter] ? notationValues[letter] : "";
      initialState[letter] = val;
    }
    return initialState;
  },
  checkBlockHasOption: (block: any, optionName: string) => {
    return block.find((b: any) => b.option === optionName);
  },
  checkDocumentHasOption: (document: any, optionName: string) => {
    return document.find((b: any) => b.option === optionName);
  },
  checkDocumentParcheminHasOption: (
    document_parchemin: any,
    optionName: string
  ) => {
    return document_parchemin.find((b: any) => b.option === optionName);
  },
  removeBlockHasOption: (block: any, optionName: string) => {
    return block.filter((b: any) => b.option !== optionName);
  },
  removeDocumentHasOption: (document: any, optionName: string) => {
    return document.filter((b: any) => b.option !== optionName);
  },
  removeDocumentParcheminHasOption: (
    document_parchemin: any,
    optionName: string
  ) => {
    return document_parchemin.filter((b: any) => b.option !== optionName);
  },
  updateBlockOptionName: (
    block: any,
    previousOptionName: any,
    newOptionName: any
  ) => {
    return block.map((b: any) => {
      if (b.option == previousOptionName) {
        b.option = newOptionName;
      }
      return b;
    });
  },
  updateDocumentOptionName: (
    document: any,
    previousOptionName: any,
    newOptionName: any
  ) => {
    return document.map((b: any) => {
      if (b.option == previousOptionName) {
        b.option = newOptionName;
      }
      return b;
    });
  },
  updateDocumentParcheminOptionName: (
    document_parchemin: any,
    previousOptionName: any,
    newOptionName: any
  ) => {
    return document_parchemin.map((b: any) => {
      if (b.option == previousOptionName) {
        b.option = newOptionName;
      }
      return b;
    });
  },
  formatPhoneNumber: (phoneNumberString: string) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (match) {
      match.shift();
      return match.join(" ");
    }
    return null;
  },
  convertHslToHex: (h: number, s: number, l: number) => {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  },
  createColorPickerTable: (h: number, s: number, l: number) => {
    const colors = [];
    for (var i = 0; i < 17; i++) {
      colors.push({
        key: i,
        text: `Color ${i}`,
        color: `${tools.convertHslToHex(h * i, s, l)}`,
      });
    }
    return colors;
  },
  generateFilterUrl: (filtersObject: any, filtersType: any) => {
    //in:
    //  filterObject={
    //    raison_sociale:'entreprise',
    //    siret:'1213',
    //    actif:{key: 'yes',text: 'Oui',value: true,},
    //    certifications:[{uuid:'...', ...}]
    //  }
    //  keyUrl si queryParams different du nom input (facultatif)
    //  filtersType={
    //    raison_sociale: { type: 'string', keyUrl: 'filter[raison_sociale]' },
    //    siret:{ type: 'string', keyUrl: 'filter[siret]' },
    //    actif:{ type: 'select', keyValue: 'value', keyUrl: 'filter[actif]', valueNotFilter: undefined },
    //    certifications:{ type: 'array', keyValue: 'uuid', keyUrl: 'filter[certifications]' },
    //  }
    //out: 'filter[raison_sociale]=entreprise&filter[siret]=1213&filter[actif]=true&certifications[]=...'
    var response: any = "";
    response = Object.keys(filtersObject)
      .filter(
        (key: any) =>
          filtersObject[key] !== undefined &&
          filtersObject[key] !== "" &&
          filtersObject[key] !== null
      )
      .map((key) => {
        const informationKey = filtersType[key];
        switch (filtersType[key]["type"]) {
          case "string":
            if (informationKey["keyUrl"]) {
              return `${informationKey["keyUrl"]}=${filtersObject[key]}`;
            }
            return `${key}=${filtersObject[key]}`;
          case "select":
            if (
              informationKey["keyUrl"] &&
              filtersObject[key][informationKey["keyValue"]] !==
                informationKey["valueNotFilter"]
            ) {
              return `${informationKey["keyUrl"]}=${
                filtersObject[key][informationKey["keyValue"]]
              }`;
            } else if (
              filtersObject[key][informationKey["keyValue"]] !==
              informationKey["valueNotFilter"]
            ) {
              return `${key}=${filtersObject[key][informationKey["keyValue"]]}`;
            }
            break;
          case "array":
            var returnArray: string[] = [];
            if (informationKey["keyUrl"]) {
              filtersObject[key]?.forEach((item: any) => {
                returnArray.push(
                  `${informationKey["keyUrl"]}[]=${
                    item[informationKey["keyValue"]]
                  }`
                );
              });
              return returnArray.join("&");
            }
            filtersObject[key]?.forEach((item: any) => {
              returnArray.push(`${key}[]=${item[informationKey["keyValue"]]}`);
            });
            return returnArray.join("&");
          default:
            break;
        }
      })
      .filter(Boolean)
      .join("&");

    return response.length ? `&${response}` : "";
  },
  objectMap: (obj: any, fn: Function) =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
    ),
  generateOptionUrl: (optionObject: any) => {
    //in: {page:1, paginate:true|false, sort:'nom,-active', searchAt:...}
    //out: 'sort[0]=nom&sort[1]=-active&page=1&paginate=true&searchAt=...'
    const transformedOption = {
      ...optionObject,
      sort: optionObject.sort?.split(","),
    };

    var response: any = "";
    response = Object.keys(transformedOption)
      //retirer searchAt utilise uniquement par react-query pour trigger la recherche
      .filter((key) => key != "searchAt")
      .map((key) => {
        if (!(key == "sort")) {
          return `${key}=${transformedOption[key]}`;
        } else {
          if (transformedOption.sort[0]) {
            return queryString.stringify(
              { sort: transformedOption[key] },
              { arrayFormat: "index" }
            );
          }
        }
      })
      .filter(Boolean)
      .join("&");

    return response;
  },
  formatDate: (date: any) => {
    if (!date) return "";
    return DateTime.fromISO(date).toFormat("dd/MM/yyyy");
  },
  mapOnApiErrors: (errors: any, reactHookProps: any) => {
    Object.keys(errors).forEach((err: any) => {
      const errMsg = errors[err];
      reactHookProps.setError(err, { type: "custom", message: errMsg[0] });
    });
  },
  lightenDarkenColor: (hexColor: any, magnitude: any) => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  },
  debounce: (fn: any, ms: any) => {
    let timer: any;
    return (_: any) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this);
      }, ms);
    };
  },
  normalize: (str: string) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(" ", "_");
  },
  checkRightToUpdateCandidature: (
    candidature: any,
    user: any,
    profilsOnThisBranch: any,
    entitiesLinkOnThisBranch: any
  ) => {
    const adminProfil = ["ADMINISTRATEUR_BRANCHE", "ADMINISTRATEUR_DOMAINE"];

    const isUserAdmin =
      user.est_administrateur ||
      profilsOnThisBranch.some((r: any) => adminProfil.includes(r));

    const isCandidatureCreatedByAdmin = candidature.cree_par == null;

    const isCandidatureCreatedByAdminAndUserNotAdmin =
      isCandidatureCreatedByAdmin && !isUserAdmin;

    const isUserNotLinkedToCreator =
      !isCandidatureCreatedByAdmin &&
      !entitiesLinkOnThisBranch[candidature?.cree_par?.type]?.find(
        (e: any) => e.uuid == candidature.cree_par.uuid
      );

    return (
      isCandidatureCreatedByAdminAndUserNotAdmin ||
      (isUserNotLinkedToCreator && !isUserAdmin)
    );
  },
  getFullname: (user: any) => {
    if (!user) return "";
    let civ = "";
    switch (user.civilite) {
      case 1:
        civ = "Mr";
        break;
      case 2:
        civ = "Mme";
        break;
      default:
        civ = "";
    }

    return `${civ ? civ + " " : ""}${user.nom} ${user.prenom}`;
  },
  reformateDateFromJsDate: (date: any) => {
    if (!date) return "";
    return DateTime.fromJSDate(new Date(date)).toFormat("dd/MM/yyyy");
  },
  isTodayBeforeStartOfThisDate: (date: any) => {
    return (
      DateTime.now().startOf("day") < DateTime.fromISO(date).startOf("day")
    );
  },
  isTodayBeforeOrEqualStartOfThisDate: (date: any) => {
    return (
      DateTime.now().startOf("day") <= DateTime.fromISO(date).startOf("day")
    );
  },
  isThisEndOfDateAfterStartOfToday: (date: any) => {
    return DateTime.fromISO(date).endOf("day") > DateTime.now().startOf("day");
  },
  isThisEndOfDateBeforeStartOfToday: (date: any) => {
    return DateTime.fromISO(date).endOf("day") < DateTime.now().startOf("day");
  },
  isThisStartOfDateBeforeOrEqualStartOfToday: (date: any) => {
    return (
      DateTime.fromISO(date).startOf("day") <= DateTime.now().startOf("day")
    );
  },
  isThisStartOfDateAfterOrEqualStartOfToday: (date: any) => {
    return (
      DateTime.fromISO(date).startOf("day") >= DateTime.now().startOf("day")
    );
  },
  parseDateFromStringDatePicker: (dateStr: any) => {
    const newDate = DateTime.fromFormat(dateStr, "dd/MM/yyyy").toJSDate();
    return newDate;
  },
  getUnique(arr: any, comp: any) {
    const unique = arr
      .map((e: any) => e[comp])
      .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);
    return unique;
  },
  addUnderStageToFilter: (filters: any) => {
    const transformedFilters = {
      ...filters,
    };

    const filteredStatut: any = [];
    filters.statuts?.map((s: any) => {
      s.statuses.map((ss: any) => {
        filteredStatut.push(ss);
      });
    });

    transformedFilters.statuts = tools.getUnique(filteredStatut, "key");
    if (filters.statuts.length > 0) {
      //item[0] car uniquement un seul possible isMultiple -> false
      if (filters.statuts[0].resultat) {
        transformedFilters.resultat = filters.statuts[0].resultat;
      }
    }

    return transformedFilters;
  },
  fieldSorterBo: (fields: any) => {
    return (a: any, b: any) =>
      fields
        .map((o: any) => {
          let dir = 1;
          if (o[0] === "-") {
            dir = -1;
            o = o.substring(1);
          }
          return tools.getValueByPath(o, a) > tools.getValueByPath(o, b)
            ? dir
            : tools.getValueByPath(o, a) < tools.getValueByPath(o, b)
            ? -dir
            : 0;
        })
        .reduce((p: any, n: any) => (p ? p : n), 0);
  },
  getValueByPath: (path: any, obj: any) => {
    const keys = path.split("."); // split the path into an array of keys
    let value = obj; // start with the object as the initial value

    // iterate through each key in the path
    for (let key of keys) {
      value = value[key]; // get the value at the current key
      if (value === undefined) {
        return undefined; // return undefined if the key doesn't exist in the object
      }
    }

    return value; // return the final value
  },
  isLocationSimilarUrl: (url1: any, url2: any) => {
    if (url1.length > 0 && url2.length > 0) {
      const loc2 = url2.substr(1, url2.length - 1);
      return url1 == loc2;
    }
    return false;
  },
  capitalize: (str: String) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  transformDocumentsForBo: ({ documents, etapesCandidature }: any) => {
    return documents
      ?.map((d: any, i: any) => {
        const documentInfo = d.documentInfo;
        if (d.isChampComplementaire && !d.file) {
          return null;
        }
        const statutCandidature = etapesCandidature.find(
          (s: any) => s.id === d.etapeInfo
        );
        return {
          etape: statutCandidature?.text || "",
          nom: d.isChampComplementaire
            ? documentInfo.libelle
            : documentInfo.nom || documentInfo.name,
          file_url: d.file_url || d.file,
          champ_complementaire: d.isChampComplementaire,
        };
      })
      .filter(Boolean);
  },
  whichParametreSuiviCohorteActif: (parametreBranche: any) => {
    const parametreBranchSuiviCohorte = [
      branchAccessEnum.SUIVI_COHORTES_MODEL_OF_CANDIDAT,
      branchAccessEnum.SUIVI_COHORTES_MODEL_OF,
      branchAccessEnum.SUIVI_COHORTES_MODEL_ENTREPRISE_CANDIDAT,
      branchAccessEnum.SUIVI_COHORTES_MODEL_ENTREPRISE,
    ];

    const parametreBranchSuiviCohorteActif = parametreBranche.filter(
      (setting: any) =>
        parametreBranchSuiviCohorte.includes(setting.parametre) &&
        setting.valeur
    );

    return parametreBranchSuiviCohorteActif.length
      ? parametreBranchSuiviCohorteActif[0]
      : false;
  },
  candidatureCanBeUnsubscribe: (
    candidature: any,
    isAdmin: boolean,
    isUserCanInscrireCandidatJury: boolean,
    isObligerValidationInscriptionJury: boolean
  ) => {
    const isAdminUnsubscribeCondition =
      isAdmin &&
      !candidature.jury?.est_cloture &&
      (candidature.statut == "decision_jury" ||
        candidature.statut == "inscription_jury");

    const isNotObligerValidationInscriptionConditons =
      !isObligerValidationInscriptionJury &&
      tools.isTodayBeforeOrEqualStartOfThisDate(
        candidature.jury?.date_limite_inscription_candidat
      ) &&
      candidature.statut == "decision_jury";

    const isObligerValiationInscriptionConditons =
      isObligerValidationInscriptionJury &&
      tools.isTodayBeforeOrEqualStartOfThisDate(
        candidature.jury?.date_limite_inscription_candidat
      ) &&
      candidature.statut == "inscription_jury";

    return (
      candidature.jury &&
      (isAdminUnsubscribeCondition ||
        (isUserCanInscrireCandidatJury &&
          (isNotObligerValidationInscriptionConditons ||
            isObligerValiationInscriptionConditons)))
    );
  },
  typeContratAllowed: (certificationType: number, typeContratConstant: any) => {
    return typeContratConstant.filter((type: any) => {
      if (
        certificationTypeCantBeContratApprentissageValues.includes(
          certificationType
        )
      ) {
        //INFO: 3 is the key for Contrat d'apprentissage
        return type.key != 3;
      }
      return type;
    });
  },
};

export default tools;
