import {
    Stack,
    Label,
    DefaultButton,
    TextField
} from "@fluentui/react";
import { useEffect } from 'react'
import { useParams } from "react-router-dom";

import {
    Controller,
    useForm
} from "react-hook-form";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { joiResolver } from "@hookform/resolvers/joi";
import { staticPagesSchema } from "../../schemas/staticPagesSchema";

import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import LoadButton from "../../Common/LoadButton/LoadButton";
import FormSection from "../../Common/FormSection/FormSection";
import { commonInputStyles, commonButtonStyles } from '../../styles/index'
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import useStaticPageDetail from "./useStaticPageDetail";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";

const Editor = require('ckeditor5-custom-build/build/ckeditor')
function StaticPageDetail() {

    const { type, id } = useParams();
    const { page, isLoadingPutPage, errorPutPage, actions, isLoadingPage } = useStaticPageDetail(type)

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        setError,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        defaultValues: page,
        resolver: joiResolver(staticPagesSchema),
    })

    useEffect(() => {
        return () => reset()
      }, [])

    useEffect(() => {
        actions.setUpdatePageId(id)
    }, [])

    useEffect(() => {
        reset(page)
    }, [page])

    useEffect(() => {
        var apiErrors = (errorPutPage as any)?.response?.data;
        if (apiErrors?.errors) {
            for (var key in apiErrors.errors) {
                setError(key as any, { type: "focus", message: apiErrors.errors[key] });
            }
        }
    }, [errorPutPage]);

    return (
        <Stack>

            <BackLink />

            <Page
                title="Détail de la page"
                explain="Veuillez modifier les informations de la page"
            >

                <DetailLoader isLoadingDetail={isLoadingPage}>
                    <form
                        onSubmit={handleSubmit(actions.putPage)}
                    >
                        <FormSection
                            sectionTitle="Informations"
                        >
                            <Stack.Item>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            styles={commonInputStyles.textField}
                                            required
                                            label="Titre de la page :"
                                            type="text"
                                            {...field}
                                            placeholder="EX: Condition générales"
                                            errorMessage={(errors.titre as any)?.message}
                                        />
                                    )}
                                    defaultValue=""
                                    control={control}
                                    name="titre"
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <Label required={true} styles={commonInputStyles.label}>Saisissez le contenu de la page </Label>
                                <Controller
                                    control={control}
                                    name="contenu"
                                    render={({ field: { name, onBlur, value } }) => (
                                        <>
                                            <CKEditor
                                                editor={Editor}
                                                data={value}
                                                onReady={(editor: any) => {
                                                    editor.editing.view.change((writer: any) => {
                                                        writer.setStyle(
                                                            "min-height",
                                                            "400px",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setValue(name, data, { shouldValidate: true })
                                                }}
                                                onBlur={onBlur}
                                            />
                                            <PageMessage
                                                message={(errors.contenu as any)?.message}
                                                type="error"
                                            />
                                        </>
                                    )}
                                />
                            </Stack.Item>

                        </FormSection>

                        <Stack
                            style={{ position: 'fixed', bottom: 180, right: 80 }}
                        >
                            <DefaultButton
                                text="Aperçu"
                                onClick={handleSubmit(actions.openPreviewPanel)}
                                styles={commonButtonStyles.defaultButton}
                            />
                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 20 }}>
                            <CancelButton />

                            <LoadButton isLoading={isLoadingPutPage} text={"Modifier"} />
                        </Stack>
                    </form>
                </DetailLoader>

            </Page>
        </Stack>
    )
}

export default StaticPageDetail