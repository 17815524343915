import { routesConfig } from "../config/routesConfig";
import { useLocation } from "react-router-dom";
import tools from "../utils/tools";
import useAuth from "./useAuth";

function useRouteAccessibility() {
  const { pathname } = useLocation();
  const { isAuth } = useAuth();

  let isPrivateRoute = false;
  const routesWithoutParam: any = tools.findIn(routesConfig, pathname, 'path')
  if (routesWithoutParam?.type) {
    if (pathname == '/') {
      isPrivateRoute = isAuth;
    } else {
      isPrivateRoute = routesWithoutParam.type == 'private'
    }
  } else {
    isPrivateRoute = isAuth;
  }

  let isPublicRoute = !isPrivateRoute; 
  //if auth and route unknown (ex with param) return false [to fix]

  return {
    isPrivateRoute,
    isPublicRoute,
  };
}

export default useRouteAccessibility;
