import { Dropdown, Link, Stack, TextField } from "@fluentui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { commonInputStyles } from "../../../styles";
import PreviousStepButton from "../PreviousStepButton/PreviousStepButton";
import SelectedAccount from "../SelectedAccount/SelectedAccount";
import { SignUpContext } from "../useSignUp";
import { useQuery } from "react-query";
import { getConstantRequest } from "../../../services/publicServices";
import Loader from "../../../Common/Loader/Loader";

const profileInformationSchema = Joi.object({
  profil: Joi.string()
    .required()
    .valid("ENTREPRISE", "OF", "GROUPE_ENTREPRISE")
    .messages({
      "string.empty": "Le profil est obligatoire",
      "any.only":
        "Le profil doit être 'ENTREPRISE', 'OF' ou 'GROUPE_ENTREPRISE'",
    }),
  cle_profil: Joi.string().messages({
    "string.empty": "Le champs est obligatoire",
  }),
});

function ProfileInformation() {
  const { actions, accountInformations }: any = useContext(SignUpContext);
  const reactHookProps: any = useForm({
    mode: "onBlur",
    resolver: joiResolver(profileInformationSchema),
  });

  const { data: constants, isLoading } = useQuery(["motifs"], () =>
    getConstantRequest()
  );

  const selectedProfile = reactHookProps.watch("profil");
  const profileList: any =
    constants?.DEMANDE_INSCRIPTION?.PROFILS_AUTORISES ?? [];

  useEffect(() => {
    reactHookProps.setValue("cle_profil", "");
  }, [selectedProfile]);

  const getProfileSpecificInput = () => {
    var props: any = { reactHookProps };
    switch (selectedProfile) {
      case "ENTREPRISE":
      case "OF":
        props = {
          ...props,
          label: "Numéro SIRET :",
          placeholder: "14 chiffres",
          maxLengthCle: 14,
        };
        break;
      case "GROUPE_ENTREPRISE":
        props = {
          ...props,
          label: "Numéro SIREN :",
          placeholder: "9 chiffres",
          maxLengthCle: 9,
        };
        break;
    }
    return <CleProfilInput {...props} />;
  };

  return (
    <>
      <SelectedAccount>
        <PreviousStepButton text="Modifier les informations du compte" />
      </SelectedAccount>
      <form
        onSubmit={reactHookProps.handleSubmit(
          actions.onSubmitProfileInformations
        )}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          {isLoading ? (
            <Loader />
          ) : (
            <Stack.Item>
              <Controller
                render={({ field: { name, value } }) => (
                  <Dropdown
                    label="Profil :"
                    placeholder="Sélectionnez un profil"
                    options={profileList}
                    //calloutProps={{ doNotLayer: true }}
                    onChange={(e: any, options: any) => {
                      reactHookProps.setValue(name, options.id, {
                        shouldValidate: true,
                      });
                    }}
                    required
                    styles={commonInputStyles.dropdownStyle}
                  />
                )}
                control={reactHookProps.control}
                name="profil"
              />
            </Stack.Item>
          )}
          {selectedProfile && (
            <Stack.Item>{getProfileSpecificInput()}</Stack.Item>
          )}
          <Stack.Item>
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
            >
              <Stack.Item>
                <Link
                  href="/"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Revenir sur la page de connexion
                </Link>
              </Stack.Item>
              <Stack.Item>
                <Link
                  href={`/demande-aide?branche=${accountInformations.branch_id}`}
                >
                  Demande d'aide
                </Link>
              </Stack.Item>
              <Stack.Item>
                <LoadButton
                  text="Envoyer ma demande d'inscription"
                  type="submit"
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </form>
    </>
  );
}

const CleProfilInput = ({
  reactHookProps,
  label,
  placeholder,
  maxLengthCle,
}: any) => {
  const { formState } = reactHookProps;
  return (
    <Controller
      render={({ field: { onChange, value, name } }) => (
        <TextField
          styles={commonInputStyles.textField}
          required
          label={label}
          type="text"
          value={value}
          placeholder={placeholder}
          errorMessage={(formState.errors[name] as any)?.message}
          onChange={(e: any, newValue: any) => {
            if (!newValue || newValue.length <= maxLengthCle) {
              onChange(e.target.value);
            }
          }}
        />
      )}
      defaultValue=""
      control={reactHookProps.control}
      name="cle_profil"
    />
  );
};

export default ProfileInformation;
