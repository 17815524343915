import { toast } from "react-toastify";
import { IStructure } from "../../interfaces/IStructure";

function useStructureDetails() {
  const structure: IStructure = {
    name: "Branche1",
    siren: "123456789",
    adresse: "14 rue des 4 tours",
    complement_adresse: "4",
    code_postal: "94250",
    region: "Ile de france",
    ville: "Gentilly",
  };

  const actions = {
    saveStructure: (data: any) => {
      console.log(data); // dev
      toast.info(`En cours de développement`);
    },
    setReferant: (ev: any, user: any) => {
      ev.preventDefault();
      toast.info(`En cours de développement`);
    },
  };

  return {
    structure,
    actions,
  };
}

export default useStructureDetails;
