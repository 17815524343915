import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Label, Stack } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
const Editor = require("ckeditor5-custom-build/build/ckeditor");

function InputDescriptionCandidature({
  label,
  onChange,
  control,
  onClick,
  isSaving,
}: any) {
  return (
    <Stack>
      <Stack.Item>
        <Label style={{ fontSize: "16px", fontWeight: "500" }}>
          {label.description} :
        </Label>

        <Controller
          render={({ field: { name, onBlur, value } }) => (
            <>
              <CKEditor
                editor={Editor}
                data={value}
                onReady={(editor: any) => {
                  editor.editing.view.change((writer: any) => {
                    writer.setStyle(
                      "min-height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onChange(data);
                }}
              />
            </>
          )}
          defaultValue=""
          name={label.type}
          control={control}
        />
      </Stack.Item>
      <Stack horizontal horizontalAlign="end" style={{ marginTop: 20 }}>
        <LoadButton
          type="button"
          onClick={onClick}
          isLoading={isSaving}
          text={"Modifier"}
        />
      </Stack>
    </Stack>
  );
}

export default InputDescriptionCandidature;
