import {
  DefaultButton,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import DynamicFieldForm from "../../../Common/DynamicFieldForm/DynamicFieldForm";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import candidatureAdapters from "../../../adapters/candidatureAdapters";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import useDynamicFields from "../../../hooks/dynamicFields/useDynamicFields";
import useOfflineCandidaturePile from "../../../hooks/offlineCandidature/useOfflineCandidaturePile";
import useConstants from "../../../hooks/useConstants";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import useFetchCandidature from "../useFetchCandidature";
import useUrlParams from "../useUrlParams";
import { useEvaluation } from "./useEvaluation";

const Evaluation = () => {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const {
    candidature,
    reactHookProps,
    actions,
    candidatureActions,
    conditionnalDisplayVariable,
    isSaving,
    isSavingSecondeEvaluation,
    isSuccessToInviteSecondEvaluateur,
    isRemovedSecondEvaluateur,
    isLoadingRemoveSecondEvaluateur,
  } = useEvaluation();
  const { labels } = useLabelSettings();
  const {
    isThisPageStatusBeforeCandidatureStatus,
    isFirstStatutBeforeOrEqualSecondStatus,
    isReferentielStillEditable,
  } = useCandidatureWorkflow();
  const { actions: accessActions } = useAccessSettings();
  const { constants } = useConstants();
  const { id_candidature } = useUrlParams();
  const { dynamicFields } = useDynamicFields();
  const { actions: actionsPile, isOffline } = useOfflineCandidaturePile();
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const fetchCandidature = useFetchCandidature(
    reactHookProps,
    (cand: any) => {}
  );
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  const fetchModifiedCandidature = async () => {
    const candidatureModified = await candidatureActions.getCandidatureDetail(
      id_candidature
    );
    reactHookProps.reset(
      candidatureAdapters.transformForBO(
        candidatureModified,
        constants,
        isFirstStatutBeforeOrEqualSecondStatus(
          candidature.statut,
          "identification_parties_prenantes"
        ),
        dynamicFields
      )
    );
  };

  useEffect(() => {
    if (
      id_candidature &&
      (isSuccessToInviteSecondEvaluateur || isRemovedSecondEvaluateur)
    ) {
      fetchModifiedCandidature();
    }
  }, [isSuccessToInviteSecondEvaluateur, isRemovedSecondEvaluateur]);

  useEffect(() => {
    return () => candidatureActions.resetCandidature();
  }, []);

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, ["validation_evaluation"])
      ? userAccessEnum.MODIFIER_EVALUATION_APRES_TRANSMISSION
      : userAccessEnum.NO_ACCESS_EVEN_ADMIN //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : userAccessEnum.SAISIR_EVALUATION;

  const inputsDisabled =
    !accessActions.canI({ action: [applicableEditPermission] }).granted ||
    (!conditionnalDisplayVariable.isEvaluateurThisCandidature &&
      !isAnyKindOfAdmin);
  //si on a pas les acces pour editer l'evaluation ||
  //si on n'est pas l'evaluateur de la candidature  &&
  //si on n'est pas un administrateur

  const applicableEdit2ndEvaluationPermission =
    isThisPageStatusBeforeCandidatureStatus(candidature.statut)
      ? isReferentielStillEditable(candidature.statut, [
          "validation_evaluation",
        ])
        ? userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR
        : userAccessEnum.NO_ACCESS_EVEN_ADMIN
      : userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR;
  const inputs2ndEvaluationDisabled =
    !accessActions.canI({
      action: [applicableEdit2ndEvaluationPermission],
    }).granted ||
    !conditionnalDisplayVariable.isSecondEvaluateurThisCandidature;

  const onSubmit = (e: any, isEnregistrerEvaluation: boolean = false) => {
    if (isEditMode === StatutCandidature.EVALUATION) {
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data: any) => {
        const evaluationObject = {
          ...data,
          evaluation: data.blocs_competences_passes.map((b: any) => ({
            uuid: b.candidature_bloc_competence_uuid,
            decision: b.decision_evaluation,
            commentaire_evaluation: b.commentaire_evaluation,
            competences_associees: b.competences.map((c: any) => ({
              uuid: c.uuid,
              decision: c.decision_evaluation,
              criteres_evaluation: accessActions.canI({
                action: branchAccessEnum.EVALUATION_PAR_CRITERE,
              }).granted
                ? c.criteres
                  ? c.criteres.map((l: any) => ({
                      libelle: l.libelle,
                      decision: l.decision_evaluation?.toString(),
                    }))
                  : null //waiting api
                : null,
            })),
          })),
        };
        mecActions.onSaveMEC(evaluationObject);
      })(e);
    } else {
      if (
        !isEnregistrerEvaluation &&
        !isOffline &&
        !window.confirm(
          "Attention : veuillez vérifier que les informations et les documents ajoutés sont bien corrects : aucune modification ne pourra être réalisée"
        )
      ) {
        return false;
      }
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data) =>
        actions.onSaveEvaluation(data, isEnregistrerEvaluation)
      )(e);
    }
  };

  const onSubmitSecondEvaluation = (e: any) => {
    if (isEditMode === StatutCandidature.AVIS_SECOND_EVALUATEUR) {
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data: any) => {
        const secondEvaluationObject = {
          ...data,
          statut_a_modifier: "avis_second_evaluateur",
        };
        mecActions.onSaveMEC(secondEvaluationObject);
      })(e);
    } else {
      if (
        !isOffline &&
        !window.confirm(
          "Attention : veuillez vérifier que les informations et les documents ajoutés sont bien corrects : aucune modification ne pourra être réalisée"
        )
      ) {
        return false;
      }
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit(actions.onSaveSecondEvaluation)(e);
    }
  };

  const notificationInvitationSecondEvaluateur = candidature.second_evaluateur
    ? candidature.second_evaluateur.notifications
        .filter((n: any) => n.data.candidature == candidature.uuid)
        .find(
          (n: any) =>
            n.type == "InviterSecondEvaluateur" ||
            n.type == "CreationInvitationSecondEvaluateur"
        )
    : null;
  const dateMaxToGiveInvitationSecondEvaluateur =
    notificationInvitationSecondEvaluateur
      ? DateTime.fromISO(notificationInvitationSecondEvaluateur.created_at)
          .startOf("day")
          .plus({ day: 30 })
      : null;
  const howManyDaysToGiveAvis = dateMaxToGiveInvitationSecondEvaluateur
    ? dateMaxToGiveInvitationSecondEvaluateur.diff(
        DateTime.now().startOf("day"),
        ["days"]
      )
    : null;

  const onChangeFile = (e: any) => {
    localStorage.setItem("myfile", JSON.stringify(e.target.files[0]));
  };

  const isSecondEvaluateurInvited = accessActions.canI({
    action: [branchAccessEnum.OBLIGER_AVIS_SECOND_EVALUATEUR],
  }).granted
    ? candidature.second_evaluateur?.uuid
    : true;

  return !candidature.uuid ? null : (
    <Stack style={{ marginTop: 0 }}>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>

      {!isOffline &&
        !reactHookProps.watch("second_evaluateur") &&
        candidature.statut == "evaluation" &&
        accessActions.canI({
          action: branchAccessEnum.POSSIBILITE_D_INVITER_UN_SECOND_EVALUATEUR,
        }).granted && (
          <Can I={userAccessEnum.INVITER_SECOND_EVALUATEUR}>
            <CandidatureInfoHeaderWrapper className="ms-Grid-col ms-sm12 ms-lg6">
              <Stack horizontalAlign="start">
                {accessActions.canI({
                  action: branchAccessEnum.OBLIGER_AVIS_SECOND_EVALUATEUR,
                }).granted ? (
                  <Text style={{ marginBottom: 15 }}>
                    Vous êtes dans l'obligation d'inviter un second évaluateur
                    via le bouton ci-dessous avant de renseigner votre
                    évaluation et de cliquer sur valider en bas de page
                  </Text>
                ) : (
                  <Text style={{ marginBottom: 15 }}>
                    Si vous souhaitez inviter un second évaluateur, veuillez
                    l'inviter via le bouton ci-dessous avant de renseigner votre
                    évaluation et de cliquer sur valider en bas de page
                  </Text>
                )}

                <PrimaryButton
                  styles={commonButtonStyles.buttonSubmit}
                  onClick={actions.openAddSecondEvaluateurModal}
                >
                  Inviter un second évaluateur
                </PrimaryButton>
              </Stack>
            </CandidatureInfoHeaderWrapper>
          </Can>
        )}
      {inputsDisabled &&
        (conditionnalDisplayVariable.isEvaluateurThisCandidature ||
          isAnyKindOfAdmin) && (
          <Can I={userAccessEnum.SAISIR_EVALUATION}>
            <BoutonDemanderMEC
              text="Corriger l'évaluation"
              statut={StatutCandidature.EVALUATION}
              statut_candidature={candidature.statut}
              onAfterValidate={fetchCandidature.refrechCandidature}
            />
          </Can>
        )}
      <form onSubmit={onSubmit}>
        <OptionsBlocsSection
          candidature={candidature}
          statutCandidature={StatutCandidature.EVALUATION}
          reactHookProps={reactHookProps}
          disabled={
            inputsDisabled &&
            (!isEditMode || isEditMode !== StatutCandidature.EVALUATION)
          }
          onValidate={actions.onValidate}
          onInvalidate={actions.onInvalidate}
        />

        <DynamicFieldForm
          statut={StatutCandidature.EVALUATION}
          reactHookProps={reactHookProps}
          disabled={
            inputsDisabled &&
            (!isEditMode || isEditMode !== StatutCandidature.EVALUATION)
          }
        />

        <Stack
          horizontal
          horizontalAlign={"space-between"}
          style={{ marginTop: 20 }}
        >
          {(!reactHookProps.watch("second_evaluateur") ||
            (isOffline &&
              conditionnalDisplayVariable.isEvaluateurThisCandidature)) &&
            !isOpenedInModal && <CancelButton text={"Retour"} />}

          {!isOffline &&
            reactHookProps.watch("second_evaluateur") &&
            candidature.statut == "evaluation" && (
              <Can I={userAccessEnum.INVITER_SECOND_EVALUATEUR}>
                {isLoadingRemoveSecondEvaluateur ? (
                  <Loader />
                ) : (
                  <DefaultButton
                    styles={commonButtonStyles.defaultButton}
                    onClick={actions.onCancelInvitationSecondEvaluateur}
                  >
                    Annuler l'invitation du second évaluateur
                  </DefaultButton>
                )}
              </Can>
            )}

          <Can I={applicableEditPermission}>
            <>
              {(conditionnalDisplayVariable.isEvaluateurThisCandidature ||
                isAnyKindOfAdmin) &&
                isSecondEvaluateurInvited && (
                  <>
                    <LoadButton
                      text={"Enregistrer l'évaluation"}
                      type="button"
                      onClick={(e: any) => onSubmit(e, true)}
                      isLoading={isSaving}
                    />
                    <LoadButton
                      text={"Valider l'évaluation"}
                      isLoading={isSaving}
                    />
                  </>
                )}
            </>
          </Can>
          {isEditMode === StatutCandidature.EVALUATION && (
            <BoutonEnregistrerMEC
              reactHookProps={reactHookProps}
              onAfterValidate={fetchCandidature.refrechCandidature}
            />
          )}
        </Stack>
      </form>
      {reactHookProps.watch("second_evaluateur") && (
        <Can
          I={[
            userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR,
            userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
          ]}
        >
          {inputs2ndEvaluationDisabled &&
            conditionnalDisplayVariable.isSecondEvaluateurThisCandidature && (
              <BoutonDemanderMEC
                text="Corriger la seconde évaluation"
                statut={StatutCandidature.AVIS_SECOND_EVALUATEUR}
                statut_candidature={candidature.statut}
                onAfterValidate={fetchCandidature.refrechCandidature}
              />
            )}
          <form onSubmit={onSubmitSecondEvaluation}>
            <FormSection sectionTitle={labels.AVIS_SECOND_EVALUATEUR_TITRE}>
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Avis du second évaluateur (3000 caractères max) :"
                    placeholder="Ex : commentaire"
                    multiline
                    required
                    rows={6}
                    maxLength={3000}
                    {...field}
                    disabled={
                      inputs2ndEvaluationDisabled &&
                      (!isEditMode ||
                        isEditMode !== StatutCandidature.AVIS_SECOND_EVALUATEUR)
                    }
                  />
                )}
                control={reactHookProps.control}
                name="avis"
              />
            </FormSection>

            <DynamicFieldForm
              statut={StatutCandidature.AVIS_SECOND_EVALUATEUR}
              reactHookProps={reactHookProps}
              disabled={
                inputsDisabled &&
                (!isEditMode ||
                  isEditMode !== StatutCandidature.AVIS_SECOND_EVALUATEUR)
              }
            />

            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ marginTop: 20 }}
            >
              {!isOpenedInModal && <CancelButton text="Retour" />}

              <Can I={applicableEdit2ndEvaluationPermission}>
                {conditionnalDisplayVariable.isSecondEvaluateurThisCandidature && (
                  <LoadButton
                    isDisabled={
                      (howManyDaysToGiveAvis as any)?.toObject().days < 0
                    }
                    text={"Valider la seconde évaluation"}
                    isLoading={isSavingSecondeEvaluation}
                  />
                )}
              </Can>
              {isEditMode === StatutCandidature.AVIS_SECOND_EVALUATEUR && (
                <BoutonEnregistrerMEC
                  reactHookProps={reactHookProps}
                  onAfterValidate={fetchCandidature.refrechCandidature}
                />
              )}
            </Stack>
          </form>
        </Can>
      )}
    </Stack>
  );
};

export default Evaluation;
