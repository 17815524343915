import {
  CommandButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Stack,
  Text
} from "@fluentui/react";
import { useContext } from "react";
import BooleanValue from "../../../Common/BooleanValue/BooleanValue";
import DownloadFileButton from "../../../Common/DownloadFileButton/DownloadFileButton";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";

const DocumentList = ({ documents = [], openDocument }: any) => {

  const { actions }: any = useContext(CertificationContext)

  const columns: IColumn[] = [
    {
      key: "1",
      name: "Libellé",
      fieldName: "nom",
      minWidth: 50,
      maxWidth: 125,
      isResizable: true,
    },
    {
      key: "2",
      name: "Description",
      fieldName: "description",
      minWidth: 50,
      maxWidth: 125,
      isResizable: true,
    },
    {
      key: "3",
      name: "Modèle",
      minWidth: 50,
      maxWidth: 125,
      isResizable: true,
      onRender: (item: any) => {
        if (item?.file_url && !item?.file) {
          const urlInstance = new URLSearchParams(item.file_url);
          let fileName = urlInstance.get('file_name')
          return (
            <CommandButton href={item.file_url} style={{ marginTop: 10, textDecoration: 'none' }} download target='_blank'>
              {fileName}
            </CommandButton>
          )
        }

        if(item.file){
          return (<DownloadFileButton file={[item.file]} />)
        }
        
        return false
      }
    },
    {
      key: "4",
      name: "Profils en lecture",
      fieldName: "profils_autorises_en_lecture",
      minWidth: 50,
      maxWidth: 175,
      isResizable: true,
      onRender: (item: any) =>
        item.profils_autorises_en_lecture.map((prof: any, i: number) => <div key={i}>{prof.text}</div>),
    },
    {
      key: "5",
      name: "Types candidature en ajout",
      fieldName: "types_candidature_autorises_en_ajout",
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) =>
        item.types_candidature_autorises_en_ajout.map((cand: any, i: number) => <div key={i}>{cand.text}</div>),
    },
    {
      key: "6",
      name: "Oblig.",
      fieldName: "obligatoire",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.obligatoire} />,
    },
    {
      key: "7",
      name: "Actions",
      fieldName: "actions",
      minWidth: 70,
      isResizable: true,
      onRender: (item: any) => (
        <Stack
          horizontal
          wrap
        >
          <CommandButton onClick={() => openDocument(item)}>Editer</CommandButton>
          <CommandButton onClick={() => actions.onRemoveDocument(item)}>Supprimer</CommandButton>
        </Stack>
      ),
    },
  ];

  return (
    <DetailsList
      layoutMode={DetailsListLayoutMode.justified}
      items={documents}
      columns={columns}
      selectionMode={SelectionMode.none}
      styles={{
        root: {
          selectors: {
            ".ms-DetailsHeader": {
              backgroundColor: "rgba(255,255,255,0)",
            },
            ".ms-DetailsRow": {
              backgroundColor: "rgba(255,255,255,0)",
            },
          },
        },
      }}
    />
  );
};

export default DocumentList;
