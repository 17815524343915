import { DefaultButton, Stack, Text } from "@fluentui/react";
import usePreviousNextCandidature from "./usePreviousNextCandidature";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import useTheme from "../../../Common/useTheme";
import { useParams } from "react-router-dom";
import useUrlParams from "../useUrlParams";

function PreviousNextCandidature() {
  const {
    actions,
    candidaturesNeedActionList,
    indiceCandidatureActual,
    isConsultCandidaturesList,
  } = usePreviousNextCandidature();
  const { primaryColor } = useTheme();
  const { id_candidature } = useUrlParams();

  return isConsultCandidaturesList ? (
    !candidaturesNeedActionList.find((c: any) => c.uuid == id_candidature) ? (
      candidaturesNeedActionList.length > 0 ? (
        <Stack horizontal horizontalAlign="end" verticalAlign="center">
          <DefaultButton
            onClick={actions.onFirstCandidature}
            style={{
              height: 42,
              padding: "0px 0px 0px 16px",
              borderColor: primaryColor,
              borderRadius: 3,
            }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              <Stack>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: primaryColor,
                  }}
                >
                  Candidature suivante
                </Text>
                <Text style={{ fontSize: 12, color: primaryColor }}>
                  {candidaturesNeedActionList[0]?.candidat.nom}{" "}
                  {candidaturesNeedActionList[0]?.candidat.prenom}
                </Text>
              </Stack>
              <MdNavigateNext size={40} color={primaryColor} />
            </Stack>
          </DefaultButton>
        </Stack>
      ) : null
    ) : candidaturesNeedActionList.length > 1 ? (
      <Stack
        horizontal
        horizontalAlign={`${
          indiceCandidatureActual == 0 ? "end" : "space-between"
        }`}
        verticalAlign="center"
      >
        {indiceCandidatureActual != 0 && (
          <DefaultButton
            onClick={actions.onPreviousCandidature}
            style={{
              height: 42,
              padding: "0px 16px 0px 0px",
              borderColor: primaryColor,
              borderRadius: 3,
            }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              <MdNavigateBefore size={40} color={primaryColor} />
              <Stack>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: primaryColor,
                  }}
                >
                  Candidature précédente
                </Text>
                <Text style={{ fontSize: 12, color: primaryColor }}>
                  {
                    candidaturesNeedActionList[indiceCandidatureActual - 1]
                      ?.candidat.nom
                  }{" "}
                  {
                    candidaturesNeedActionList[indiceCandidatureActual - 1]
                      ?.candidat.prenom
                  }
                </Text>
              </Stack>
            </Stack>
          </DefaultButton>
        )}

        {indiceCandidatureActual != candidaturesNeedActionList.length - 1 && (
          <DefaultButton
            onClick={actions.onNextCandidature}
            style={{
              height: 42,
              padding: "0px 0px 0px 16px",
              borderColor: primaryColor,
              borderRadius: 3,
            }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              <Stack>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: primaryColor,
                  }}
                >
                  Candidature suivante
                </Text>
                <Text style={{ fontSize: 12, color: primaryColor }}>
                  {
                    candidaturesNeedActionList[indiceCandidatureActual + 1]
                      ?.candidat.nom
                  }{" "}
                  {
                    candidaturesNeedActionList[indiceCandidatureActual + 1]
                      ?.candidat.prenom
                  }
                </Text>
              </Stack>
              <MdNavigateNext size={40} color={primaryColor} />
            </Stack>
          </DefaultButton>
        )}
      </Stack>
    ) : null
  ) : null;
}

export default PreviousNextCandidature;
