import { Stack } from "@fluentui/react";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import DynamicFieldForm from "../../../Common/DynamicFieldForm/DynamicFieldForm";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import AvisCandidatPositionnement from "../Common/AvisCandidatPositionnement/AvisCandidatPositionnement";
import CandidatSection from "../Common/CandidatSection";
import CertificationSection from "../Common/CertificationSection";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import useFetchCandidature from "../useFetchCandidature";
import { usePositionnement } from "./usePositionnement";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import { useAtom } from "jotai";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";

const Positionnement = () => {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const {
    isThisPageStatusBeforeCandidatureStatus,
    isReferentielStillEditable,
  } = useCandidatureWorkflow();
  const {
    candidature,
    reactHookProps,
    actions,
    conditionnalDisplayVariable,
    isSaving,
  } = usePositionnement();
  const { actions: accessActions } = useAccessSettings();

  const fetchCandidature = useFetchCandidature(reactHookProps);
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  //Si la candidature est dans un statut ultérieur à choix_blocs et que son référentiel est toujours modifiable, il faut la permission MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS pour les modifier (admin), sinon il faut juste la permission SAISIR_POSITIONNEMENT
  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, [
        "avis_formateur",
        "validation_positionnement",
      ])
      ? userAccessEnum.MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS
      : userAccessEnum.NO_ACCESS_EVEN_ADMIN //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : userAccessEnum.SAISIR_POSITIONNEMENT;
  const inputsDisabled =
    !accessActions.canI({ action: [applicableEditPermission] }).granted ||
    (!conditionnalDisplayVariable.isPositionneurThisCandidature &&
      !isAnyKindOfAdmin);
  //si on a pas les acces pour editer le positionnement ||
  //si on n'est pas le positionneur de la candidature  &&
  //si on n'est pas un administrateur

  const onSubmit = (e: any, isEnregistrerPositionnement: boolean = false) => {
    if (isEditMode) {
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data: any) => {
        const positionnementObject = {
          ...data,
          positionnement: data.blocs_competences_passes.map((b: any) => ({
            uuid: b.candidature_bloc_competence_uuid,
            decision: b.decision_positionnement,
            commentaire_positionnement: b.commentaire_positionnement,
            competences_associees: accessActions.canI({
              action: branchAccessEnum.POSITIONNEMENT_PAR_COMPETENCE,
            }).granted
              ? b.competences.map((c: any) => ({
                  uuid: c.uuid,
                  decision: c.decision_positionnement,
                }))
              : null,
          })),
        };

        mecActions.onSaveMEC(positionnementObject);
      })(e);
    } else {
      if (
        !isEnregistrerPositionnement &&
        !window.confirm(
          "Attention : veuillez vérifier que les informations et les documents ajoutés sont bien corrects : aucune modification ne pourra être réalisée"
        )
      ) {
        return false;
      }
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data) =>
        actions.onSave(data, isEnregistrerPositionnement)
      )(e);
    }
  };

  return !candidature.uuid ? null : (
    <Stack style={{ marginTop: 0 }}>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        {candidature.statut_validation_positionnement_par_candidat && (
          <Can I={userAccessEnum.VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT}>
            <AvisCandidatPositionnement
              candidatValidationStatus={
                candidature.statut_validation_positionnement_par_candidat
              }
              comment={candidature.commentaire_candidat_positionnement}
            />
          </Can>
        )}
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>
      {inputsDisabled &&
        (conditionnalDisplayVariable.isPositionneurThisCandidature ||
          isAnyKindOfAdmin) && (
          <Can I={userAccessEnum.SAISIR_POSITIONNEMENT}>
            <BoutonDemanderMEC
              statut={StatutCandidature.POSITIONNEMENT}
              statut_candidature={candidature.statut}
              onAfterValidate={fetchCandidature.refrechCandidature}
            />
          </Can>
        )}
      <form onSubmit={onSubmit}>
        <OptionsBlocsSection
          candidature={candidature}
          statutCandidature={StatutCandidature.POSITIONNEMENT}
          reactHookProps={reactHookProps}
          disabled={inputsDisabled && !isEditMode}
          onValidate={actions.onValidate}
          onInvalidate={actions.onInvalidate}
        />

        <DynamicFieldForm
          statut={StatutCandidature.POSITIONNEMENT}
          reactHookProps={reactHookProps}
          disabled={inputsDisabled && !isEditMode}
        />

        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          <Stack.Item>
            {!isOpenedInModal && <CancelButton text={"Retour"} />}
          </Stack.Item>
          <Can I={applicableEditPermission}>
            {(conditionnalDisplayVariable.isPositionneurThisCandidature ||
              isAnyKindOfAdmin) && (
              <>
                <LoadButton
                  text={"Enregistrer le positionnement"}
                  type="button"
                  onClick={(e: any) => onSubmit(e, true)}
                  isLoading={isSaving}
                />
                <LoadButton
                  text={"Valider le positionnement"}
                  isLoading={isSaving}
                />
              </>
            )}
          </Can>
          <BoutonEnregistrerMEC
            reactHookProps={reactHookProps}
            onAfterValidate={fetchCandidature.refrechCandidature}
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default Positionnement;
