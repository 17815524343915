import {
  candidaturesNeedActionListAtom,
  isConsultCandidaturesListAtom,
} from "../../CandidatJuryList/useCandidatureJuryList";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { atom } from "jotai";
import { urlIdCandidatureAtom } from "../../../hooks/candidature/useCandidatureWorkflow";

const indiceCandidatureActualAtom = atom((get: any) => {
  const candidaturesNeedActionList: any = get(candidaturesNeedActionListAtom);
  const candidatureActualUuid: any = get(urlIdCandidatureAtom);
  const candidaturesNeedActionUuid = candidaturesNeedActionList.map(
    (i: any) => i.uuid
  );
  return candidaturesNeedActionUuid.indexOf(candidatureActualUuid);
});

function usePreviousNextCandidature() {
  const [candidaturesNeedActionList] = useAtom(candidaturesNeedActionListAtom);
  const [isConsultCandidaturesList] = useAtom(isConsultCandidaturesListAtom);
  const [indiceCandidatureActual]: any = useAtom(indiceCandidatureActualAtom);

  const navigate = useNavigate();

  const actions = {
    onFirstCandidature: () => {
      navigate(
        `/admin/candidature/${candidaturesNeedActionList[0]["uuid"]}/${candidaturesNeedActionList[0]["statut"]}/true`
      );
    },
    onPreviousCandidature: () => {
      navigate(
        `/admin/candidature/${
          candidaturesNeedActionList[indiceCandidatureActual - 1]["uuid"]
        }/${
          candidaturesNeedActionList[indiceCandidatureActual - 1]["statut"]
        }/true`
      );
    },
    onNextCandidature: () => {
      navigate(
        `/admin/candidature/${
          candidaturesNeedActionList[indiceCandidatureActual + 1]["uuid"]
        }/${
          candidaturesNeedActionList[indiceCandidatureActual + 1]["statut"]
        }/true`
      );
    },
  };

  return {
    isConsultCandidaturesList,
    candidaturesNeedActionList,
    indiceCandidatureActual,
    actions,
  };
}

export default usePreviousNextCandidature;
