import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import tools from "../../utils/tools";
import {
  checkPublicUtilisateurExistsRequest,
  postSignUpRequest,
} from "../../services/publicServices";

export const SignUpContext = React.createContext({});
export const SignUpProvider = ({ value, children }: any) => {
  return (
    <SignUpContext.Provider value={value}>{children}</SignUpContext.Provider>
  );
};

const stepsInfo: any = {
  1: () => {
    return {
      title: "Demande de création de compte",
      subtitle:
        "Veuillez sélectionner la branche sur laquelle vous souhaitez créer un compte",
      labelNextButton: "Continuer ma demande d'inscription",
    };
  },
  2: () => {
    return {
      title: "Informations du compte",
      subtitle:
        "Veuillez renseigner les informations ci-dessous afin de réaliser votre demande de création de compte",
      labelNextButton: "Continuer ma demande d'inscription",
    };
  },
  3: () => {
    return {
      title: "Informations du profil",
      subtitle:
        "Veuillez identifier le profil de votre structure afin de réaliser votre demande de création de compte",
      labelNextButton: "Continuer ma demande d'inscription",
    };
  },
  4: () => {
    return {};
  },
};

const stepsConfigAtom = atom([
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
]);

const currentStepIdAtom = atom(1);
const currentStepRefAtom = atom((get) => {
  const stepsConfig = get(stepsConfigAtom);
  const currentStepId = get(currentStepIdAtom);
  return tools.findIn(stepsConfig, currentStepId, "id");
});

export const chosenBranchAtom = atom(null);
export const accountInformationsAtom = atom(null);
export const apiErrorsAtom = atom(null);

function useSignUp() {
  const [stepsConfig, setStepConfig] = useAtom(stepsConfigAtom);
  const [currentStepId, setCurrentStepId] = useAtom(currentStepIdAtom);
  const [currentStepRef, setCurrentStepRef] = useAtom(currentStepRefAtom);
  const [accountInformations, setAccountInformations]: any = useAtom(
    accountInformationsAtom
  );

  console.log("accountInformations", accountInformations);
  const [apiErrors, setApiErrors]: any = useAtom(apiErrorsAtom);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const actions = {
    onSubmitBranch: (data: any) => {
      setAccountInformations((a: any) => ({ ...a, branch_id: data.id }));
      actions.goToNextStep();
    },
    onSubmitPersonalInformations: async (data: any) => {
      setApiErrors(null);
      setIsLoading(true);
      const newAccountInformations = { ...accountInformations, ...data };
      setAccountInformations(newAccountInformations);
      const doesUserExistOnThisBranch = await actions.doesUserExistOnThisBranch(
        data
      );
      if (doesUserExistOnThisBranch) {
        if (doesUserExistOnThisBranch.response?.data?.message) {
          toast.error(doesUserExistOnThisBranch.response?.data?.message);
        } else {
          setApiErrors(
            "Un compte avec cette adresse mail existe déjà sur la branche. Merci de vous connecter à Certifi@ avec votre mot de passe. En cas d'oubli du mot de passe, merci d'utiliser la fonction Mot de passe oublié"
          );
        }
      } else {
        actions.goToNextStep();
      }
      setIsLoading(false);
    },
    onSubmitProfileInformations: (data: any) => {
      console.log("submit profile informations", data);
      const newAccountInformations = { ...accountInformations, ...data };
      setAccountInformations(newAccountInformations);
      actions.submitForm(newAccountInformations);
    },
    goToNextStep: () => {
      //   toast.info(`En cours de développement`);
      setApiErrors(null);
      if (actions.isLastStep()) {
        //reactHookProps.handleSubmit(actions.submitForm);
      } else {
        setCurrentStepId((s) => s + 1);
      }
    },
    goToPrevious: () => {
      setApiErrors(null);
      if (actions.isFirstStep()) {
        //navigate page d'accueil
        navigate("/");
      } else {
        setCurrentStepId((s) => s - 1);
      }
    },
    submitForm: async (data: any) => {
      try {
        const response = await postSignUpRequest(data);
        if (response.status < 300 && response.status > 199) {
          actions.goToNextStep();
        }
      } catch (e: any) {
        if (e?.response?.data?.message) {
          toast.error(e?.response?.data?.message);
        } else {
          toast.error("Une erreur est survenue lors de l'inscription");
        }
      }
    },
    isFirstStep: () => {
      return currentStepId == 1;
    },
    isLastStep: () => {
      return (
        stepsConfig.findIndex((item: any) => item.id == currentStepId) ==
        stepsConfig.length - 1
      );
    },
    doesUserExistOnThisBranch: async (data: any) => {
      try {
        const userExist = await checkPublicUtilisateurExistsRequest(
          accountInformations.branch_id,
          data.email
        );
        if (userExist === true) {
          return true;
        } else {
          return false;
        }
      } catch (e: any) {
        return e;
      }
    },
  };

  return {
    stepInfo: stepsInfo[currentStepId](),
    stepsConfig,
    currentStepId,
    currentStepRef,
    accountInformations,
    actions,
    isLoading,
  };
}

export default useSignUp;
