import { DefaultButton, Stack } from "@fluentui/react";
import Page from "../../Common/Page/Page";
import { commonButtonStyles } from "../../styles";
import useTheme from "../../Common/useTheme";
import useExportCaisseDesDepots from "../../hooks/useExportCaisseDesDepots";

function ExportCaisseDepotPage() {
  const { primaryColor } = useTheme();
  const { actions: actionsExport } = useExportCaisseDesDepots();

  return (
    <Stack>
      <Page
        title="Export pour la Caisse des dépôts"
        explain="En tant que certificateur, vous êtes dans l’obligation de transmettre les données des personnes, que vous avez certifiées sur vos certifications inscrites au RNCP ou au RS, dans un délai de 3 mois maximum suite à leur certification. Le fichier proposé par Certifi@ est au format attendu par la Caisse des dépôts et ne contient que vos candidats certifiés sur vos certifications inscrites au RNCP ou au RS."
      >
        <Stack horizontal wrap verticalAlign="center" style={{ marginTop: 25 }}>
          <DefaultButton
            type="button"
            text="Exporter pour la Caisse des Dépôts"
            styles={commonButtonStyles.defaultButton}
            style={{ color: primaryColor, borderColor: primaryColor }}
            onClick={actionsExport.openExportCaisseDesDepot}
          />
        </Stack>
      </Page>
    </Stack>
  );
}

export default ExportCaisseDepotPage;
