import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import DynamicFieldForm from "../../../Common/DynamicFieldForm/DynamicFieldForm";
import FormSection from "../../../Common/FormSection/FormSection";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import useValidationEvaluation from "./useValidationEvaluation";
import { commonInputStyles } from "../../../styles";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import useFetchCandidature from "../useFetchCandidature";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import { userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import InfoMessage from "../../../Common/SelectField/common/InfoMessage/InfoMessage";

function ValidationEvaluation() {
  const { isThisPageStatusBeforeCandidatureStatus } = useCandidatureWorkflow();
  const { isCandidat, isSaving, candidature, reactHookProps, actions } =
    useValidationEvaluation();
  const { actions: accessActions } = useAccessSettings();
  const { isAdmin } = useInfoProfilUser();
  const { labels } = useLabelSettings();

  const fetchCandidature = useFetchCandidature(reactHookProps);

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? userAccessEnum.NO_ACCESS_EVEN_ADMIN
    : userAccessEnum.VALIDER_EVALUATION;

  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  const onSubmit = (e: any) => {
    reactHookProps.clearErrors();
    reactHookProps.handleSubmit((data: any) => actions.onSubmit(data))(e);
  };

  console.log("inputsDisabled", inputsDisabled);
  console.log("isCandidat", isCandidat);

  return !candidature.uuid ? null : (
    <Stack style={{ marginTop: 0 }}>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>

      <OptionsBlocsSection
        candidature={candidature}
        statutCandidature={StatutCandidature.EVALUATION}
        reactHookProps={reactHookProps}
        disabled={true}
      />

      <DynamicFieldForm
        statut={StatutCandidature.EVALUATION}
        reactHookProps={reactHookProps}
        disabled={true}
      />

      {reactHookProps.watch("second_evaluateur") && (
        <>
          <FormSection sectionTitle={labels.AVIS_SECOND_EVALUATEUR_TITRE}>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Avis du second évaluateur (3000 caractères max) :"
                  placeholder="Ex : commentaire"
                  multiline
                  required
                  rows={6}
                  maxLength={3000}
                  {...field}
                  disabled={true}
                />
              )}
              control={reactHookProps.control}
              name="avis"
            />
          </FormSection>

          <DynamicFieldForm
            statut={StatutCandidature.AVIS_SECOND_EVALUATEUR}
            reactHookProps={reactHookProps}
            disabled={true}
          />
        </>
      )}

      {!inputsDisabled && (
        <>
          <Stack horizontal horizontalAlign="end" style={{ marginTop: 20 }}>
            {isCandidat && (
              <LoadButton
                text={"J’ai pris connaissance de mon évaluation"}
                isLoading={isSaving}
                type="button"
                onClick={onSubmit}
              />
            )}
            {isAdmin && (
              <LoadButton
                text={"Passer cette étape"}
                isLoading={isSaving}
                type="button"
                onClick={onSubmit}
              />
            )}
          </Stack>
          {isCandidat && (
            <Stack>
              <InfoMessage
                infoMessage={
                  "ATTENTION : Sans cette action de votre part, votre candidature ne pourra pas être examinée en jury. Pour rappel, seul le jury décide ou non de l’obtention de la certification."
                }
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}

export default ValidationEvaluation;
