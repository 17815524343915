import { Text, PrimaryButton } from "@fluentui/react";
import { commonButtonStyles } from "../../../styles/index";
import useCustomModal from "../CustomModal/useCustomModal";

function DeleteConfirmDialog({ libelle, deleteFunction = () => { } }: any) {
    const { actions: { closeModal } } = useCustomModal()

    const onClickDeleteButton = () => {
        deleteFunction()
        closeModal()
    }

    return (
        <>
            <Text variant="medium" style={{ margin: "10px 0px", textAlign: "center" }}>
                Voulez-vous vraiment supprimer cette entité ? <br /> <strong>{libelle}</strong>
            </Text>
            <PrimaryButton
                text="Supprimer"
                onClick={onClickDeleteButton}
                style={{ width: "200px", margin: "10px 0px" }}
                styles={commonButtonStyles.deleteButton}
            />
        </>
    )
}

export default DeleteConfirmDialog