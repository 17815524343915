import { ActionButton, Label, Stack } from "@fluentui/react";
import { RiAddFill } from "react-icons/ri";
import tools from "../../utils/tools";
import CancelButton from "../CancelButton/CancelButton";
import FileInput from "../FileInput/FileInput";
import LoadButton from "../LoadButton/LoadButton";
import Loader from "../Loader/Loader";
import useCustomModal from "../Overlays/CustomModal/useCustomModal";
import SectionTitle from "../SectionTitle/SectionTitle";
import useTheme from "../useTheme";
import ValidationResults from "./ValidationResults";
import useImportEntities, { entityConfig } from "./useImportEntities";

const ImportEntities = ({ entityType, isAsync = false }: any) => {
  const { actions: modalActions } = useCustomModal();
  const {
    fileToImport,
    validationResult,
    isLoading,
    confirmationResult,
    actions,
    isDownloadingTemplate,
  } = useImportEntities(entityType);
  const { primaryColor } = useTheme();

  if (!entityType) return <p>Aucun type d'entité sélectionné</p>;
  const entityRef: any = tools.findIn(entityConfig, entityType, "type");

  if (confirmationResult && isAsync) {
    return (
      <Stack tokens={{ childrenGap: 20 }}>
        <p>
          L'importation de vos données est en cours et prendra du temps. Vous
          obtiendrez le résultat de l'importation par email une fois que
          celle-ci sera terminée.
        </p>
      </Stack>
    );
  }

  if (confirmationResult) {
    return (
      <Stack tokens={{ childrenGap: 20 }}>
        <SectionTitle title={entityRef.title} style={{ marginTop: 0 }} />

        <Stack.Item>
          <ValidationResults
            results={confirmationResult}
            validLabel="Entités importées"
            invalidLabel="Entités non importées"
          />
        </Stack.Item>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          <CancelButton onClick={modalActions.closeModal} text="Fermer" />
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Stack tokens={{ childrenGap: 20 }}>
        <SectionTitle title={entityRef.title} style={{ marginTop: 0 }} />

        <Stack.Item>
          <Label>1 - Téléchargez le modèle d'import à compléter :</Label>
          {isDownloadingTemplate ? (
            <Loader />
          ) : (
            <ActionButton
              type="button"
              iconProps={{ iconName: "CloudDownload" }}
              text={`${entityType}.xlsx`}
              onClick={actions.onDownloadTemplate}
              style={{ color: primaryColor }}
            />
          )}
        </Stack.Item>
        <Stack.Item>
          <Label>2 - Déposez le modèle complété avec vos données :</Label>
          {isLoading ? (
            <Loader />
          ) : (
            <FileInput
              onUpdateFile={actions.onUpdateFile}
              onDeleteFile={actions.onDeleteFile}
              file={fileToImport}
              acceptedFileTypes={["EXCEL"]}
            />
          )}
        </Stack.Item>
      </Stack>
      {validationResult && (
        <ValidationResults
          results={validationResult}
          validLabel="Entitées valides et prêtes à être importées"
          invalidLabel="Entitées invalides qui ne seront pas importées"
        />
      )}
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{ marginTop: 20 }}
      >
        <CancelButton
          onClick={actions.onCancelImport}
          text="Annuler l'import de ce fichier"
        />
        {validationResult?.import_valides.length > 0 && (
          <LoadButton
            text={`Importer ${validationResult?.import_valides.length}/${
              validationResult?.import_valides.length +
              validationResult?.import_invalides.length
            } entités valides`}
            onClick={actions.onConfirmImport}
            isLoading={isLoading}
            icon={<RiAddFill size={38} />}
            style={{ fontWeight: "bold" }}
          />
        )}
      </Stack>
    </>
  );
};

export default ImportEntities;
