import ChoixBlocs from "../../Pages/CandidatureWorkflow/ChoixBlocs/ChoixBlocs";
import CreationCandidature from "../../Pages/CandidatureWorkflow/CreationCandidature/CreationCandidature";
import Evaluation from "../../Pages/CandidatureWorkflow/Evaluation/Evaluation";
import ParcoursCandidat from "../../Pages/CandidatureWorkflow/ParcoursCandidat/ParcoursCandidat";
import PartiesPrenantes from "../../Pages/CandidatureWorkflow/PartiesPrenantes/PartiesPrenantes";
import Positionnement from "../../Pages/CandidatureWorkflow/Positionnement/Positionnement";
import SaiseInformations from "../../Pages/CandidatureWorkflow/SaisieInformations/SaisieInformations";
import TransmissionDossier from "../../Pages/CandidatureWorkflow/TransmissionDossier/TransmissionDossier";
import ValidationPositionnement from "../../Pages/CandidatureWorkflow/ValidationPositionnement/ValidationPositionnement";
import tools from "../../utils/tools";
import useConstants from "../useConstants";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import AvisFormateur from "../../Pages/CandidatureWorkflow/AvisFormateur/AvisFormateur";
import DecisionJury from "../../Pages/CandidatureWorkflow/DecisionJury/DecisionJury";
import InscriptionJury from "../../Pages/CandidatureWorkflow/InscriptionJury/InscriptionJury";
import ResultatsCandidatureWrapper from "../../Pages/CandidatureWorkflow/ResultatsCandidature/ResultatsCandidatureWrapper";
import ValidationAvisFormateur from "../../Pages/CandidatureWorkflow/ValidationAvisFormateur/ValidationAvisFormateur";
import ValidationEvaluation from "../../Pages/CandidatureWorkflow/ValidationEvaluation/ValidationEvaluation";
import useUrlParams from "../../Pages/CandidatureWorkflow/useUrlParams";
import useLabelSettings from "../../Pages/LabelSettingsDetail/useLabelSettings";
import {
  branchAccessEnum,
  permissionsByCandidatureStatus,
} from "../../config/accessEnum";
import useAccessSettings from "../branchSettings/useAccessSettings";
import { candidatureAtom } from "./useCandidature";

export const pagesByStatus: any = {
  creation_candidature: <CreationCandidature />,
  saisie_informations: <SaiseInformations />,
  choix_blocs: <ChoixBlocs />,
  saisie_parcours: <ParcoursCandidat />,
  identification_parties_prenantes: <PartiesPrenantes />,
  transmission_dossier: <TransmissionDossier />,
  positionnement: <Positionnement />,
  validation_positionnement: <ValidationPositionnement />,
  avis_formateur: <AvisFormateur />,
  valider_avis_formateur: <ValidationAvisFormateur />,
  evaluation: <Evaluation />,
  valider_evaluation: <ValidationEvaluation />,
  //avis_second_evaluateur: <SecondEvaluation />,
  inscription_jury: <InscriptionJury />,
  decision_jury: <DecisionJury />,
  resultats_jury: <ResultatsCandidatureWrapper />,
};

export enum StatutCandidature {
  CREATION_CANDIDATURE = "creation_candidature",
  VALIDATION_DOSSIER_VAE = "validation_vae",
  POSITIONNEMENT = "positionnement",
  VALIDATION_POSITIONNEMENT = "validation_positionnement",
  AVIS_FORMATEUR = "avis_formateur",
  VALIDER_AVIS_FORMATEUR = "valider_avis_formateur",
  EVALUATION = "evaluation",
  AVIS_SECOND_EVALUATEUR = "avis_second_evaluateur",
  VALIDER_EVALUATION = "valider_evaluation",
  INSCRIPTION_JURY = "inscription_jury",
  VALIDATION_INSCRIPTION_JURY = "validation_inscription_jury",
  DECISION_JURY = "decision_jury",
  RESULTATS_JURY = "resultats_jury",
}

export const StatutDecisionMembre = [
  { id: "FAVORABLE", key: "FAVORABLE", text: "Favorable" },
  { id: "DEFAVORABLE", key: "DEFAVORABLE", text: "Défavorable" },
  { id: "SANS_AVIS", key: "SANS_AVIS", text: "Sans avis" },
];

export const ETAPES_CANDIDATURE_CONFIG = [
  {
    name: "Création de la candidature",
    nameType: "CANDIDATURE_SLIDER_ETAPE_CREATION",
    statuses: [
      {
        id: "creation_candidature",
        action: "Créer la candidature",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_CREATIONCANDIDATURE",
        pageNameType: "CANDIDATURE_CREE_TITRE",
        pageSubNameType: "CANDIDATURE_CREE_SOUS_TITRE",
      },
      {
        id: "saisie_informations",
        action: "Saisir les informations",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_SAISIEINFORMATIONS",
      },
      { id: "saisie_parcours", action: "Saisir le parcours" },
      {
        id: "choix_blocs",
        action: "Choisir les blocs",
        nameType: "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_CHOIXDESBLOCS",
      },
      {
        id: "identification_parties_prenantes",
        action: "Identifier les parties prenantes",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_CREATION_SOUSETAPE_IDENTIFICATIONPARTIEPRENANTES",
        pageNameType: "PARTIE_PRENANTE_TITRE",
        pageSubNameType: "PARTIE_PRENANTE_SOUS_TITRE",
      },
      { id: "transmission_dossier", action: "Transmettre le dossier" },
    ],
  },
  {
    name: "Validation VAE",
    statuses: [{ id: "validation_vae", action: "Validation VAE" }],
  },
  {
    name: "Positionnement du candidat",
    nameType: "CANDIDATURE_SLIDER_ETAPE_POSITIONNEMENT",
    statuses: [
      {
        id: "positionnement",
        action: "Positionner",
        actionType: "CANDIDATURES_BTN_POSITIONNER",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_POSITIONNEMENT_SOUSETAPE_POSITIONNEMENT",
        pageNameType: "POSITIONNEMENT_TITRE",
        pageSubNameType: "POSITIONNEMENT_SOUS_TITRE",
        ficheType: "FICHE_INDIVIDUELLE_CANDIDATURE_POSITIONNER",
      },
      {
        id: "validation_positionnement",
        action: "Valider le positionnement",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_POSITIONNEMENT_SOUSETAPE_VALIDATIONPOSITIONNEMENT",
        ficheType: "FICHE_INDIVIDUELLE_CANDIDATURE_VALIDATION_POSITIONNEMENT",
      },
    ],
  },

  {
    name: "Avis formateur",
    statuses: [
      {
        id: "avis_formateur",
        action: "Donner son avis formateur",
        actionType: "CANDIDATURES_BTN_DONNER_SON_AVIS_FORMATEUR",
        ficheType: "FICHE_INDIVIDUELLE_CANDIDATURE_DONNER_SON_AVIS_FORMATEUR",
      },
      {
        id: "valider_avis_formateur",
        action: "Valider avis formateur",
        //text: "Validation avis formateur",
      },
    ],
  },
  {
    name: "Évaluation du candidat",
    nameType: "CANDIDATURE_SLIDER_ETAPE_EVALUATION",
    statuses: [
      {
        id: "evaluation",
        action: "Evaluer",
        actionType: "CANDIDATURES_BTN_EVALUER",
        nameType: "CANDIDATURE_SLIDER_ETAPE_EVALUATION_SOUSETAPE_EVALUATION",
        pageNameType: "EVALUATION_TITRE",
        pageSubNameType: "EVALUATION_SOUS_TITRE",
        ficheType: "FICHE_INDIVIDUELLE_CANDIDATURE_EVALUER",
      },
      {
        id: "avis_second_evaluateur",
        action: "Laisser une seconde évaluation",
        actionType: "CANDIDATURES_BTN_DONNER_SON_AVIS_EVALUATEUR",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_EVALUATION_SOUSETAPE_AVISSECONDEVALUATEUR",
        pageNameType: "INVITATIONAVIS_EVALUATEUR_TITRE",
        pageSubNameType: "INVITATIONAVIS_EVALUATEUR_SOUS_TITRE",
        ficheType: "FICHE_INDIVIDUELLE_CANDIDATURE_DONNER_SON_AVIS_EVALUATEUR",
      },
      {
        id: "valider_evaluation",
        action: "Valider évaluation",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_EVALUATION_SOUSETAPE_VALIDATIONEVALUATION",
        ficheType: "FICHE_INDIVIDUELLE_CANDIDATURE_VALIDER_EVALUATION",
        //text: "Validation avis formateur",
      },
    ],
  },
  {
    name: "Jury et certification du candidat",
    nameType: "CANDIDATURE_SLIDER_ETAPE_JURY_CERTIFICATIONCANDIDAT",
    statuses: [
      {
        id: "inscription_jury",
        action: "Inscrire au jury",
        actionType: "CANDIDATURES_BTN_INSCRIRE_AU_JURY",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_JURY_CERTIFICATIONCANDIDAT_SOUSETAPE_INSCRIPTIONJURY",
        ficheType: "FICHE_INDIVIDUELLE_CANDIDATURE_INSCRIR_AU_JURY",
      },
      {
        id: "decision_jury",
        action: "Renseigner une décision",
        nameType:
          "CANDIDATURE_SLIDER_ETAPE_JURY_CERTIFICATIONCANDIDAT_SOUSETAPE_DECISIONDUJURY",
        pageNameType: "DEROULEMENT_JURY_JURY_TITRE",
        pageSubNameType: "DEROULEMENT_JURY_JURY_SOUS_TITRE",
      },
      { id: "resultats_jury", action: "Consulter les résultats du jury" },
    ],
  },
];

export const ETAPES_CANDIDATURE_CONFIG_FILTER = [
  {
    id: "creation_candidature_etape",
    canBeAddedWithOtherStatuts: true,
    key: "creation_candidature_etape",
    text: "Création de la candidature",
    statuses: [
      { key: "creation_candidature" },
      { key: "saisie_informations" },
      { key: "saisie_parcours" },
      { key: "choix_blocs" },
      { key: "identification_parties_prenantes" },
      { key: "transmission_dossier" },
    ],
  },
  // {
  //   key: "validation_vae_etape",
  //   canBeAddedWithOtherStatuts:true,
  //   text: "Validation VAE",
  //   statuses: [
  //     { key: "validation_vae" }
  //   ],
  // },
  {
    id: "positionnement_etape",
    canBeAddedWithOtherStatuts: true,
    key: "positionnement_etape",
    text: "Positionnement",
    statuses: [
      { key: "positionnement" },
      //  { key: "validation_positionnement" },
    ],
  },
  {
    id: "avis_formateur_etape",
    canBeAddedWithOtherStatuts: true,
    key: "avis_formateur_etape",
    text: "Avis formateur",
    statuses: [{ key: "avis_formateur" }, { key: "valider_avis_formateur" }],
  },
  {
    id: "evaluation_etape",
    canBeAddedWithOtherStatuts: true,
    key: "evaluation_etape",
    text: "Évaluation",
    statuses: [{ key: "evaluation" }, { key: "valider_evaluation" }],
  },
  {
    id: "inscription_jury_etape",
    canBeAddedWithOtherStatuts: true,
    key: "inscription_jury_etape",
    text: "Inscription du jury",
    statuses: [{ key: "inscription_jury" }],
  },
  {
    id: "decision_jury_etape",
    canBeAddedWithOtherStatuts: true,
    key: "decision_jury_etape",
    text: "Décision du jury",
    statuses: [{ key: "decision_jury" }],
  },
  {
    id: "resultats_jury_valide_etape",
    canBeAddedWithOtherStatuts: false,
    key: "resultats_jury_valide_etape",
    text: "Validé",
    statuses: [{ key: "resultats_jury" }],
    resultat: "VALIDE",
  },
  {
    id: "resultats_jury_valide_partiellement_etape",
    canBeAddedWithOtherStatuts: false,
    key: "resultats_jury_valide_partiellement_etape",
    text: "Validé partiellement",
    statuses: [{ key: "resultats_jury" }],
    resultat: "VALIDE_PARTIELLEMENT",
  },
  {
    id: "resultats_jury_non_valide_etape",
    canBeAddedWithOtherStatuts: false,
    key: "resultats_jury_non_valide_etape",
    text: "Non validé",
    statuses: [{ key: "resultats_jury" }],
    resultat: "NON_VALIDE",
  },
];

const postDisabledCandidatureStatusesAtom = atom([]);
export const urlIdCandidatureAtom = atom(null);

const useCandidatureWorkflow = () => {
  const [candidature]: any = useAtom(candidatureAtom);
  const [urlIdCandidature, setUrlIdCandidature]: any =
    useAtom(urlIdCandidatureAtom);
  const { id_candidature, statut_candidature } = useUrlParams();
  const { labels } = useLabelSettings();
  const { constants } = useConstants();
  const { actions: accessActions, roleSettings } = useAccessSettings();

  useEffect(() => {
    if (id_candidature !== urlIdCandidature) {
      setUrlIdCandidature(id_candidature);
    }
  }, [id_candidature]);

  const pageStatus = statut_candidature;
  const [
    postDisabledCandidatureStatuses,
    setPostDisabledCandidatureStatuses,
  ]: any = useAtom(postDisabledCandidatureStatusesAtom);
  let disabledCandidatureStatuses: any = [
    "validation_vae",
    "validation_positionnement",
    "validation_evaluation",
    "avis_second_evaluateur",
  ];
  if (id_candidature && statut_candidature) {
    disabledCandidatureStatuses = [
      ...disabledCandidatureStatuses,
      ...postDisabledCandidatureStatuses,
    ];
  }

  if (
    !accessActions.canI({ action: branchAccessEnum.AFFICHER_PARCOURS_CANDIDAT })
      .granted
  ) {
    disabledCandidatureStatuses.push("saisie_parcours");
  }

  if (candidature.uuid) {
    if (
      roleSettings.filter(
        (a: any) =>
          a.est_parametrable &&
          a.est_accessible &&
          a.acces == "CREER_AVIS_FORMATEUR"
      ).length == 0 ||
      candidature?.certification?.est_acces_et_processus_limites
    ) {
      disabledCandidatureStatuses.push("avis_formateur");
      disabledCandidatureStatuses.push("valider_avis_formateur");
    }
  }

  if (
    !accessActions.canI({ action: branchAccessEnum.ACTIVER_AVIS_FORMATEUR })
      .granted ||
    !candidature?.of_lie?.raison_sociale
  ) {
    disabledCandidatureStatuses.push("avis_formateur");
    disabledCandidatureStatuses.push("valider_avis_formateur");
  }

  if (
    !accessActions.canI({
      action: branchAccessEnum.INVITER_CANDIDAT_A_L_INSCRIPTION,
    }).granted ||
    !accessActions.canI({
      action: branchAccessEnum.ACTIVER_VALIDATION_AVIS_FORMATEUR,
    }).granted
  ) {
    disabledCandidatureStatuses.push("valider_avis_formateur");
  }
  //si personne n'a le droit de saisir le positionnement sur la branche (à part admin) on désactive l'étape
  if (
    roleSettings.filter(
      (a: any) =>
        a.est_parametrable &&
        a.est_accessible &&
        a.acces == "SAISIR_POSITIONNEMENT"
    ).length == 0 &&
    !accessActions.canI({
      action: branchAccessEnum.OBLIGER_POSITIONNEMENT,
    }).granted &&
    !accessActions.canI({
      action:
        branchAccessEnum.AUTORISER_CREATEUR_CANDIDATURE_DEPOSER_POSITIONNEMENT,
    }).granted
  ) {
    disabledCandidatureStatuses.push("positionnement");
  }

  if (
    !accessActions.canI({
      action: branchAccessEnum.INVITER_CANDIDAT_A_L_INSCRIPTION,
    }).granted ||
    !accessActions.canI({
      action: branchAccessEnum.ACTIVER_PRISE_CONNAISSANCE_EVALUATION,
    }).granted ||
    candidature?.certification?.est_acces_et_processus_limites
  ) {
    disabledCandidatureStatuses.push("valider_evaluation");
  }

  /*
   * On retire de la config les étapes désactivées (par la branche) et celles pour lesquelles l'utilisateur n'a aucune permission
   */
  const ETAPES_CANDIDATURE = ETAPES_CANDIDATURE_CONFIG.map((etape: any) => {
    return {
      ...etape,
      name: etape.nameType ? labels[etape.nameType] : etape.name,
      statuses: etape.statuses.filter((status: any) => {
        return (
          !disabledCandidatureStatuses.includes(status.id) &&
          accessActions.canI({
            action: permissionsByCandidatureStatus[status.id],
          }).granted
        );
      }),
    };
  }).filter((etape: any) => etape.statuses.length > 0);
  const STATUTS_CANDIDATURE = constants.CANDIDATURES.STATUTS.filter(
    (status: any) => !disabledCandidatureStatuses.includes(status.id)
  ).map((status: any, index: number) => {
    const stepRef = ETAPES_CANDIDATURE.find((etape) =>
      etape.statuses.find((d: any) => d.id == status.id)
    );
    let actionRef = stepRef
      ? stepRef.statuses.find((d: any) => d.id == status.id)
      : {};
    if (actionRef.actionType) {
      actionRef = { action: labels[actionRef.actionType] };
    }
    return {
      ...status,
      text: actionRef.nameType ? labels[actionRef.nameType] : status.text,
      ficheText: actionRef.ficheType
        ? labels[actionRef.ficheType]
        : actionRef.action,
      stepName: stepRef?.name || "",
      pageName: actionRef?.pageNameType ? labels[actionRef.pageNameType] : "",
      pageSubName: actionRef?.pageSubNameType
        ? labels[actionRef.pageSubNameType]
        : "",
      actionName: actionRef.action,
      order: index,
      url: `/admin/candidature/${id_candidature}/${status.id}`,
    };
  });

  const ETAPES = ETAPES_CANDIDATURE.map((etape) => {
    return {
      ...etape,
      statuses: etape.statuses.map((et: any) =>
        tools.findIn(STATUTS_CANDIDATURE, et.id, "id")
      ),
    };
  });
  const STATUT_PAGE: any = tools.findIn(STATUTS_CANDIDATURE, pageStatus, "id");
  let STATUTS_ETAPE_PAGE: any = ETAPES_CANDIDATURE.find((etape) =>
    etape.statuses.includes(STATUT_PAGE.id)
  );
  STATUTS_ETAPE_PAGE = {
    ...STATUTS_ETAPE_PAGE,
    statuses: STATUTS_ETAPE_PAGE?.statuses.map(
      (status: any) =>
        STATUTS_CANDIDATURE.find((st: any) => st.id == status) || []
    ),
  };

  //retourne la prochaine étape après le statut passé en paramètre selon la configuration du workflow sur la branche
  const getNextStepAfterStatus = (status: any) => {
    const nextStatus = STATUTS_CANDIDATURE.findIndex(
      (d: any) => d.id == status
    );
    if (nextStatus == -1) return false;
    return STATUTS_CANDIDATURE[
      STATUTS_CANDIDATURE.findIndex((d: any) => d.id == status) + 1
    ];
  };

  //Permet de savoir si la page du workflow consultée correspond à une étape antérieure au statut actuel de la candidature
  const isThisPageStatusBeforeCandidatureStatus = (status: any) => {
    return (
      (tools.findIn(STATUTS_CANDIDATURE, status, "id") as any).order >
      STATUT_PAGE.order
    );
  };

  const isFirstStatutBeforeOrEqualSecondStatus = (
    firstStatus: any,
    secondStatus: any
  ) => {
    return (
      (tools.findIn(STATUTS_CANDIDATURE, firstStatus, "id") as any).order <=
      (tools.findIn(STATUTS_CANDIDATURE, secondStatus, "id") as any).order
    );
  };

  const updateDisabledStatuses = (status: any, isDisabled: boolean) => {
    setPostDisabledCandidatureStatuses((p: any) => {
      if (isDisabled && !p.includes(status)) {
        return [...p, status];
      }
      if (!isDisabled) {
        return p.filter((s: any) => s !== status);
      }
      return p;
    });
  };

  //Permet de savoir si la candidature est dans le statut juste après la transmission du dossier, pour définir si le référentiel (choix des blocs, parties prenantes etc...) peuvent encore être modifié par un utilisateur possédant les droits
  const isReferentielStillEditable = (
    candidatureStatus: any,
    statusLimitingEdition: any
  ) => {
    let nextStatus: any = { order: 0 };
    for (var key in statusLimitingEdition) {
      let status = getNextStepAfterStatus(statusLimitingEdition[key]);
      if (status) {
        nextStatus = status;
        break;
      }
    }
    return (
      (tools.findIn(STATUTS_CANDIDATURE, candidatureStatus, "id") as any)
        .order <= nextStatus.order
    );
  };

  return {
    STATUTS_CANDIDATURE,
    ETAPES,
    ETAPES_CANDIDATURE,
    pageStatus,
    STATUT_PAGE,
    STATUTS_ETAPE_PAGE,
    statut_candidature,
    isThisPageStatusBeforeCandidatureStatus,
    isReferentielStillEditable,
    isFirstStatutBeforeOrEqualSecondStatus,
    updateDisabledStatuses,
  };
};

export default useCandidatureWorkflow;
