import { atom, useAtom } from "jotai";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { branchesAtom } from "../../atoms/constantsAtom";
import { getCandidaturesRequest } from "../../services/candidatureService";
import useCandidatureWorkflow from "../../hooks/candidature/useCandidatureWorkflow";
import { getJuryAvailableForCandidature } from "../../services/juryServices";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import ModificationCandidaturesJuryModal from "../../Common/InscriptionJuryModal/ModificationCandidatureJuryModal";
import {
  getHabilitationOfRequest,
  getHabilitationsUtilisateurDetailRequest,
} from "../../services/habilitationServices";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import { branchAccessEnum } from "../../config/accessEnum";
import tools from "../../utils/tools";
import useAuth from "../../hooks/useAuth";
import DemandeHabilitationModal from "./DemandeHabilitationModal/DemandeHabilitationModal";

const filtersAtom = atom<any>({
  en_attente_de_ma_part: false,
  statuts: [
    { key: "saisie_informations" },
    { key: "saisie_parcours" },
    { key: "choix_blocs" },
    { key: "identification_parties_prenantes" },
    { key: "transmission_dossier" },
    { key: "positionnement" },
    { key: "evaluation" },
    { key: "inscription_jury" },
    { key: "decision_jury" },
  ],
});

const optionsFiltersAtom = atom({
  sort: "",
  page: 1,
  paginate: false,
  searchAt: "",
});

const habilitationOptionsFiltersAtom = atom({
  sort: "-date_fin",
  page: 1,
  paginate: true,
  searchAt: "",
});

export function useDashboard() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [branches] = useAtom<any>(branchesAtom);
  const branchRef: any = branches.find(
    (e: any) => e.id == userSelectedBranch.id
  );
  const [filters] = useAtom(filtersAtom);
  const [optionsFilters] = useAtom(optionsFiltersAtom);
  const [habilitationOptionsFilters, setHabilitationOptionsFilters] = useAtom(
    habilitationOptionsFiltersAtom
  );

  const { auth } = useAuth();

  const { ETAPES } = useCandidatureWorkflow();
  const { actions: modalActions } = useCustomModal();
  const { actions: accessActions } = useAccessSettings();

  const {
    data: candidatures,
    isLoading: isLoadingCandidatures,
    refetch,
  } = useQuery(
    ["candidatures", optionsFilters, userSelectedBranch],
    () => getCandidaturesRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const { data: juryList, isLoading: isLoadingJuryList } = useQuery(
    ["jurys", {}],
    () => getJuryAvailableForCandidature({}),
    { retry: 0 }
  );

  const { data: habilitationsOF, isLoading: isLoadingHabilitationsOF } =
    useQuery(
      ["habilitationsOF", userSelectedBranch, habilitationOptionsFilters],
      () => getHabilitationOfRequest({}, habilitationOptionsFilters),
      {
        retry: 0,
        enabled: accessActions.canI({
          action: branchAccessEnum.HABILITATION_SIMPLE_OF,
        }).granted,
      }
    );
  const {
    data: habilitationsEvaluateur,
    isLoading: isLoadingHabilitationsEvaluateur,
  } = useQuery(
    ["habilitationsEvaluateur", userSelectedBranch, habilitationOptionsFilters],
    () =>
      getHabilitationsUtilisateurDetailRequest(
        { evaluateur: auth.user.uuid },
        habilitationOptionsFilters
      ),
    {
      retry: 0,
      enabled: accessActions.canI({
        action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
      }).granted,
    }
  );
  const {
    data: habilitationsPositionneur,
    isLoading: isLoadingHabilitationsPositionneur,
  } = useQuery(
    [
      "habilitationsPositionneur",
      userSelectedBranch,
      habilitationOptionsFilters,
    ],
    () =>
      getHabilitationsUtilisateurDetailRequest(
        { positionneur: auth.user.uuid },
        habilitationOptionsFilters
      ),
    {
      retry: 0,
      enabled: accessActions.canI({
        action: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
      }).granted,
    }
  );

  const branchInformations = {
    nom: userSelectedBranch?.nom,
    description: branchRef?.description,
    hero: branchRef?.couleur_primaire,
    logo: branchRef?.logo_url,
    headband: branchRef?.headband_url,
  };

  const creationStatuses = ETAPES.find(
    (e: any) => e.nameType == "CANDIDATURE_SLIDER_ETAPE_CREATION"
  ).statuses.map((s: any) => s.id);
  const candidatureCreationItems =
    candidatures?.data?.filter((c: any) =>
      creationStatuses.includes(c.statut)
    ) || [];
  const candidaturePositionnementItems = candidatures?.data?.filter(
    (c: any) => c.statut == "positionnement"
  );
  const candidatureEvaluationItems = candidatures?.data?.filter(
    (c: any) => c.statut == "evaluation"
  );

  const inscriptionsJuryItems = candidatures?.data?.filter(
    (c: any) => c.statut == "inscription_jury"
  );

  const inscriptionsWithoutEnAttenteValidationJuryItems =
    candidatures?.data?.filter(
      (c: any) => c.statut == "inscription_jury" && !c.jury
    );

  const decisionJuryItems = candidatures?.data?.filter(
    (c: any) => c.statut == "decision_jury"
  );

  const actions = {
    inscriptionCandidaturesJury: () => {
      modalActions.openModal({
        header: "Inscrire mes candidatures au jury",
        body: (
          <ModificationCandidaturesJuryModal
            candidatures={inscriptionsWithoutEnAttenteValidationJuryItems}
            onSaved={(jury: any) => {
              refetch();
            }}
            isModification={false}
          />
        ),
        fullWidth: true,
      });
    },
    changePage: (page: number) => {
      actions.onChangeHabilitationOptionFilters({
        ...habilitationOptionsFilters,
        page,
      });
    },
    onChangeHabilitationOptionFilters: (newOptionsFilters: any) => {
      setHabilitationOptionsFilters(newOptionsFilters);
    },
    sortColumn: (name: string) => {
      const sortArray = habilitationOptionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeHabilitationOptionFilters({
        ...habilitationOptionsFilters,
        sort: sortJoin,
      });
    },
    onOpenDemandeHabilitationModal: (
      entityOrProfil: "OF" | "EVALUATEUR" | "POSITIONNEUR"
    ) => {
      modalActions.openModal({
        header: "Demander une habilitation",
        body: <DemandeHabilitationModal entityOrProfil={entityOrProfil} />,
        fullWidth: true,
      });
    },
  };

  return {
    habilitationsOF,
    habilitationsEvaluateur,
    habilitationsPositionneur,
    habilitationOptionsFilters,
    isLoadingHabilitationsOF,
    candidatureCreationItems,
    candidaturePositionnementItems,
    candidatureEvaluationItems,
    inscriptionsJuryItems,
    decisionJuryItems,
    branchInformations,
    creationStatuses,
    juryList,
    actions,
    isLoadingCandidatures,
  };
}
