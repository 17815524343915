import { BiChevronRight } from "react-icons/bi";
import Page from "../../Common/Page/Page";
import SliderCustom from "../../Common/SliderCustom/SliderCustom";
import useCandidature from "../../hooks/candidature/useCandidature";
import useCandidatureWorkflow, {
  pagesByStatus,
} from "../../hooks/candidature/useCandidatureWorkflow";
import CandidatureStatus from "./Common/CandidatureStatus";
import PreviousNextCandidature from "./PreviousNextCandidature/PreviousNextCandidature";
import ListeMECS from "./Common/MiseEnConformite/ListeMECS";

import useMiseEnConfirmite from "./Common/MiseEnConformite/useMiseEnConfirmite";
import WorkflowWrapper from "./WorkflowWrapper";

const WorkflowContent = () => {
  const { STATUTS_CANDIDATURE, ETAPES, STATUT_PAGE, statut_candidature }: any =
    useCandidatureWorkflow();
  const { candidature } = useCandidature();
  const { mecAsked, activeMecs, activeMecsAsked } = useMiseEnConfirmite();

  return (
    <WorkflowWrapper>
      <Page
        title={
          <div className="d-center">
            {STATUT_PAGE.pageName || STATUT_PAGE.stepName} <BiChevronRight />
            <span style={{ fontSize: 16, color: "grey" }}>
              {STATUT_PAGE.text}
            </span>{" "}
          </div>
        }
        explain={STATUT_PAGE.pageSubName || ""}
      >
        <PreviousNextCandidature />

        <SliderCustom
          steps={ETAPES}
          subSteps={STATUTS_CANDIDATURE}
          currentSubStep={STATUT_PAGE}
          activeSubStep={candidature.statut}
          subStepsWithAlert={
            activeMecsAsked?.map((m: any) => ({
              statut: m.candidature_statut,
              title: "Mise en conformité demandée",
            })) || []
          }
        />
        <CandidatureStatus />
        {pagesByStatus[statut_candidature] && pagesByStatus[statut_candidature]}
        {candidature.mise_en_conformites?.length > 0 && (
          <ListeMECS mecs={activeMecs} />
        )}
        <PreviousNextCandidature />
      </Page>
    </WorkflowWrapper>
  );
};

export default WorkflowContent;
