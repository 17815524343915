import {
  ChoiceGroup,
  DatePicker,
  DefaultButton,
  Dropdown,
  IChoiceGroupOption,
  Label,
  Stack,
  Text,
} from "@fluentui/react";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import useSuiviCohortes from "../../../hooks/suiviCohortes/useSuiviCohortes";
import { getCertificationsRequest } from "../../../services/certificationServices";
import { useQuery } from "react-query";
import Loader from "../../../Common/Loader/Loader";
import tools from "../../../utils/tools";
import { strings } from "../../../config/datePickerConfig";
import { useState } from "react";
import { DateTime } from "luxon";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import validations from "../../../utils/validations";
import { toast } from "react-toastify";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";

function ExportSuiviCohortes() {
  const [errors, setErrors]: any = useState({});
  const [informationExport, setInformationExport] = useState({
    certification: undefined,
    date_debut: undefined,
    date_fin: undefined,
    periode_de_suivi: undefined,
  });

  const { actions: actionsModal } = useCustomModal();
  const { userSelectedBranch, actions, isLoadingExport } = useSuiviCohortes();

  const handleChangeInformationExport = (e: any) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setInformationExport({ ...informationExport, [name]: value });
  };

  const validateInfoExport = (info: any) => {
    let validationErrors: any = {};
    const validationExport = validations.validateExportForm(info);
    if (validationExport?.error) {
      validationExport.error.details.map((err: any) => {
        validationErrors[err.context.key] = err.message;
      });
    }
    setErrors(validationErrors);
    let isValid = Object.keys(validationErrors).length === 0;
    return isValid;
  };

  const onExportSuiviCohorte = () => {
    if(validateInfoExport(informationExport)){
        actions.onExportSuiviCohorte(informationExport);
    }else{
        toast.error("Le formulaire comporte des erreurs.");
    }
  };

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    isRefetching: isRefetchingCertifications,
  } = useQuery(
    ["certifications", userSelectedBranch],
    () =>
      getCertificationsRequest(
        {},
        { sort: "", page: 1, paginate: false, searchAt: "" },
        userSelectedBranch.id
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const certificationsFromApi =
    certifications?.data.map((certification: any) => ({
      id: certification.uuid,
      key: certification.uuid,
      text: `${certification.libelle} - ${certification.type_libelle}`,
    })) || [];

  //[to update] add fron constant API
  const exportSuiviChoices: IChoiceGroupOption[] = [
    { key: "1", text: "Suivi à 6 mois" },
    { key: "2", text: "Suivi à 6 et 24 mois" },
  ];

  return isLoadingCertifications || isRefetchingCertifications ? (
    <Loader />
  ) : (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Text style={{ color: "#6f6f6f", fontSize: 13 }}>
        Veuillez sélectionner une date et une certification pour exporter votre
        fichier au format attendu par France Compétences
      </Text>

      <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <Dropdown
            label="Certification :"
            placeholder="Sélectionnez une certification"
            options={certificationsFromApi}
            onChange={(e: any, options: any) => {
              handleChangeInformationExport({
                target: {
                  name: "certification",
                  value: options.key,
                },
              });
            }}
            required
            styles={commonInputStyles.dropdownStyle}
            style={{maxWidth:400}}
            errorMessage={errors?.certification}
          />
        </Stack.Item>

        <Stack.Item>
          <Label htmlFor="date_debut" required styles={commonInputStyles.label}>
            Exporter les candidats certifiés et suivis à partir de :
          </Label>
          <DatePicker
            formatDate={(date?: any): any =>
              date ? `${tools.reformateDateFromJsDate(date)}` : null
            }
            strings={strings}
            showGoToToday={false}
            maxDate={new Date()}
            value={
              informationExport.date_debut
                ? new Date(informationExport.date_debut)
                : undefined
            }
            styles={commonInputStyles.dataPicker}
            onSelectDate={(date: any) => {
              if (date) {
                handleChangeInformationExport({
                  target: {
                    name: "date_debut",
                    value: DateTime.fromJSDate(date).toUTC().toISO(),
                  },
                });
              }
            }}
            placeholder="dd/mm/yyyy"
            allowTextInput
            parseDateFromString={(dateStr: any) =>
              tools.parseDateFromStringDatePicker(dateStr)
            }
          />
          <ErrorMessage errorMessage={errors?.date_debut} />
        </Stack.Item>

        <Stack.Item>
          <Label htmlFor="date_fin" required styles={commonInputStyles.label}>
            Jusqu'à :
          </Label>
          <DatePicker
            formatDate={(date?: any): any =>
              date ? `${tools.reformateDateFromJsDate(date)}` : null
            }
            strings={strings}
            maxDate={new Date()}
            showGoToToday={false}
            value={
              informationExport.date_fin
                ? new Date(informationExport.date_fin)
                : undefined
            }
            styles={commonInputStyles.dataPicker}
            onSelectDate={(date: any) => {
              if (date) {
                handleChangeInformationExport({
                  target: {
                    name: "date_fin",
                    value: DateTime.fromJSDate(date).toUTC().toISO(),
                  },
                });
              }
            }}
            placeholder="dd/mm/yyyy"
            allowTextInput
            parseDateFromString={(dateStr: any) =>
              tools.parseDateFromStringDatePicker(dateStr)
            }
          />
          <ErrorMessage errorMessage={errors?.date_fin} />
        </Stack.Item>

        <Stack.Item>
          <ChoiceGroup
            options={exportSuiviChoices}
            label="Période de suivi à exporter :"
            required
            onChange={(e: any, options: any) => {
              handleChangeInformationExport({
                target: {
                  name: "periode_de_suivi",
                  value: options.key,
                },
              });
            }}
          />
          <ErrorMessage errorMessage={errors?.periode_de_suivi} />
        </Stack.Item>

        <Stack.Item
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <DefaultButton
            styles={commonButtonStyles.defaultButton}
            onClick={actionsModal.closeModal}
          >
            Annuler
          </DefaultButton>
          <LoadButton
            text={"Exporter mon suivi"}
            type="button"
            onClick={onExportSuiviCohorte}
            isLoading={isLoadingExport}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
}

export default ExportSuiviCohortes;
